import React from 'react';
import { ButtonPreviewIcon, ButtonFloatPreviewIcon } from '@/components/Icons';

export const previewDrawerWidth = 480;

export const previewDrawerWidthSm = 320;

export const buttonTypeOptions = [
	{
		value: 'label',
		label: 'data_collection-preview-button_type-option_label',
		icon: <ButtonPreviewIcon />,
		checkedIcon: <ButtonPreviewIcon />,
	},
	{
		value: 'float',
		label: 'data_collection-preview-button_type-option_float',
		icon: <ButtonFloatPreviewIcon />,
		checkedIcon: <ButtonFloatPreviewIcon />,
	},
];

export const buttonPositionOptions = [
	{
		value: 'right',
		label: `data_collection-preview-button_type-position-right`,
	},
	{
		value: 'bottom-right',
		label: `data_collection-preview-button_type-position-bottom_right`,
	},
	{
		value: 'bottom-left',
		label: `data_collection-preview-button_type-position-bottom_left`,
	},
	{
		value: 'left',
		label: `data_collection-preview-button_type-position-left`,
	},
];
