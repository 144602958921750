import React, { useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';

import HelpMenu from './HelpMenu';
import MenuItems from './MenuItems';
import WhatsNew from './WhatsNew';
import GiveFeedback from './GiveFeedback';
import AppBarGlobals from './AppBarGlobals';
import {
	Logo,
	ActionIconButton,
	RenderConditional,
	constants as layoutConstants,
} from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flex: 1,
		maxWidth: '100%',
	},
	appBarBg: {
		backgroundColor: '#ffffff',
		color: theme.palette.text.primary,
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: `calc(100% - ${layoutConstants.drawerWidthClosed}px)`,
	},
	appBarShift: {
		width: `calc(100% - ${layoutConstants.drawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: layoutConstants.drawerWidth,
	},
	menuButton: {
		marginLeft: theme.spacing(0.5),
	},
	hide: {
		display: 'none',
	},
	drawerSpacer: {
		'@media (min-width: 1200px)': {
			width: layoutConstants.drawerWidth,
		},
	},
	drawerPaper: {
		position: 'fixed',
		width: layoutConstants.drawerWidth,
		height: '100vh',
		transition: theme.transitions.create(['width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		backgroundColor: theme.palette.mopinion.main,
		color: '#fff',
	},
	drawerPaperClosed: {
		width: layoutConstants.drawerWidthClosed,
	},
	drawerHeight: {
		height: '100vh',
	},
	drawerInner: {
		flexGrow: 1,
		overflowY: 'auto',
	},
	drawerHeader: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	logo: {
		width: '36px',
		verticalAlign: 'middle',
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(1),
	},
	logoDrawer: {
		width: '24px',
		verticalAlign: 'middle',
		marginRight: '2px',
		marginLeft: 0,
	},
	logoDrawerText: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: 600,
		display: 'inline-flex',
	},
	grow: {
		flexGrow: 1,
	},
	appBarActions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			marginRight: theme.spacing(1),
		},
		'& > *:not(:last-child)': {
			marginRight: theme.spacing(0.5),
		},
		marginTop: props =>
			props.appBarProminent && !props.prominentHidden ? theme.spacing(1) : 0,
	},
	helpMenuWrapper: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
		padding: props => (props.mainMenuOpen ? theme.spacing(1) : 0),
		textAlign: props => (props.mainMenuOpen ? 'left' : 'center'),
	},
	pageHeaderProminent: {
		position: 'absolute',
		top: 0,
		transform: props => (props.prominentHidden ? `translateY(-64px)` : ''),
		transition: theme.transitions.create('transform'),
		display: 'flex',
		height: 64,
		alignItems: 'center',
	},
	toolbar: {
		transition: theme.transitions.create('min-height'),
	},
	toolbarProminent: {
		minHeight: 128,
		alignItems: 'flex-start',
	},
	tabs: {
		alignSelf: 'flex-end',
	},
	tabsProm: {
		'& > div': {
			position: 'absolute',
			bottom: 0,
			maxWidth: 'calc(100% - 48px)',
			width: '100%',
		},
	},
	tabsPromHidden: {
		flexShrink: 1,
		overflow: 'hidden',
	},
	iconButtonHover: {
		'&:hover': {
			backgroundColor: theme.palette.darkMode.action.hover,
		},
	},
	buttonDisabled: {
		color: '#fff!important',
		opacity: 0.5,
	},
	pointer: {
		cursor: 'pointer',
	},
}));

export default function AppMenuBar({ children, ...props }) {
	const appBarRef = useRef(null);
	const appBarActionsRef = useRef(null);
	const appBarLeftContentRef = useRef(null);
	const pageHeaderRef = useRef(null);
	const { t } = useTranslation();
	const {
		app,
		app: { layout, session },
	} = useAppContext();
	const classes = useStyles({ ...layout });

	//when still onboarding, menu should always be disabled
	const mainMenuOpen = layout.mainMenuOpen && !session.onboarding_trial;

	function handleClose() {
		app.dispatch({ type: 'main_menu', payload: false });
	}

	function handleOpen() {
		app.dispatch({ type: 'main_menu', payload: true });
	}
	useEffect(() => {
		app.dispatch({ type: 'set_app_bar_ref', payload: appBarRef });
	}, [appBarRef]);
	useEffect(() => {
		app.dispatch({ type: 'set_app_actions_ref', payload: appBarActionsRef });
	}, [appBarActionsRef]);
	useEffect(() => {
		app.dispatch({ type: 'set_app_bar_left_content_ref', payload: appBarLeftContentRef });
	}, [appBarLeftContentRef]);
	useEffect(() => {
		app.dispatch({ type: 'set_page_header_ref', payload: pageHeaderRef });
	}, [pageHeaderRef]);

	return (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				ref={appBarRef}
				className={`${classes.appBar} ${
					layout.persistentDrawer && mainMenuOpen ? classes.appBarShift : ''
				} ${classes.appBarBg}`}
				elevation={0}
			>
				<Toolbar
					disableGutters={false}
					className={`${classes.toolbar} ${
						layout.appBarProminent && !layout.prominentHidden
							? classes.toolbarProminent
							: ''
					}`}
				>
					<div
						className={`${classes.grow} ${
							layout.appBarProminent ? classes.pageHeaderProminent : ''
						}`}
						id="page-header"
						ref={pageHeaderRef}
					/>
					<div
						className={`${classes.grow} ${
							layout.appBarLeftContentTabs ? classes.tabs : ''
						} ${
							layout.appBarLeftContentTabs && !layout.prominentHidden
								? classes.tabsProm
								: ''
						} ${
							layout.appBarLeftContentTabs && layout.prominentHidden
								? classes.tabsPromHidden
								: ''
						}`}
						ref={appBarLeftContentRef}
					/>

					<div
						data-onboarding="appbar-actions"
						className={classes.appBarActions}
						ref={appBarActionsRef}
					/>

					<AppBarGlobals />
				</Toolbar>
			</AppBar>

			<nav>
				<Drawer
					variant={
						layout.persistentDrawer
							? 'permanent'
							: mainMenuOpen
							? 'temporary'
							: 'permanent'
					}
					open={mainMenuOpen}
					classes={{
						paper: `${classes.drawerPaper} ${
							!mainMenuOpen ? classes.drawerPaperClosed : ''
						}`,
					}}
					onClose={handleClose}
					PaperProps={{
						'data-onboarding': 'main-nav',
					}}
				>
					<div className={classes.drawerInner}>
						<div className={classes.drawerHeader}>
							<Logo
								withText={mainMenuOpen}
								inDrawer
							/>

							{mainMenuOpen && (
								<ActionIconButton
									action="previous"
									onClick={handleClose}
									className={classes.iconButtonHover}
									color="inherit"
									data-test-element="close_app_menu_bar_icon"
								/>
							)}
						</div>

						<RenderConditional
							condition={session.onboarding_trial}
							title={t`app_general-app_menu-tooltip-menu_disabled`}
							placement="right"
							className={classes.pointer}
						>
							<span>
								{!mainMenuOpen && (
									<Box align="center">
										<ActionIconButton
											color="inherit"
											disabled={session.onboarding_trial}
											onClick={mainMenuOpen ? handleClose : handleOpen}
											className={`${classes.iconButtonHover} ${
												session.onboarding_trial ? classes.buttonDisabled : null
											}`}
											action="menu"
											data-test-element="toggle-main-menu"
										/>
									</Box>
								)}
								<List>
									<MenuItems menuExpanded={mainMenuOpen} />
								</List>
							</span>
						</RenderConditional>
					</div>

					<div
						className={classes.helpMenuWrapper}
						data-onboarding="trial-help"
					>
						<HelpMenu
							ButtonProps={{ color: 'inherit', className: classes.iconButtonHover }}
							withText={mainMenuOpen}
						/>

						<WhatsNew
							color="inherit"
							mainMenuOpen={mainMenuOpen}
							className={classes.iconButtonHover}
						/>

						<GiveFeedback
							color="inherit"
							mainMenuOpen={mainMenuOpen}
							className={classes.iconButtonHover}
						/>
					</div>
				</Drawer>
			</nav>

			{children}
		</div>
	);
}
