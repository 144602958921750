import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { Typography, Autocomplete } from '@/components/Layout';
import { FilterGroup } from '@/components/FilteredLists';
import { DataSourceAutocomplete } from '@/components/DataSources';
import { useDeploymentStore } from './DeploymentStore';

const useStyles = makeStyles(theme => ({
	filterGroupWrap: {
		padding: theme.spacing(1),
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
		'& > div': {
			maxWidth: '100%',
		},
	},
}));

export default function DeploymentEditorSdkFilterSidebar({ editorType }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const autocompleteSurveyOptions = useDeploymentStore(store => {
		const sdkSurveys = store.surveysByProject.flatMap(project => project.sdk_surveys);
		const webSurveys = store.surveysByProject.flatMap(project => project.web_surveys);
		return [...sdkSurveys, ...webSurveys];
	});

	// Gather mobile events from all rules (ensuring they are unique).
	const autocompleteEventOptions = useDeploymentStore(store => {
		const events = (store.workingDraft.rules ?? []).flatMap(rule =>
			rule.if.flatMap(condition => condition.mobile?.events || [])
		);
		return Array.from(new Set(events));
	});

	const filters = useDeploymentStore(store => store.filters);
	const { setFilter } = useDeploymentStore(store => store.actions);

	return (
		<div className={classes.filterGroupWrap}>
			<div>
				<Typography variant="h6">
					{t`app_general-filtered_list-sidebar-header`}
				</Typography>
			</div>
			<div>
				<FilterGroup noCollapse>
					<DataSourceAutocomplete
						label={t`data_collection-deployment_editor_sdk_filter_sidebar-datasource_filter-label`}
						placeholder={t`data_collection-deployment_editor_sdk_filter_sidebar-datasource_filter-placeholder`}
						variant="filled"
						value={filters?.surveys ?? []}
						multiple
						onChange={(e, value) =>
							setFilter({
								filter: 'surveys',
								value: value.map(survey => survey.survey_key),
							})
						}
						options={autocompleteSurveyOptions}
					/>
				</FilterGroup>
			</div>
			{autocompleteEventOptions.length > 0 && (
				<FilterGroup noCollapse>
					<Autocomplete
						label={t`data_collection-deployment_editor_sdk_filter_sidebar-event_filter-label`}
						placeholder={t`data_collection-deployment_editor_sdk_filter_sidebar-event_filter-placeholder`}
						value={filters?.events ?? []}
						options={autocompleteEventOptions}
						onChange={(e, value) => setFilter({ filter: 'events', value })}
						freeSolo
						multiple
						variant="filled"
					/>
				</FilterGroup>
			)}
			<FilterGroup
				noCollapse
				filterName={t`data_collection-deployment_editor_sdk_filter_sidebar-channel_filter-label`}
				filterKey="channel"
				multiple
				filters={[
					{
						label: t`data_collection-deployment_editor_sdk_filter_sidebar-channel_filter-native`,
						value: 'sdk_native',
					},
					{
						label: t`data_collection-deployment_editor_sdk_filter_sidebar-channel_filter-webview`,
						value: 'sdk_webview',
					},
					{
						label: t`data_collection-deployment_editor_sdk_filter_sidebar-channel_filter-web`,
						value: 'web',
					},
				].map(filter => {
					if (filters.channel?.includes(filter.value)) {
						filter.selected = true;
					}
					return filter;
				})}
				onChange={({ key, value }) => setFilter({ filter: key, value })}
			/>
			<FilterGroup
				noCollapse
				filterName={t`data_collection-deployment_editor_sdk_filter_sidebar-trigger_filter-label`}
				filterKey="trigger"
				multiple
				filters={[
					{
						label: t`data_collection-deployment_editor_sdk_filter_sidebar-trigger_filter-passive`,
						value: 'passive',
					},
					{
						label: t`data_collection-deployment_editor_sdk_filter_sidebar-trigger_filter-proactive`,
						value: 'proactive',
					},
				].map(filter => {
					if (filters.trigger?.includes(filter.value)) {
						filter.selected = true;
					}
					return filter;
				})}
				onChange={({ key, value }) => setFilter({ filter: key, value })}
			/>
		</div>
	);
}
