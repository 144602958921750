import React from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { renderToString } from 'react-dom/server';
import get from 'lodash.get';
import { I18nextProvider, Translation } from 'react-i18next';
import i18n from '@/i18n';

import { xIsSameAsYSerieValue } from './constants';
import { tryParse } from '@/utils';

import { ThemeProvider, getDefaultChartColors, getRandomColor, theme } from '@/styles';

export function formatter(f) {
	try {
		if (typeof f !== 'undefined') {
			f = f.replace(/function(.*)\(\)(.*)\{/, '').replace(/\}(.*)/, '');
			return new Function(f);
		} else {
			return function () {};
		}
	} catch (e) {
		return function () {};
	}
}

export function formatterToString(f) {
	return String(f)
		.replace(/\sanonymous/, '')
		.replace('/\n/g', '')
		.replace(/\s+/g, ' ')
		.replace(/"/g, '\\"');
}

export function maxStringLength(point, len = 47) {
	//percentagebased charts || checkbox chart
	if (point.percentage || point.name === 'true') {
		return null;
	}
	if (point.name.length >= len) {
		return point.name.substring(0, len) + '(...)';
	}

	return point.name;
}

export function createTooltipText(name, currentSerie, y, percentage, t) {
	const isCheckbox = currentSerie.filter_value?.[0]?.[0] === 'true';
	const serieName =
		name === 'Survey' ? t`reporting-dashboard-chart_tooltip-survey` : name;
	const scoreType =
		currentSerie.average === 1
			? `${t`reporting-dashboard-chart_tooltip-average`} `
			: Number(currentSerie.average) === 10
			? `${t`reporting-dashboard-chart_tooltip-gcr`} `
			: Number(currentSerie.average) === 2
			? `${t`reporting-dashboard-chart_tooltip-nps`} `
			: Number(currentSerie.average) === 9
			? `${t`reporting-dashboard-chart_tooltip-ces`} `
			: '';

	if (isCheckbox) {
		const option = serieName.split('-');
		return option[0] + ': ' + scoreType + y + percentage;
	}

	return serieName + ': ' + scoreType + y + percentage;
}

export function tooltip() {
	//TODO -> decide when to add perc
	function getYLink(serie = {}) {
		switch (Number(serie.average)) {
			case 3:
				if (serie.perc_cats) {
					if (Array.isArray(serie.perc_cats)) {
						return serie.perc_cats;
					} else {
						const perc_cats = tryParse(serie.perc_cats, []);
						if (Array.isArray(perc_cats)) {
							return perc_cats.map(cat => {
								if (!isNaN(Number(cat))) {
									return Number(cat);
								}
								return cat;
							});
						}
					}
				}
				return null;
			case 9:
				return [4, 5];
			case 10:
				return ['yes'];
			case 11:
				return [1, 2];
			case 13:
				return [9, 10];
			case 14:
				return [7, 8];
			case 15:
				return [0, 1, 2, 3, 4, 5, 6];
			case 17:
				return ['yes'];
			case 18:
				return ['partially'];
			case 19:
				return ['no'];
			case 20:
				return [4, 5];
			case 21:
				return [3];
			case 22:
				return [1, 2];
			case 23:
				return ['positive'];
			case 24:
				return ['negative'];
			default:
				return null;
		}
	}
	return function () {
		const {
			point = {},
			series,
			series: { userOptions, chart },
			y,
		} = this;

		const currentSerie = get(
			chart,
			`options.chart.seriesData[${userOptions.series_order}]`,
			{}
		);

		const custom =
			typeof currentSerie.custom === 'object'
				? currentSerie.custom
				: getQueryParameters(currentSerie.custom);

		const percentage = showPercentageSerie(currentSerie) ? '%' : '';

		const addPointPercentage =
			get(chart, 'options.plotOptions.series.stacking', '') === 'percent';

		// const { filter_fields='', filter_values='' } = point;
		// const extraFilterKeys = filter_fields.indexOf('{PIPE}') > -1 ? filter_fields.split('{PIPE}') : filter_fields.split('|');
		// const extraFilterValues = filter_values.indexOf('{PIPE}') > -1 ? filter_values.split('{PIPE}') : filter_values.split('|');

		const extraFilterKeys = currentSerie?.filter_key ?? [];
		const extraFilterValues = (currentSerie?.filter_value ?? []).map(value => {
			if (Array.isArray(value)) {
				return value.map(inner => {
					if (typeof inner === 'string' && inner.startsWith('_') && inner.endsWith('_')) {
						return inner.substring(1, inner.length - 1);
					}
					return inner;
				});
			}
			return value;
		});

		//If custom.x_tags only
		const filterTags =
			custom.x_tags === 'true'
				? [point.x_link]
				: Array.isArray(custom.filter_tags)
				? custom.filter_tags
				: typeof custom.filter_tags === 'string'
				? custom.filter_tags.split(',').filter(Boolean)
				: [];

		//if we're filtering on tags we need the corresponding survey_id as well because otherwise the quickview will show tags for all surveys
		const surveyId = custom.x_tags === 'true' ? currentSerie.survey_id : null;

		//Add point.x_link to tag filters if tags are used as categories (x_tags = true)
		// if (custom.x_tags === 'true') {
		//   filterTags.push(point.x_link);
		// }

		const filtersForFeedbackList = [
			{ x: currentSerie.x },
			{ y: currentSerie.y },
			{ custom: [...extraFilterKeys] },
		].reduce((allFilters, currentFilter, index, self) => {
			//only add x field if it isnt a tag field because the tag field is added above
			if (currentFilter.x && custom.x_tags !== 'true') {
				allFilters.push({
					type: 'datafield',
					identifier:
						currentFilter.x === xIsSameAsYSerieValue ? currentSerie.y : currentFilter.x,
					value: [point.x_link],
					surveyId: currentSerie.survey_id,
				});
			} else if (currentFilter.y) {
				const yValue = getYLink(currentSerie);
				if (yValue) {
					allFilters.push({
						type: 'datafield',
						identifier: currentFilter.y,
						value: yValue,
						surveyId: currentSerie.survey_id,
					});
				}
			} else if (currentFilter.custom && currentFilter.custom.length) {
				allFilters.push(
					...extraFilterKeys.reduce((allExtraFilters, filterKey, filterIndex) => {
						let filterValue = extraFilterValues[filterIndex];

						if (filterKey && filterValue) {
							allExtraFilters.push({
								type: 'datafield',
								identifier: filterKey,
								value: filterValue,
								surveyId: currentSerie.survey_id,
							});
						}

						return allExtraFilters;
					}, [])
				);
			}

			return allFilters;
		}, []);

		return renderToString(
			<ThemeProvider>
				<I18nextProvider i18n={i18n}>
					<Translation>
						{t => (
							<div>
								<Typography
									display="block"
									variant="subtitle2"
								>
									{maxStringLength(point)}
								</Typography>
								<Typography
									display="block"
									variant="caption"
								>
									{createTooltipText(series.name, currentSerie, y, percentage, t)}
								</Typography>

								{addPointPercentage && (
									<Typography
										display="block"
										variant="caption"
									>
										{t('Percentage') + ': ' + Math.round(this.percentage) + '%'}
									</Typography>
								)}

								<Typography
									display="block"
									variant="caption"
								>
									{t('Count') + ': ' + point.count}
								</Typography>

								<div style={{ marginTop: theme.spacing(1) }}>
									<Button
										variant="contained"
										color="secondary"
										className={`quick-view-feedback-button`}
										data-filters={JSON.stringify(filtersForFeedbackList)}
										data-tags={JSON.stringify(filterTags)}
										{...(surveyId && {
											'data-survey-id': surveyId,
										})}
										fullWidth
									>
										{t`View feedback`}
									</Button>
								</div>
							</div>
						)}
					</Translation>
				</I18nextProvider>
			</ThemeProvider>
		);
	};
}

export function formatChart(chart = {}, onDashBoard) {
	try {
		chart.title.text = decodeCharacters(chart.title.text);
	} catch (e) {}

	try {
		chart.subtitle.text = decodeCharacters(chart.subtitle.text);
	} catch (e) {}

	try {
		delete chart.chart.events.click;
	} catch (e) {}
	try {
		chart.colors = getDefaultChartColors();
	} catch (e) {}

	try {
		chart.tooltip.formatter = tooltip();
	} catch (e) {}

	try {
		chart.title.style.display = 'none';
		chart.subtitle.style.display = 'none';
	} catch (e) {}

	try {
		['legend', 'plotOptions', 'tooltip', 'xAxis', 'yAxis'].forEach(key => {
			if (Array.isArray(chart[key])) {
				chart[key] = {};
			}
		});
	} catch (e) {}

	try {
		chart.yAxis.title = {
			text: '',
			style: {},
		};
	} catch (e) {}

	return chart;
}

export function serieBase(
	{ id, system_var, surveyId, survey_block = {}, title },
	{ x = 0, chartType = 1, average = 0, ...settings } = {}
) {
	const filter_key =
		Number(survey_block?.type) === 4 //checkbox
			? [[id]]
			: [];

	const filter_value =
		Number(survey_block?.type) === 4 //checkbox
			? [['true']]
			: [];

	return {
		average,
		benchmark: '',
		color: settings.color ?? getRandomColor(),
		custom: {
			date_format: settings.custom?.date_format ?? '',
			date_group: settings.custom?.date_group ?? '',
			decimal: settings.decimal,
			distinct: 'false',
			n: chartType == 1 ? 'true' : '',
			no_zero: 'false',
			perc: settings.perc || '',
			x_tags: settings.custom?.x_tags ?? '',
			filter_tags: '',
			survey_id: surveyId || '',
			...(settings.custom?.limit && { limit: settings.custom.limit }),
			...(settings.custom?.order && { order: settings.custom.order }),
			...(Number(survey_block?.type) === 4 && {
				extra_filter: mapFilterArrayToString(filter_key, filter_value),
			}),
		},
		custom_widget: {
			type: 'nps',
			options: {
				show_total: true,
				score_style: {
					fontFamily: 'Helvetica',
					fontSize: '72px',
					fontWeight: 'bold',
					fontStyle: 'normal',
					color: settings.color ?? getRandomColor(),
				},
			},
		},
		date_group: settings.custom?.date_group ?? '',
		decimal: settings.decimal,
		filter_key,
		filter_value,
		i: 0,
		is_visible: 1,
		is_widget: 0,
		name: title || system_var, // <- from fields
		perc_cats: [],
		possible_values: [],
		x: x, // <- from fields
		y: id,
		survey_id: surveyId,
	};
}

export function formatterOptions(chart = {}, add_perc = false) {
	if (get(chart, 'plotOptions.series.dataLabels.show_percent')) {
		return [
			function () {
				if (this.y) return Math.round(this.percentage) + ' %';
			},
			function () {
				if (this.y) return this.x + ': ' + Math.round(this.percentage) + ' %';
			},
			function () {
				if (this.y) return this.series.name + ': ' + Math.round(this.percentage) + ' %';
			},
			function () {
				if (this.y) return this.point.name + ': ' + Math.round(this.percentage) + ' %';
			},
		];
	} else if (add_perc) {
		return [
			function () {
				if (this.y) return this.y + ' %';
			},
			function () {
				if (this.y) return this.x + ': ' + this.y + ' %';
			},
			function () {
				if (this.y) return this.series.name + ': ' + this.y + ' %';
			},
			function () {
				if (this.y) return this.point.name + ': ' + this.y + ' %';
			},
		];
	} else {
		return [
			function () {
				if (this.y) return this.y;
			},
			function () {
				if (this.y) return this.x + ': ' + this.y;
			},
			function () {
				if (this.y) return this.series.name + ': ' + this.y;
			},
			function () {
				if (this.y) return this.point.name + ': ' + this.y;
			},
		];
	}
}

//Previously the value of the label formatter function was saved a string
//we must parse the previous value and map it to the current way of saving a datalabel formatter
//as a simple string
export function parseLabelFormat(str = '') {
	//current values -> carry on
	if (
		str.match(
			/(show_x_name|show_serie_name|show_point_name|show_value|show_value_percent|show_x_name_percent|show_point_name_percent|show_serie_name_percent)/g
		)
	)
		return str;

	switch (true) {
		case str?.includes('this.x'):
			return 'show_x_name';
		case str?.includes('this.series.name'):
			return 'show_serie_name';
		case str?.includes('this.point.name'):
			return 'show_point_name';
		case str?.includes('this.y'):
			return 'show_value';
		default:
			return '';
	}
}

export function dataLabelsFormatter(
	type = '',
	{ addPercSeries, hasPointPerc, decimal = 0, isPercStacked } = {}
) {
	function value(v, addPercChart) {
		const valueWithDecimal = v?.toFixed(decimal);
		return addPercSeries || addPercChart ? `${valueWithDecimal} %` : valueWithDecimal;
	}
	const switchType = type.match(
		/(this.percentage|this.y|this.x|this.series.name|this.point.name)/g
	)
		? parseLabelFormat(type)
		: type;

	switch (switchType) {
		case 'show_value':
			return function () {
				return value(this.y);
			};

		case 'show_x_name':
			return function () {
				return `${this.x}: ${value(this.y)}`;
			};

		case 'show_serie_name':
			return function () {
				return `${this.series.name}: ${value(this.y)}`;
			};

		case 'show_point_name':
			return function () {
				return `${this.point.name}: ${value(this.y)}`;
			};

		case 'show_value_percent':
			return function () {
				if (hasPointPerc) {
					return value(this.percentage, true);
				}
				return value(getPercOfSeriesTotal(this.y, this.series), true);
			};

		case 'show_x_name_percent':
			return function () {
				if (hasPointPerc) {
					return `${this.x}: ${value(this.percentage, true)}`;
				}
				return `${this.x}: ${value(getPercOfSeriesTotal(this.y, this.series), true)}`;
			};

		case 'show_serie_name_percent':
			return function () {
				if (hasPointPerc) {
					return `${this.series.name}: ${value(this.percentage, true)}`;
				}
				return `${this.series.name}: ${value(
					getPercOfSeriesTotal(this.y, this.series),
					true
				)}`;
			};

		case 'show_point_name_percent':
			return function () {
				if (hasPointPerc) {
					return `${this.point.name}: ${value(this.percentage, true)}`;
				}
				return `${this.point.name}: ${value(
					getPercOfSeriesTotal(this.y, this.series),
					true
				)}`;
			};

		default:
			return function () {
				if (isPercStacked) {
					return value(this.percentage, true);
				}
				return value(this.y);
			};
	}
}

function getPercOfSeriesTotal(y, series = []) {
	return (y / series.data.reduce((all, serie) => all + serie.y, 0)) * 100;
}

export function makeQueryString(obj) {
	var str = [];
	for (var p in obj)
		if (obj.hasOwnProperty(p)) {
			if (typeof obj[p] == 'undefined' || obj[p] == undefined) {
				obj[p] = '';
			}
			str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
		}
	return str.join('&');
}

export function getQueryParameters(str) {
	if (typeof str == 'object') {
		return str;
	} else if (typeof str !== 'undefined') {
		var obj = str
			.replace(/(^\?)/, '')
			.split('&')
			.map(
				function (n) {
					return (n = n.split('=')), (this[n[0]] = n[1]), this;
				}.bind({})
			)[0];
		delete obj[''];
		return obj;
	} else {
		return '';
	}
}

export function rnd() {
	return Math.random().toString(36);
}

export function mapFilterStringToArray(filter = '') {
	const splitToken = filter.includes('{PIPE}') ? '{PIPE}' : '|';
	const newColonNotation = filter.includes('{COLON}');

	if (filter.startsWith(splitToken)) {
		filter = filter.replace(splitToken, '');
	}

	let values = [];
	let keys = [];

	filter.split(splitToken).forEach((piece, i) => {
		piece = newColonNotation
			? piece.replace(/(fv.*?{COLON})/, '')
			: piece.replace(/(fv.*?:)/, '');

		if (piece) {
			if (i % 2) {
				//is value
				values.push(piece.split('{COMMA}'));
			} else {
				//is key
				keys.push(piece);
			}
		}
	});

	return {
		values: values,
		keys: keys,
	};
}

export function mapFilterArrayToString(filterKeys = [], filterValues = []) {
	if (filterKeys.length && filterValues.length) {
		let arr = filterKeys.reduce((accumulator, key, i) => {
			if (key && filterCats(filterValues[i])) {
				accumulator.push(
					`fv${i + 2}.data_field_id{COLON}${key}{PIPE}fv${i + 2}.value{COLON}${filterCats(
						filterValues[i]
					)}`
				);
			}

			return accumulator;
		}, []);

		return arr.length ? '{PIPE}' + arr.join('{PIPE}') : '';
	} else {
		return '';
	}
}

export function filterCats(perc_cats) {
	try {
		if (
			perc_cats != '' &&
			(typeof perc_cats === 'undefined' ? 'undefined' : typeof perc_cats) !== 'object'
		) {
			perc_cats = JSON.parse(perc_cats);
		}
		if ((typeof perc_cats === 'undefined' ? 'undefined' : typeof perc_cats) == 'object') {
			perc_cats = perc_cats.join('{COMMA}');
		}
	} catch (e) {}

	return perc_cats;
}

export function chartWidget(chart, serie, serieConfig, forceValue) {
	const scoreSerie = get(serieConfig, '[0]', {});
	const style = {
		color: '#8bc34a',
		fontWeight: 'bold',
		fontStyle: 'normal',
		...get(scoreSerie, 'custom_widget.options.score_style', {}),
		fontSize: 72,
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(', '),
	};

	//Use color from series
	if (scoreSerie.color) {
		style.color = scoreSerie.color;
	}
	//const value = forceValue ? forceValue : widgetValue(serie,scoreSerie);

	const widget = {
		resize: {},
		chart: {
			type: 'line',
			backgroundColor: 'white',
			height: chart.chart.height,
			seriesData: serieConfig,
			renderValue: forceValue ? forceValue : widgetValue(serie, scoreSerie),
			events: {
				render(chartContext) {
					try {
						chartContext.target.resize.destroy();
					} catch (e) {}
					try {
						//Create label
						chartContext.target.resize =
							this.renderer &&
							this.renderer
								.label(chartContext.target.options.chart.renderValue)
								.css({
									color: style.color,
									fontFamily: style.fontFamily,
									fontWeight: style.fontWeight,
									fontStyle: style.fontStyle,
									fontSize: style.fontSize,
								})
								.add();

						//Center label
						const bb = chartContext.target.resize.getBBox();
						const x = this.plotLeft + this.plotWidth * 0.5 - bb.width * 0.5;
						const y = this.plotTop + this.plotHeight * 0.5 - bb.height * 0.5;
						chartContext.target.resize.attr({ x: x, y: y });
					} catch (e) {}
				},
			},
		},
		title: {
			text: chart.title.text,
		},
		subtitle: {
			text: chart.subtitle.text,
		},
		credits: {
			enabled: false,
		},
		xAxis: {
			categories: [],
		},
		exporting: {
			enabled: false,
			fallbackToExportServer: false,
		},
	};

	// if (width) {
	//   widget.chart.width = width;
	// }
	return widget;
}

function widgetValue(serie = {}, serieConfig = {}) {
	if (!Object.keys(serie).length) return '-';

	let v = '';
	let custom = {};
	try {
		custom = getQueryParameters(get(serieConfig, '[0].custom'));
	} catch (e) {}

	if (custom && custom.pre_string) {
		v += custom.pre_string + ' ';
	}

	if (get(serie, '[0].data[0].y')) {
		v += get(serie, '[0].data[0].y');
	} else if (get(serie, '[0].data[0][1]')) {
		v += unescape(get(serie, '[0].data[0][1]'));
	} else {
		v += '-';
	}

	if (custom && custom.add_string) {
		let unescAdd = unescape(custom.add_string);
		if (v.indexOf(unescAdd) === -1) {
			v += ' ' + unescAdd;
		}
	}

	if (v.match(/null/gi)) {
		v = '0';
	}

	return v;
}

export function sortSeries(a, b) {
	if (a.series_order < b.series_order) return -1;
	else if (a.series_order > b.series_order) return 1;
	else return 0;
}

export function parseFilterArrayFromApi(arrayString) {
	if (arrayString.startsWith('[') && arrayString.endsWith(']')) {
		arrayString = arrayString.substr(1).slice(0, -1);
	}

	return arrayString.split(',');
}

export function showPercentageSerie(serie = {}) {
	return (
		[3, 5, 9, 12, 17, 18, 19].indexOf(Number(serie.average)) > -1 ||
		(typeof serie.custom === 'object' && serie.custom.perc == 1)
	);
}

export function prepareSaveChart(data = {}) {
	let formatter;

	try {
		if (data.plotOptions.series.dataLabels.formatter) {
			formatter = formatterToString(data.plotOptions.series.dataLabels.formatter);
		}
	} catch (e) {}

	const preparedSeries = prepareSeries(data.series, data.type);

	return {
		...data,
		chart: {
			...data.chart,
			seriesData: preparedSeries,
		},
		title: {
			...data.title,
			text: encodeCharacters(data.title?.text ?? ''),
		},
		subtitle: {
			...data.subtitle,
			text: encodeCharacters(data.subtitle?.text ?? ''),
		},
		series: preparedSeries,
		...(formatter && {
			plotOptions: {
				...data.plotOptions,
				series: {
					...data.plotOptions.series,
					dataLabels: {
						...data.plotOptions.series.dataLabels,
						formatter,
					},
				},
			},
		}),
	};
}

//prepare a series object for saving
export function prepareSeries(series, chartType) {
	return series.map(serie => {
		const preparedSerie = {
			...serie,
			custom: {
				...serie.custom,
				filter_tags: Array.isArray(serie.custom.filter_tags)
					? serie.custom.filter_tags.join(',')
					: serie.custom.filter_tags
					? serie.custom.filter_tags
					: '',
				...(chartType == 2 && {
					n: 'false',
				}),
			},
		};

		//encode special characters in serie
		return parseCharactersInNestedObject(preparedSerie);
	});
}

//parse the series object fetched from the server into an object we can work with
export function parseSeriesFromServer(series) {
	return series.map(serie => {
		const custom = getQueryParameters(serie.custom);
		const extra = mapFilterStringToArray(decodeCharacters(custom.extra_filter));
		const newSerie = {
			...serie,
			perc_cats:
				typeof serie.perc_cats === 'string'
					? tryParse(serie.perc_cats, [])
					: [...serie.perc_cats],
			custom,
			filter_key: [...(custom.filter_tags ? ['tags'] : []), ...extra.keys],
			filter_value: [...(custom.filter_tags ? [[]] : []), ...extra.values],
		};

		//decode remaining possible encoded chars
		return parseCharactersInNestedObject(newSerie, 'decode');
	});
}

export function encodeCharacters(str) {
	const replaceCharacters = {
		'"': '%22',
		"'": '%27',
	};

	if (typeof str === 'string') {
		try {
			return encodeURIComponent(str).replace(
				/["']/g,
				found => replaceCharacters[found] ?? found
			);
		} catch (e) {
			return str;
		}
	}

	return str;
}

export function decodeCharacters(str) {
	const replaceCharacters = {
		'%22': '"',
		'%27': "'",
	};
	if (typeof str === 'string') {
		//Previously we used a simple decoding but as it turns out other unicode characters also have issues being saved
		//therefor we are now using encode/decodeURIComponent but first we must filter out the old encoding style
		const removeOldStringEncoding = str.replace(/&apos;/gi, "'").replace(/&quot;/gi, '"');
		try {
			const o = decodeURIComponent(removeOldStringEncoding).replace(
				/(%22|%27)/g,
				found => replaceCharacters[found] ?? found
			);

			return o;
		} catch (e) {
			return removeOldStringEncoding;
		}
	}

	return str;
}

//recurisvely encode or decode special characters
export function parseCharactersInNestedObject(obj, type = 'encode', arr) {
	const clone = arr ? [] : {};

	for (const key in obj) {
		const value = obj[key];

		if (value !== null && typeof value === 'object') {
			clone[key] = parseCharactersInNestedObject(value, type, Array.isArray(value));
		} else if (typeof value === 'string') {
			clone[key] = type === 'encode' ? encodeCharacters(value) : decodeCharacters(value);
		} else {
			clone[key] = value;
		}
	}

	return clone;
}

export function fieldIsCheckboxOptionButNotExtra(field = {}) {
	return (
		Number(field.survey_block?.type) === 4 && !/extra.input/gi.test(field.import_var)
	);
}
