import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Paper } from '@/components/Layout';

import RenderSurvey from './RenderSurvey';

const useStyles = makeStyles(theme => ({
	paperWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
		},
	},
	paper: {
		background: theme.palette.grey[300],
	},
	row: {
		height: 12,
	},
	row_break: {
		height: 12,
		marginBottom: theme.spacing(4),
	},
	title: {
		height: 20,
		marginBottom: theme.spacing(3),
	},
	image: {
		height: props => (props.persistent ? 70 : 85),
	},
	image_small: {
		height: props => (props.persistent ? 35 : 65),
	},
	break: {
		background: 'transparent',
		height: theme.spacing(2),
	},
	dummyBody: {
		paddingTop: theme.spacing(3),
		background: theme.palette.grey[200],
	},
}));

function Row({ type }) {
	const classes = useStyles({});

	return (
		<Paper
			className={`${classes.paper} ${classes[type]}`}
			elevation={0}
		/>
	);
}

function Rows({ rows = [] }) {
	const classes = useStyles({});

	return (
		<Grid
			container
			direction="column"
			tabIndex="0"
		>
			{rows.map((type, index) => {
				return (
					<Grid
						key={index}
						item
						xs={12}
						className={classes.paperWrap}
					>
						<Row type={type} />
					</Grid>
				);
			})}
		</Grid>
	);
}

export default function DummyGridWeb({
	surveySettings,
	contentWindow,
	scriptLoaded,
	selectedLanguage,
	setScriptLoaded = () => {},
}) {
	const classes = useStyles({});

	return (
		<Grid
			container
			className={classes.dummyBody}
		>
			<Grid
				item
				xs={1}
			/>
			<Grid
				item
				xs={6}
			>
				<Grid container>
					<Grid
						item
						xs={12}
						className={classes.paperWrap}
					>
						<Row type="title" />
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Rows rows={['row', 'row', 'row', 'row_break', 'row', 'row']} />
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Rows rows={['row', 'row_break', 'image_small', 'row']} />
					</Grid>
					<Grid
						item
						xs={12}
						className={classes.paperWrap}
					>
						<Row type="break" />
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				xs={4}
			>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<Rows rows={['title', 'row', 'row', 'image', 'row', 'break']} />
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				xs={1}
			/>

			<Grid
				item
				xs={1}
			/>
			<Grid
				item
				xs={10}
			>
				<RenderSurvey
					surveySettings={surveySettings}
					scriptLoaded={scriptLoaded}
					contentWindow={contentWindow}
					setScriptLoaded={setScriptLoaded}
					selectedLanguage={selectedLanguage}
				/>
			</Grid>
			<Grid
				item
				xs={1}
			/>

			<Grid
				item
				xs={1}
			/>
			<Grid
				item
				xs={6}
			>
				<Grid container>
					<Grid
						item
						xs={12}
						className={classes.paperWrap}
					>
						<Row type="title" />
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Rows rows={['image', 'image', 'image']} />
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Rows
							rows={[
								'row',
								'row',
								'row_break',
								'row',
								'row',
								'row_break',
								'row',
								'row',
								'row_break',
							]}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				xs={4}
			>
				<Grid container>
					<Grid
						item
						xs={12}
						className={classes.paperWrap}
					>
						<Row type="title" />
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Rows
							rows={['row', 'row', 'image', 'row_break', 'row', 'row', 'row', 'row']}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Rows
							rows={['row', 'row', 'row', 'row', 'row', 'row', 'row', 'image', 'row']}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				xs={1}
			/>
		</Grid>
	);
}
