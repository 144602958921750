import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { SimpleDialog, Typography, ActionButton } from '@/components/Layout';
import { ValidatedField } from '@/components/Validation';
import { useAjaxForm } from '@/components/Ajax';

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
	},
}));

export default function DuplicateDeploymentDialog({
	open,
	onClose,
	deploymentId,
	loadDeployments,
}) {
	const { app } = useAppContext();
	const classes = useStyles();
	const { t } = useTranslation();
	const [name, setName] = useState('');
	const { enqueueSnackbar } = useSnackbar();

	const { postForm, loading, clicked } = useAjaxForm({
		valid: () => name.length > 0,
		url: `/api/1/pastease/${app.domain}/${app.organisations.current.org_id}/${deploymentId}/duplicate`,
		type: 'mjolnir',
		data: {
			name,
		},
		onSuccess: response => {
			if (response.code === 200) {
				loadDeployments();
				onClose();
				enqueueSnackbar(t`data_collection-duplicate_deployment_dialog-success`, {
					action: () => (
						<ActionButton
							link
							to={`/data-collection/deployment/edit/${response.deployment_id}`}
							color="secondary"
						>
							{t`data_collection-duplicate_deployment_dialog-view_duplicate`}
						</ActionButton>
					),
				});
			}
		},
	});
	return (
		<SimpleDialog
			open={open}
			onClose={onClose}
			title={t`data_collection-duplicate_deployment_dialog-title`}
			onSubmit={postForm}
			submit={t`data_collection-duplicate_deployment_dialog-submit`}
			loading={loading}
		>
			<div className={classes.content}>
				<ValidatedField
					className={classes.nameInput}
					fullWidth
					autoFocus
					label={t`data_collection-duplicate_deployment_dialog-name_label`}
					placeholder={t`data_collection-duplicate_deployment_dialog-name_placeholder`}
					errorMessage={t`data_collection-duplicate_deployment_dialog-name_error`}
					value={name}
					onChange={e => setName(e.target.value)}
					rules={{
						required: true,
					}}
					showErrors={clicked}
				/>
			</div>
		</SimpleDialog>
	);
}
