import React from 'react';
import AutoComplete from './AutoComplete';
import translations from '../../constants/translations.json';

function GoogleTranslateAutoComplete({ onChange, selectedItem = [], ...props }) {
	const options = translations.map(country => {
		const countryObj = {
			label: country.name,
			name: country.name,
			language: country.language,
		};

		return countryObj;
	});

	const AutoCompleteProps = {
		...props,
		options,
		onChange: language => onChange(language),
		selectedItem: selectedItem,
		disableCustomEntry: true,
	};

	return (
		<>
			<AutoComplete {...AutoCompleteProps} />
		</>
	);
}

export default GoogleTranslateAutoComplete;
