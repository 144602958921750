import React, { Fragment, useEffect, useState, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import { useParams, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useImmer } from 'use-immer';
import get from 'lodash.get';
import set from 'lodash.set';
import { useSnackbar } from 'notistack';

import StopIcon from '@material-ui/icons/Stop';

import { useDataSourceContext, DataFieldIcon } from '@/components/DataSources';
import { PageHeader } from '@/components/App';
import {
	ActionButton,
	Loader,
	FileTypeAvatar,
	Alert,
	EditableContent,
	SimpleDialog,
} from '@/components/Layout';
import { VarTypeSelect } from '@/components/Select';
import { useEFM, useAjaxForm } from '@/components/Ajax';

const useStyles = makeStyles(theme => ({
	fab: {
		position: 'fixed',
		left: '50%',
		transform: 'translateX(-50%)',
		bottom: theme.spacing(2),
		zIndex: 100,
	},
	chips: {
		margin: theme.spacing(0.25),
	},
	ellipsis: {
		minWidth: 0,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	spaceAlert: {
		margin: theme.spacing(1, 0),
	},
}));

const errorMessages = {
	error: 'Something went wrong while analyzing your data',
	incorrect_titles:
		'It looks like your data has incorrect titles or is missing a title row',
	max_columns_exceeded: 'Your file exceeds the maximum amount of columns',
};

export default function AnalyzeData({ data, onBack }) {
	const { t } = useTranslation();
	const [state, setState] = useImmer({
		fields: [],
		name: t`New dataset`,
	});

	const classes = useStyles();
	const { datasetId } = useParams();
	const history = useHistory();
	const { datasource } = useDataSourceContext();
	const { enqueueSnackbar } = useSnackbar();
	const [showNoDateDialog, setShowNoDateDialog] = useState(false);
	const [error, setError] = useState(null);

	function setField(path, value, selectedDateField) {
		if (selectedDateField) {
			const dateFieldIndex = state.fields.findIndex(field => field.var_type == 7);
			if (dateFieldIndex !== -1) {
				setState(draft => {
					draft.fields[dateFieldIndex].var_type = 1;
				});
				enqueueSnackbar(
					t(
						`You can only select one date type field, {{field}} will now be imported as a category type`,
						{ field: state.fields[dateFieldIndex]?.column_name }
					)
				);
			}
		}
		setState(draft => {
			set(draft.fields, path, value);
		});
	}

	useEffect(() => {
		if (datasetId !== 'new') {
			const source = datasource.api.getSource(datasetId);
			if (source.name) {
				setState(draft => {
					draft.name = source.name;
				});
			}
		}
	}, [datasetId, datasource.api.getSource(datasetId)]);

	const [analyzedData, loadingAnalyze, analyzeError] = useEFM(
		'/survey/ajax/analyze-data',
		{
			...data,
			surveyId: datasetId === 'new' ? 0 : datasetId,
		}
	);

	const dateFieldCount = useMemo(
		() => analyzedData.fields?.filter(field => field.var_type == 7).length,
		[analyzedData.fields]
	);

	const fieldsWithMatchedDataField = useMemo(
		() => analyzedData.fields?.filter(field => field.matched) ?? [],
		[analyzedData.fields]
	);

	const missingFields = useMemo(
		() => Object.values(analyzedData.missing_fields ?? {}).map(field => field),
		[analyzedData.missing_fields]
	);

	const missingDateField = useMemo(
		() => missingFields.find(field => field.var_type == 7),
		[missingFields]
	);

	const hasMatchedDateField = useMemo(
		() => fieldsWithMatchedDataField.find(field => field.var_type == 7),
		[fieldsWithMatchedDataField]
	);

	useEffect(() => {
		if (analyzeError) {
			setError('error');
			return;
		}

		if (analyzedData?.column_count > 50) {
			setError('max_columns_exceeded');
			return;
		}

		if (analyzedData.collision_percentage > 10 || analyzedData.numeric_titles) {
			setError('incorrect_titles');
			return;
		}

		setError(null);

		setState(draft => {
			if (analyzedData.fields) {
				//Set datefield if there is only one found other wise
				draft.fields = analyzedData.fields.reduce((allFields, field, index) => {
					//if field wasnt matched to a datafield
					if (!field.matched) {
						if (field.var_type == 7) {
							if (dateFieldCount > 1) {
								//if there are more than 1 datefields, dont prefill any so the user can pick himself
								field.var_type = -1;
							}
						}
						allFields.push(field);
					}

					return allFields;
				}, []);
			}
		});
	}, [analyzedData, analyzeError, dateFieldCount]);

	const feedback_date_col_matched = hasMatchedDateField
		? hasMatchedDateField.column_name
		: missingDateField
		? missingDateField.system_var
		: false;

	const { postForm, loading: loadingPost } = useAjaxForm({
		url: '/survey/ajax/process-data',
		data: {
			name: state.name,
			survey_id: datasetId === 'new' ? 0 : datasetId,
			fields: state.fields.reduce((fieldObj, currentField) => {
				fieldObj[currentField.column_name] = currentField.var_type;
				return fieldObj;
			}, {}),
			file: data.fileData.name,
			feedback_date_col_matched,
		},
		onSuccess: response => {
			setShowNoDateDialog(false);
			if (response.msg === 'OK') {
				enqueueSnackbar(
					datasetId !== 'new'
						? `${t`Data added to`} ${state.name}`
						: `${t`Dataset`} ${state.name} ${t`created`}`
				);

				history.push('/data-collection/dataset');
			} else {
				enqueueSnackbar(t`data_collection-datasets-analyze_data-process_data-error`);
			}
		},
		onError: err => {
			setShowNoDateDialog(false);
			enqueueSnackbar(t`data_collection-datasets-analyze_data-process_data-error`);
		},
	});

	function saveDataSet() {
		const noDateField =
			!fieldsWithMatchedDataField.find(field => field.var_type == 7) &&
			!state.fields.find(field => field.var_type == 7);

		if (noDateField) {
			setShowNoDateDialog(true);
		} else {
			postForm();
		}
	}

	const fileType = data.fileData?.name.split('.').pop();

	return (
		<Fragment>
			<PageHeader
				title={
					<Fragment>
						{datasetId === 'new' ? t`Create dataset` : t`Add to dataset`}
						{' - '}
						<EditableContent
							display="inline-flex"
							value={state.name}
							onChange={value =>
								setState(draft => {
									draft.name = value;
								})
							}
							showIconAlways
							dataTestElement="datasetName"
						>
							{state.name}
						</EditableContent>
					</Fragment>
				}
				documentTitle={`${t`Review imported data`} ${state.name}`}
				tooltip={t`To uploader`}
				onBack={onBack}
			/>
			<Alert variant="default">
				{datasetId
					? t`You're about to create a new dataset with data from your imported file. Review the imported file and select the datafields that you want to import as feedback.`
					: `${t`You're about to import data into your existing dataset`} ${
							state.name
					  } ${`from your imported file. Review the imported file and the changes that will be applied by importing it`}.`}
			</Alert>
			<Card data-onboarding="import-data-card">
				<CardHeader
					avatar={<FileTypeAvatar type={fileType} />}
					title={data.fileData.name}
					titleTypographyProps={{
						variant: 'h6',
					}}
					subheader={`${get(analyzedData, 'column_count', '')} ${t`columns`} - ${get(
						analyzedData,
						'data_row_count',
						''
					)} ${t`rows`}`}
					subheaderTypographyProps={{
						variant: 'body2',
					}}
				/>
				<CardContent>
					{loadingAnalyze ? (
						<Loader empty />
					) : error ? (
						<Alert
							severity="error"
							action={
								<ActionButton
									color="inherit"
									size="small"
									onClick={onBack}
								>
									{t`Back to upload`}
								</ActionButton>
							}
						>
							{errorMessages[error] ?? t`There is a problem with your uploaded file`}
						</Alert>
					) : (
						<Fragment>
							{(fieldsWithMatchedDataField.length > 0 || missingFields.length > 0) && (
								<Fragment>
									{missingDateField && (
										<Alert
											severity="warning"
											className={classes.spaceAlert}
										>
											{t`Your upload didn't contain the date field that was included in your previous import. Today's date will be used as feedback date.`}
										</Alert>
									)}
									<Grid
										container
										spacing={3}
									>
										<Divider />
										{fieldsWithMatchedDataField.length > 0 && (
											<Grid
												item
												xs={missingFields.length > 0 ? 6 : 12}
											>
												<Typography variant="subtitle1">{t`Previously mapped fields found in field`}</Typography>

												{fieldsWithMatchedDataField.map(field => {
													return (
														<Chip
															className={classes.chips}
															key={field.column_name}
															icon={
																<DataFieldIcon
																	{...field.datafield}
																	var_type={Number(field.var_type)}
																/>
															}
															label={`${field.column_name} ${t`imported as`} ${
																field.datafield.system_var
															}`}
														/>
													);
												})}
											</Grid>
										)}
										{missingFields.length > 0 && (
											<Grid
												item
												xs={fieldsWithMatchedDataField.length > 0 ? 6 : 12}
											>
												<Typography variant="subtitle1">{t`Previously mapped fields but not found in file`}</Typography>
												{missingFields.map(field => {
													return (
														<Chip
															className={classes.chips}
															key={field.column_name}
															icon={<DataFieldIcon var_type={Number(field.var_type)} />}
															label={`${field.import_var} ${t`imported as`} ${
																field.system_var
															}`}
														/>
													);
												})}
											</Grid>
										)}
									</Grid>
									<Box
										mt={2}
										mb={2}
									>
										<Divider />
									</Box>
								</Fragment>
							)}
							{state.fields.length > 0 && (
								<Fragment>
									<Typography variant="subtitle1">{t`Not yet mapped fields found in file`}</Typography>
									<Typography
										variant="body2"
										color="textSecondary"
									>{t`Decide what columns you want to import into your dataset and assign a data type to those fields.`}</Typography>

									{dateFieldCount > 1 && datasetId === 'new' && (
										<Alert
											severity="warning"
											className={classes.spaceAlert}
										>
											{t`We found more than one date type field in your import. You can only select one date field als feedback date, if you don't select any date type fields today's date will be used.`}
										</Alert>
									)}

									<List>
										<ListSubheader>
											<Grid
												container
												spacing={2}
											>
												<Grid
													item
													xs={1}
													className={classes.ellipsis}
													data-onboarding="import-checkbox-col"
												>
													{t`Import`}
												</Grid>
												<Grid
													item
													xs={2}
													className={classes.ellipsis}
													data-onboarding="import-name-col"
												>
													{t`Column name`}
												</Grid>
												<Grid
													item
													xs={3}
													className={classes.ellipsis}
													data-onboarding="import-preview-col"
												>
													{t`Content row 1`}
												</Grid>
												<Grid
													item
													xs={3}
													className={classes.ellipsis}
												>
													{t`Content row 2`}
												</Grid>
												<Grid
													item
													xs={3}
													className={classes.ellipsis}
													data-onboarding="import-datatype-col"
												>
													{t`Data type`}
												</Grid>
											</Grid>
										</ListSubheader>
										{state.fields.map((field, fieldIndex) => {
											return (
												<ListItem key={field.column_name + fieldIndex}>
													<ListItemText
														primary={
															<Grid
																container
																spacing={2}
																alignItems="center"
															>
																<Grid
																	item
																	xs={1}
																>
																	<Checkbox
																		edge="start"
																		checked={Number(field.var_type) !== 0}
																		onClick={e => {
																			if (e.target.checked) {
																				const foundField = analyzedData.fields.find(
																					f => f.column_name === field.column_name
																				);
																				setField(
																					`[${fieldIndex}].var_type`,
																					foundField && foundField.var_type !== 0
																						? foundField.var_type
																						: 1
																				);
																			} else {
																				setField(`[${fieldIndex}].var_type`, 0);
																			}
																		}}
																		// disableRipple
																	/>
																</Grid>
																<Grid
																	item
																	xs={2}
																	className={classes.ellipsis}
																>
																	{field.column_name}
																</Grid>
																<Grid
																	item
																	xs={3}
																	className={classes.ellipsis}
																>
																	{field.content[0]}
																</Grid>
																<Grid
																	item
																	xs={3}
																	className={classes.ellipsis}
																>
																	{field.content[1]}
																</Grid>
																<Grid
																	item
																	xs={3}
																>
																	<VarTypeSelect
																		value={String(field.var_type)}
																		onChange={e =>
																			setField(
																				`[${fieldIndex}].var_type`,
																				e.target.value,
																				e.target.value == 7
																			)
																		}
																		prependOption={{
																			label: t`Don't import`,
																			value: 0,
																			icon: <StopIcon />,
																		}}
																		types={[
																			'1',
																			'4',
																			'6',
																			'7',
																			'10',
																			'12',
																			'13',
																			'16',
																			'19',
																			'20',
																			'21',
																			'30',
																			'35',
																			'36',
																			'37',
																			'39',
																			'40',
																			'43',
																			'44',
																			'45',
																			'46',
																		]}
																		disabledTypes={
																			missingDateField || hasMatchedDateField ? ['7'] : []
																		}
																	/>
																</Grid>
															</Grid>
														}
													/>
												</ListItem>
											);
										})}
									</List>
								</Fragment>
							)}
						</Fragment>
					)}
				</CardContent>
			</Card>

			{!error && (
				<ActionButton
					action={datasetId !== 'new' ? 'add' : 'save'}
					className={classes.fab}
					fab
					variant="extended"
					color="secondary"
					loading={loadingPost}
					onClick={saveDataSet}
					data-test-element="datasetCreateDataset"
					data-onboarding="create-dataset"
				>
					{datasetId !== 'new' ? t`Add to dataset` : t`Create dataset`}
				</ActionButton>
			)}

			<SimpleDialog
				open={showNoDateDialog}
				onClose={() => setShowNoDateDialog(false)}
				title={t`No date field selected`}
				submit={datasetId !== 'new' ? t`Add to dataset` : t`Create dataset`}
				onSubmit={postForm}
				loading={loadingPost}
			>
				<Typography gutterBottom>
					{missingDateField ? (
						t`Your upload didn't contain the date field that was included in your previous import.`
					) : (
						<Trans>
							You didn't select a <strong>date</strong> field inside your imported file.
						</Trans>
					)}
				</Typography>
				<Typography>
					<Trans>
						<strong>Today's date</strong> will be used as feedback date. Do you want to
						continue?
					</Trans>
				</Typography>
			</SimpleDialog>
		</Fragment>
	);
}
