import { defaultInsightsChartOptions } from './defaultChartOptions';

export const xIsSameAsYSerieValue = 'x_same_as_y';

export const directInsightChartTypes = {
	bar_count: 'bar_count',
	bar_top_5: 'bar_top_5',
	column_count: 'column_count',
	column_count_sorted_desc: 'column_count_sorted_desc',
	column_count_thumbs: 'column_count_thumbs',
	column_count_checkbox: 'column_count_checkbox',
	column_nps_stacked: 'column_nps_stacked',
	column_gcr_stacked: 'column_gcr_stacked',
	score_count: 'score_count',
	line_total_over_time: 'line_total_over_time',
	line_average_over_time: 'line_average_over_time',
	line_nps_over_time: 'line_nps_over_time',
	line_ces_over_time: 'line_ces_over_time',
	line_gcr_over_time: 'line_gcr_over_time',
};

export const directInsightsChartsWithPercentageOption = [
	'column_count',
	'column_count_sorted_desc',
	'column_count_checkbox',
];

export const directInsightChartOptions = {
	[directInsightChartTypes.bar_count]: {
		subTitleKey: 'reporting-insights-bar-subtitle-count',
		type: 'bar',
		serieOptions: {
			average: 0,
			order: 'desc',
			y: 'self',
			x: 'self',
		},
		plotOptions: {
			series: {
				...defaultInsightsChartOptions.plotOptions.series,
				colorByPoint: true,
			},
		},
		legend: {
			enabled: false,
		},
	},
	[directInsightChartTypes.bar_top_5]: {
		subTitleKey: 'reporting-insights-bar-subtitle-count_top_5',
		type: 'bar',
		serieOptions: {
			average: 0,
			y: 'self',
			x: 'self',
			limit: 5,
			order: 'desc',
		},
		plotOptions: {
			series: {
				...defaultInsightsChartOptions.plotOptions.series,
				colorByPoint: true,
			},
		},
		legend: {
			enabled: false,
		},
	},
	[directInsightChartTypes.column_count]: {
		subTitleKey: 'reporting-insights-column-subtitle-count',
		type: 'column',
		serieOptions: {
			average: 0,
			y: 'self',
			x: 'self',
		},
		plotOptions: {
			series: {
				...defaultInsightsChartOptions.plotOptions.series,
				colorByPoint: true,
			},
		},
		legend: {
			enabled: false,
		},
		perc: true,
	},
	[directInsightChartTypes.column_count_sorted_desc]: {
		subTitleKey: 'reporting-insights-column-subtitle-count',
		type: 'column',
		serieOptions: {
			order: 'desc',
			average: 0,
			y: 'self',
			x: 'self',
		},
		plotOptions: {
			series: {
				...defaultInsightsChartOptions.plotOptions.series,
				colorByPoint: true,
			},
		},
		legend: {
			enabled: false,
		},
	},
	[directInsightChartTypes.column_count_checkbox]: {
		subTitleKey: 'reporting-insights-column-subtitle-count',
		type: 'column',
		serieOptions: {
			average: 0,
			y: 'self',
			x: 'self',
		},
		plotOptions: {
			series: {
				...defaultInsightsChartOptions.plotOptions.series,
				colorByPoint: false,
			},
		},
		xAxis: {
			labels: {
				enabled: false,
			},
		},
		legend: {
			labelFormatter: function () {
				try {
					const option = this.name.split('-');
					return option[0];
				} catch (e) {
					return this.name;
				}
			},
		},
	},
	[directInsightChartTypes.column_count_thumbs]: {
		subTitleKey: 'reporting-insights-column-subtitle-count_thumbs',
		type: 'column',
		serieOptions: {
			average: 0,
			y: 'self',
			x: 'self',
		},
		sentiment: ['negative', 'positive'],
		plotOptions: {
			series: {
				...defaultInsightsChartOptions.plotOptions.series,
				colorByPoint: true,
			},
		},
		legend: {
			enabled: false,
		},
	},
	[directInsightChartTypes.column_nps_stacked]: {
		subTitleKey: 'reporting-insights-stacked-subtitle-nps',
		type: 'column',
		serieOptions: {
			average: 3,
			y: 'self',
			x: 'all',
		},
		customSeries: [
			{
				perc_cats: ['10', '9'],
				name: 'Promoters',
				sentiment: 'positive',
			},
			{
				perc_cats: ['8', '7'],
				name: 'Passives',
				sentiment: 'neutral',
			},
			{
				perc_cats: ['6', '5', '4', '3', '2', '1', '0'],
				name: 'Detractors',
				sentiment: 'negative',
			},
		],
		plotOptions: {
			series: {
				...defaultInsightsChartOptions.plotOptions.series,
				stacking: 'percentage',
			},
		},
		xAxis: {
			labels: {
				enabled: false,
			},
		},
		yAxis: {
			labels: {
				y: 0,
				x: 0,
				align: 'center',
				enabled: true,
			},
			startOfWeek: 1,
		},
	},
	[directInsightChartTypes.column_gcr_stacked]: {
		subTitleKey: 'reporting-insights-stacked-subtitle-gcr',
		type: 'column',
		serieOptions: {
			average: 0,
			y: 'self',
			x: 'all',
		},
		customSeries: [
			{
				average: 17,
				name: 'Yes',
				sentiment: 'positive',
			},
			{
				average: 18,
				name: 'Partly',
				sentiment: 'neutral',
			},
			{
				average: 19,
				name: 'No',
				sentiment: 'negative',
			},
		],
		plotOptions: {
			series: {
				...defaultInsightsChartOptions.plotOptions.series,
				stacking: 'normal',
			},
		},
		xAxis: {
			labels: {
				enabled: false,
			},
		},
		yAxis: {
			minRange: '',
			labels: {
				y: 0,
				x: 0,
				align: 'center',
				enabled: true,
				rotation: '',
			},
			startOfWeek: 1,
		},
	},
	[directInsightChartTypes.score_count]: {
		subTitleKey: 'radio_count',
		type: 'score',
		serieOptions: {
			average: 0,
			y: 'self',
			x: 'all',
		},
	},
	[directInsightChartTypes.line_total_over_time]: {
		subTitleKey: 'reporting-insights-line-subtitle-total',
		type: 'line',
		serieOptions: {
			average: 0,
			y: 'self',
			x: 'datetime',
		},
		legend: {
			enabled: false,
		},
	},
	[directInsightChartTypes.line_average_over_time]: {
		subTitleKey: 'reporting-insights-line-subtitle-average',
		type: 'line',
		decimal: 1,
		serieOptions: {
			average: 1,
			y: 'self',
			x: 'datetime',
		},
		legend: {
			enabled: false,
		},
	},
	[directInsightChartTypes.line_nps_over_time]: {
		subTitleKey: 'reporting-insights-line-subtitle-nps',
		type: 'line',
		serieOptions: {
			average: 2,
			y: 'self',
			x: 'datetime',
		},
	},
	[directInsightChartTypes.line_ces_over_time]: {
		subTitleKey: 'reporting-insights-line-subtitle-ces',
		type: 'line',
		serieOptions: {
			average: 9,
			y: 'self',
			x: 'datetime',
		},
	},
	[directInsightChartTypes.line_gcr_over_time]: {
		subTitleKey: 'reporting-insights-line-subtitle-gcr',
		type: 'line',
		serieOptions: {
			average: 10,
			y: 'self',
			x: 'datetime',
		},
	},
};
