import React, { useState, useContext } from 'react';

import { createStore, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Mjolnir, EFM } from '@/api';
import { useAppContext } from '@/components/AppContext';
import { debounce, tryParse } from '@/utils';

import { ruleBase, conditionBase, parseDeploymentResponse } from './deploymentUtils';

const DeploymentStoreContext = React.createContext(null);

const createDeploymentStore = ({
	domain,
	organisationId,
	deploymentId,
	userUuid,
	editorType,
}) => {
	return createStore(
		immer(
			(set, get) => ({
				loading: false,
				surveysLoading: false,
				current: {
					name: '',
					description: '',
					rules: [],
				},
				draft: {
					name: '',
					description: '',
					rules: [],
				},
				versions: [],
				workingDraft: {
					name: '',
					description: '',
					rules: [],
				},
				surveysByKey: {},
				surveysByProject: [],
				filters: {},
				openRules: new Set(),
				savedConditions: [],
				openConditions: new Set(),
				actions: {
					setVersion(version) {
						set(state => {
							if (typeof version === 'number') {
								const _version = state.versions.find(
									prevVersion => prevVersion.revision === version
								);
								if (_version?.deployment) {
									state.workingDraft = _version.deployment;
								}
								return;
							}

							state.workingDraft = state[version];
						});
					},

					//deployment editing actions
					handleDraftChange(fn) {
						const handleChange = () => {
							set(state => {
								state.pendingDraftChanges = true;
								state.workingDraft.revision = 'draft';
								state._pendingChange = null;
							});
							fn();
							get().actions.saveDraft(() => {
								set(state => {
									state.draft = state.workingDraft;
								});
							});
						};
						if (get().workingDraft.revision === 'draft' || !get().draft?.rules?.length) {
							handleChange();
						} else {
							set(state => {
								state._pendingChange = handleChange;
							});
						}
					},

					acceptChange() {
						get()._pendingChange();
					},

					rejectChange() {
						set(state => {
							state._pendingChange = null;
						});
					},

					setDescription(value) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.description = value;
							});
						});
					},

					moveRule({ destinationIndex, sourceIndex }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.rules.splice(
									destinationIndex,
									0,
									state.workingDraft.rules.splice(sourceIndex, 1)[0]
								);
							});
						});
					},

					deleteRule({ index }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.rules.splice(index, 1);
							});
						});
					},

					createTemporaryRule({ surveyKey, domain, editorType, sdkType, surveyFormat }) {
						set(state => {
							const rule = ruleBase({
								surveyKey,
								domain,
								editorType,
								sdkType,
								surveyFormat,
							});
							state.temporaryRule = rule;
							state.hasUnsavedRuleChanges = true;
						});
					},

					saveTemporaryRule() {
						get().actions.handleDraftChange(() => {
							set(state => {
								if (state.temporaryRule) {
									const ruleIndex = state.workingDraft.rules.findIndex(
										rule => rule.id === state.temporaryRule.id
									);
									if (ruleIndex !== -1) {
										state.workingDraft.rules[ruleIndex] = state.temporaryRule;
									} else {
										state.workingDraft.rules.push(state.temporaryRule);
									}
									state.temporaryRule = null;
									state.hasUnsavedRuleChanges = false;
									state.newRule = false;
									state.drawerOpen = false;
								}
							});
						});
					},

					discardTemporaryRule() {
						set(state => {
							state.temporaryRule = null;
							state.newRule = false;
							state.hasUnsavedRuleChanges = false;
							state.drawerOpen = false;
						});
					},

					toggleOpenConditions(id, expanded) {
						set(state => {
							if (typeof expanded !== 'undefined') {
								if (expanded) {
									state.openConditions.add(id);
								} else {
									state.openConditions.delete(id);
								}
								return;
							}

							if (state.openConditions.has(id)) {
								state.openConditions.delete(id);
							} else {
								state.openConditions.add(id);
							}
						});
					},

					setConditionOption({ conditionIndex, condition, value }) {
						set(state => {
							if (state.temporaryRule) {
								state.temporaryRule.if[conditionIndex][condition] = value;
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					setNestedConditionOption({
						conditionIndex,
						condition,
						nested,
						nested2,
						nested3,
						value,
					}) {
						set(state => {
							if (state.temporaryRule) {
								if (typeof nested3 !== 'undefined') {
									state.temporaryRule.if[conditionIndex][condition][nested][nested2][
										nested3
									] = value;
								} else if (typeof nested2 !== 'undefined') {
									state.temporaryRule.if[conditionIndex][condition][nested][nested2] =
										value;
								} else {
									state.temporaryRule.if[conditionIndex][condition][nested] = value;
								}
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					setSurveyKey({ value }) {
						set(state => {
							if (state.temporaryRule) {
								state.temporaryRule.then[0].args[0] = value;
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					setRenderDiv({ ruleIndex, value }) {
						set(state => {
							if (state.temporaryRule) {
								state.temporaryRule.then[0].args[2] = value;
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					setRenderOption({ option, value }) {
						set(state => {
							if (state.temporaryRule) {
								try {
									state.temporaryRule.then[0][option] = value;
									state.hasUnsavedRuleChanges = true;
								} catch (e) {}
							}
						});
					},

					setMobileConditionOption({ conditionIndex, condition, nested, value }) {
						set(state => {
							if (state.temporaryRule) {
								if (typeof nested !== 'undefined') {
									state.temporaryRule.if[conditionIndex].mobile[condition][nested] =
										value;
								} else {
									state.temporaryRule.if[conditionIndex].mobile[condition] = value;
								}
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					setNestedMobileConditionOption({
						conditionIndex,
						condition,
						nested,
						nested2,
						nested3,
						value,
					}) {
						set(state => {
							if (state.temporaryRule) {
								if (typeof nested3 !== 'undefined') {
									state.temporaryRule.if[conditionIndex].mobile[condition][nested][
										nested2
									][nested3] = value;
								} else if (typeof nested2 !== 'undefined') {
									state.temporaryRule.if[conditionIndex].mobile[condition][nested][
										nested2
									] = value;
								} else {
									state.temporaryRule.if[conditionIndex].mobile[condition][nested] =
										value;
								}
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					addToConditionArray({ conditionIndex, condition, conditionOrIndex, value }) {
						set(state => {
							if (state.temporaryRule) {
								if (typeof conditionOrIndex !== 'undefined') {
									state.temporaryRule.if[conditionIndex][condition][
										conditionOrIndex
									].push(value);
								} else {
									if (!Array.isArray(state.temporaryRule.if[conditionIndex][condition])) {
										state.temporaryRule.if[conditionIndex][condition] = [];
									}
									state.temporaryRule.if[conditionIndex][condition].push(value);
								}
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					removeFromConditionArray({ conditionIndex, condition, index, orIndex }) {
						set(state => {
							if (state.temporaryRule) {
								if (typeof orIndex !== 'undefined') {
									state.temporaryRule.if[conditionIndex][condition][orIndex].splice(
										index,
										1
									);
								} else {
									state.temporaryRule.if[conditionIndex][condition].splice(index, 1);
								}
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					addCondition({ ruleIndex }) {
						set(state => {
							if (state.temporaryRule) {
								state.temporaryRule.if.push(conditionBase(editorType));
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					addSavedCondition({ conditionSet }) {
						set(state => {
							state.temporaryRule.if.push(conditionSet);
							state.hasUnsavedRuleChanges = true;
						});
					},

					removeCondition({ ruleIndex, index }) {
						set(state => {
							if (state.temporaryRule) {
								state.temporaryRule.if.splice(index, 1);
								state.hasUnsavedRuleChanges = true;
							}
						});
					},

					setName(value) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.name = value;
							});
						});
					},

					setPublished(published, save) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.published = Boolean(published);
							});
							if (save) {
								get().actions.saveDeployment();
							}
						});
					},

					setFilter({ filter, value } = {}) {
						if (
							!value ||
							(typeof value === 'object' && Object.keys(value).length === 0)
						) {
							set(state => {
								delete state.filters[filter];
							});
						}

						set(state => {
							state.filters[filter] = value;
						});
					},

					setCreateNewRule(_new = true) {
						set(state => {
							state.newRule = _new;
							state.drawerOpen = true;
						});
					},

					setSelectedRule(ruleId) {
						set(state => {
							if (ruleId) {
								const rule = state.workingDraft.rules.find(rule => rule.id === ruleId);
								state.temporaryRule = rule ? { ...rule } : null;
								state.hasUnsavedRuleChanges = false;
								state.drawerOpen = true;
							} else {
								state.temporaryRule = null;
								state.hasUnsavedRuleChanges = false;
								state.drawerOpen = false;
							}
						});
					},

					setNewRuleForm(formKey) {
						set(state => {
							state.newRuleForm = formKey;
						});
					},

					toggleRuleExpanded(ruleId, expanded) {
						set(state => {
							if (typeof expanded !== 'undefined') {
								if (expanded) {
									state.openRules.add(ruleId);
								} else {
									state.openRules.delete(ruleId);
								}
								return;
							}

							if (state.openRules.has(ruleId)) {
								state.openRules.delete(ruleId);
							} else {
								state.openRules.add(ruleId);
							}
						});
					},

					setAllRulesExpanded(expanded) {
						set(state => {
							if (expanded) {
								state.openRules = new Set(state.workingDraft.rules.map(rule => rule.id));
							} else {
								state.openRules.clear();
							}
						});
					},
					//end deployment editing actions

					async getSurveys() {
						set(state => {
							state.surveysLoading = true;
						});
						try {
							const response = await EFM.post(
								'/survey/ajax/get-surveys-from-organisation'
							);
							if (response.code === 200) {
								set(state => {
									state.surveysByProject = response.surveys;
									state.surveysByKey = response.surveys.reduce(
										(objectBySurveyKey, currentProject) => {
											[
												...currentProject.web_surveys,
												...currentProject.sdk_surveys,
											].forEach(survey => {
												objectBySurveyKey[survey.survey_key] = survey;
											});

											return objectBySurveyKey;
										},
										{}
									);
								});
							}
						} catch (e) {}
						set(state => {
							state.surveysLoading = false;
						});
					},
					async getCurrent() {
						set(state => {
							state.loading = true;
						});
						try {
							const response = await Mjolnir.get(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}`
							);
							if (Number(response.code) === 200) {
								set(state => {
									const deployment = parseDeploymentResponse(response?.deployment);
									state.current = deployment;
									state.workingDraft = deployment;
								});
							}
						} catch (e) {}
						set(state => {
							state.loading = false;
						});
					},
					async getDraft() {
						try {
							const response = await Mjolnir.get(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}/draft`
							);
							set(state => {
								state.draft = response?.draft?.deployment ?? {};
								state.draft.revision = 'draft';
							});
						} catch (e) {}
					},
					async getVersions() {
						try {
							const response = await Mjolnir.get(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}/versions`
							);

							set(state => {
								state.versions = response.versions;
							});
						} catch (e) {}
					},
					saveDraft: debounce(async (onSuccess = () => {}) => {
						if (get().workingDraft.revision !== 'draft') {
							return;
						}

						try {
							set(state => {
								state.saveDraftLoading = true;
							});
							await Mjolnir.post(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}/draft`,
								{
									org_id: organisationId,
									type: get().workingDraft.type,
									user_uuid: userUuid,

									name: get().workingDraft.name,
									rules: get().workingDraft.rules,
									published: get().workingDraft.published,
								}
							);
							onSuccess();
							set(state => {
								state.pendingDraftChanges = false;
								state.draft.rules = state.workingDraft.rules;
								state.draft.name = state.workingDraft.name;
								state.draft.published = state.workingDraft.published;
								state.workingDraft.lastSaved = new Date();
							});
						} catch (e) {}
						set(state => {
							state.saveDraftLoading = false;
						});
					}, 1000),
					async saveDeployment(onSuccess = () => {}) {
						set(state => {
							state.saveDeploymentLoading = true;
							state.workingDraft.revision = 'saved_draft';
						});
						try {
							const response = await Mjolnir.post(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}`,
								{
									org_id: organisationId,
									type: get().workingDraft.type,
									permission_groups: get().workingDraft.permission_groups,
									user_uuid: userUuid,

									name: get().workingDraft.name,
									rules: get().workingDraft.rules,
									published: get().workingDraft.published,
									description: get().workingDraft.description,
								}
							);
							get().actions.getCurrent();
							get().actions.getDraft();
							get().actions.getVersions();
							onSuccess(response);
						} catch (e) {
							set(state => {
								state.workingDraft.revision = 'draft';
							});
						}
						set(state => {
							state.saveDeploymentLoading = false;
						});
					},

					async getSavedConditions() {
						const response = await Mjolnir.get(
							`/api/1/pastease/${domain}/${organisationId}/conditions`
						);

						if (response.code === 200 && Array.isArray(response.conditions)) {
							set(state => {
								state.savedConditions = response.conditions;
							});
						}
					},
					async saveCondition({ onSuccess = () => {}, conditionSet, name }) {
						const response = await Mjolnir.post(
							`/api/1/pastease/${domain}/${organisationId}/conditions`,
							{
								type: get().workingDraft.type,
								name,
								condition_set: conditionSet,
							}
						);
						if (response.code === 200) {
							onSuccess();
							get().actions.getSavedConditions();
						}
					},
					async deleteCondition({ id, onSuccess = () => {} }) {
						const response = await Mjolnir.delete(
							`/api/1/pastease/${domain}/${organisationId}/conditions/${id}`
						);
						if (response.code === 200) {
							onSuccess();
							get().actions.getSavedConditions();
						}
					},
				},
			}),
			{ name: 'deployment-store' }
		)
	);
};

export function DeploymentStoreProvider({ children, deploymentId, editorType }) {
	const { app } = useAppContext();
	const [store] = useState(() =>
		createDeploymentStore({
			domain: app.domain,
			organisationId: Number(app.organisations.current.org_id),
			userUuid: app.users.current.uuid,
			deploymentId,
			editorType,
		})
	);

	return (
		<DeploymentStoreContext.Provider value={store}>
			{children}
		</DeploymentStoreContext.Provider>
	);
}

export function useDeploymentStore(selector) {
	const store = useContext(DeploymentStoreContext);
	if (!store) {
		throw new Error('Missing DeploymentStoreProvider');
	}
	return useStore(store, selector);
}
