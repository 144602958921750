import React from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import useOnboardingContext from './useOnboardingContext';
import { simulateClick } from './onboardingUtils';
import Joyride, { Row } from './Joyride';
import OnboardingVideo from './OnboardingVideo';

export function MenuTour({ newUserOnboardingTrial, ...props }) {
	const { t } = useTranslation();
	const { app } = useAppContext();

	return (
		<Joyride
			disableOverlay
			steps={[
				...(newUserOnboardingTrial
					? [
							{
								target: 'body',
								placement: 'center',
								outerContent: <OnboardingVideo inTooltip />,
								title: t`general_tour-menu-step_welcome-title`,
								content: (
									<div>
										<Row>
											<Trans
												i18nKey="general_tour-menu-step_welcome-row1"
												components={{
													strong: <strong />,
												}}
											/>
										</Row>
										<Row>
											<Trans
												i18nKey="general_tour-menu-step_welcome-row2"
												components={{
													strong: <strong />,
												}}
											/>
										</Row>
									</div>
								),
								disableOverlay: false,
								size: 'large',
							},
					  ]
					: []),
				{
					target: '[data-onboarding="main-nav"]',
					title: t`general_tour-menu-step_navigation-title`,
					content: (
						<>
							<Row>{t`general_tour-menu-step_navigation-row1`}</Row>
							<Row>
								<Trans
									i18nKey="general_tour-menu-step_navigation-row2"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-menu-step_navigation-row3"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-menu-step_navigation-row4"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-menu-step_navigation-row5"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-menu-step_navigation-row6"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
						</>
					),
					placement: 'right',
				},
				{
					target: '[data-onboarding="help-menu-button"]',
					title: t`general_tour-menu-step_help-title`,
					content: (
						<>
							<Row>
								<Trans
									i18nKey="general_tour-menu-step_help-row1"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-menu-step_help-row2"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
						</>
					),
				},
				...(app.session.onboarding_trial
					? [
							{
								target: '[data-onboarding="onboarding-menu"]',
								title: t`general_tour-menu-step_onboarding-title`,
								content: (
									<>
										<Row>
											<Trans
												i18nKey="general_tour-menu-step_onboarding-row1"
												components={{
													strong: <strong />,
												}}
											/>
										</Row>
										<Row>
											<Trans
												i18nKey="general_tour-menu-step_onboarding-row2"
												components={{
													strong: <strong />,
												}}
											/>
										</Row>
									</>
								),
							},
					  ]
					: []),
				...(!app.session.onboarding_trial
					? [
							{
								target: '[data-onboarding="global-filters"]',
								title: t`general_tour-menu-step_appsettings-title`,
								content: (
									<Trans
										i18nKey="general_tour-menu-step_appsettings-row1"
										components={{
											strong: <strong />,
										}}
									/>
								),
							},
					  ]
					: []),
			]}
			{...props}
		/>
	);
}

export function SurveyEditorTour({ ...props }) {
	const { t } = useTranslation();
	return (
		<Joyride
			steps={[
				{
					target: 'body',
					disableOverlay: false,
					placement: 'center',
					title: t`general_tour-survey_editor-step_welcome-title`,
					content: (
						<Trans
							i18nKey="general_tour-survey_editor-step_welcome-row1"
							components={{
								strong: <strong />,
							}}
						/>
					),
					dataTrackEvent: 'tour_surveybuilder_step1',
				},
				{
					target: '[data-onboarding="build-area"]',
					title: t`general_tour-survey_editor-step_build_area-title-ass`,
					content: (
						<Trans
							i18nKey="general_tour-survey_editor-step_build_area-row1"
							components={{
								strong: <strong />,
							}}
						/>
					),
					//	placement: 'left-start',
					dataTrackEvent: 'tour_surveybuilder_step2',
				},
				{
					target: '[data-onboarding="editor-sidebar"]',
					title: t`general_tour-survey_editor-step_toolbar-title`,
					content: (
						<Trans
							i18nKey="general_tour-survey_editor-step_toolbar-row1"
							components={{
								strong: <strong />,
							}}
						/>
					),
					placement: 'left-start',
					dataTrackEvent: 'tour_surveybuilder_step3',
				},
				{
					target: '[data-onboarding="build-tabs"]',
					title: t`general_tour-survey_editor-step_build-title`,
					content: (
						<>
							<Row>
								<Trans
									i18nKey="general_tour-survey_editor-step_build-row1"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-survey_editor-step_build-row2"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-survey_editor-step_build-row3"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-survey_editor-step_build-row4"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
						</>
					),
					placement: 'left-start',
					dataTrackEvent: 'tour_surveybuilder_step4',
				},
				{
					target: '[data-onboarding="design-tab"]',
					title: t`general_tour-survey_editor-step_design-title`,
					content: (
						<Trans
							i18nKey="general_tour-survey_editor-step_design-row1"
							components={{
								strong: <strong />,
							}}
						/>
					),
					dataTrackEvent: 'tour_surveybuilder_step5',
				},
				{
					target: '[data-onboarding="integration-tab"]',
					title: t`general_tour-survey_editor-step_integrate-title`,
					content: (
						<Trans
							i18nKey="general_tour-survey_editor-step_integrate-row1"
							components={{
								strong: <strong />,
							}}
						/>
					),
					dataTrackEvent: 'tour_surveybuilder_step6',
				},
				{
					target: '[data-onboarding="deploy-tab"]',
					title: t`general_tour-survey_editor-step_deploy-title`,
					content: (
						<Trans
							i18nKey="general_tour-survey_editor-step_deploy-row1"
							components={{
								strong: <strong />,
							}}
						/>
					),
					dataTrackEvent: 'tour_surveybuilder_step7',
				},
			]}
			{...props}
		/>
	);
}

export function SurveyEditorDesignTour(props) {}

export function DeploymentEditorTour({ ...props }) {
	const { t } = useTranslation();
	const onboarding = useOnboardingContext();

	const { editorType = 'web' } = useParams();

	function makeTarget(target) {
		if (onboarding.showDeploymentDrawer) {
			return `[data-onboarding="drawer-with-appbar-scroller"] ${target}`;
		}

		return target;
	}

	const steps =
		editorType === 'web'
			? [
					{
						disableOverlay: false,
						target: 'body',
						placement: 'center',
						title: t`general_tour-deployment_editor-step_welcome-title`,
						content: (
							<>
								<Row>
									<Trans
										i18nKey="general_tour-deployment_editor-step_welcome-row1"
										components={{
											strong: <strong />,
										}}
									/>
								</Row>
								<Row>
									<Trans
										i18nKey="general_tour-deployment_editor-step_welcome-row2"
										components={{
											strong: <strong />,
										}}
									/>
								</Row>
							</>
						),
						dataTrackEvent: 'tour_deployment_step1',
					},
					{
						target: makeTarget('[data-onboarding="add-form-fab"]'),
						title: t`general_tour-deployment_editor-step_add_form-title`,
						placement: 'top',
						content: (
							<Trans
								i18nKey="general_tour-deployment_editor-step_add_form-row1"
								components={{
									strong: <strong />,
								}}
							/>
						),
						dataTrackEvent: 'tour_deployment_step2',
					},
					{
						target: makeTarget('[data-onboarding="deployment-sidebar"]'),
						title: t`general_tour-deployment_editor-step_filters-title`,
						content: (
							<Trans
								i18nKey="general_tour-deployment_editor-step_filters-row1"
								components={{
									strong: <strong />,
								}}
							/>
						),
						placement: 'left-start',
						dataTrackEvent: 'tour_deployment_step3',
					},
					{
						target: '[data-onboarding="version-toggle"]',
						title: t`general_tour-deployment_editor-step_version-title`,
						content: (
							<Trans
								i18nKey="general_tour-deployment_editor-step_version-content"
								components={{
									strong: <strong />,
								}}
							/>
						),
						dataTrackEvent: 'tour_deployment_step7',
					},
					{
						target: makeTarget('[data-onboarding="deploy-status"]'),
						title: t`general_tour-deployment_editor-step_status-title`,
						content: (
							<Trans
								i18nKey="general_tour-deployment_editor-step_status-row1"
								components={{
									strong: <strong />,
								}}
							/>
						),
						dataTrackEvent: 'tour_deployment_step8',
					},
					{
						target: makeTarget('[data-onboarding="deploy-buttons"]'),
						title: t`general_tour-deployment_editor-step_save_deploy-title`,
						content: (
							<>
								<Row>
									<Trans
										i18nKey="general_tour-deployment_editor-step_save_deploy-row1"
										components={{
											strong: <strong />,
										}}
									/>
								</Row>
								<Row>
									<Trans
										i18nKey="general_tour-deployment_editor-step_save_deploy-row2"
										components={{
											strong: <strong />,
										}}
									/>
								</Row>
							</>
						),
						dataTrackEvent: 'tour_deployment_step9',
					},
			  ]
			: [{}];

	return (
		<Joyride
			steps={steps}
			{...props}
		/>
	);
}

export function FeedbackInboxTour({ fromTrialOnboarding, onClose, ...props }) {
	const { t } = useTranslation();
	return (
		<Joyride
			disableOverlay
			steps={[
				{
					target: '[data-onboarding="feedback-expansion-panel"]',
					title: t`general_tour-feedback_inbox-step_feedback_item-title`,
					content: (
						<Trans
							i18nKey="general_tour-feedback_inbox-step_feedback_item-row1"
							components={{
								strong: <strong />,
							}}
						/>
					),
					action: () =>
						simulateClick('[data-onboarding="feedback-expansion-panel-summary"]'),
					dataTrackEvent: 'tour_inbox_step1',
				},
				{
					target: '[data-onboarding="customer-card"]',
					title: t`general_tour-feedback_inbox-step_contact-title`,
					dataTrackEvent: 'tour_inbox_step2',
				},
				{
					target: '[data-onboarding="notes-card"]',
					title: t`general_tour-feedback_inbox-step_notes-title`,
					dataTrackEvent: 'tour_inbox_step3',
				},
				{
					target: '[data-onboarding="feedback-highlights"]',
					title: t`general_tour-feedback_inbox-step_highlights-title`,
					dataTrackEvent: 'tour_inbox_step4',
				},
				{
					target: '[data-onboarding="feedback-summary"]',
					title: t`general_tour-feedback_inbox-step_customer-title`,
					dataTrackEvent: 'tour_inbox_step5',
				},
				{
					target: '[data-onboarding="feedback-meta"]',
					title: t`general_tour-feedback_inbox-step_meta-title`,
					dataTrackEvent: 'tour_inbox_step6',
				},
				{
					target: '[data-onboarding="feedback-actions"]',
					title: t`general_tour-feedback_inbox-step_actions-title`,
					content: (
						<Trans
							i18nKey="general_tour-feedback_inbox-step_actions-row1"
							components={{
								strong: <strong />,
							}}
						/>
					),
					dataTrackEvent: 'tour_inbox_step7',
				},
				{
					target: '[data-onboarding="list-with-sidebar-drawer"]',
					title: t`general_tour-feedback_inbox-step_filter-title`,
					content: (
						<>
							<Row>{t`general_tour-feedback_inbox-step_filter-row1`}</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_filter-row2"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_filter-row3"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_filter-row4"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_filter-row5"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_filter-row6"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_filter-row7"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
						</>
					),
					placement: 'right',
					dataTrackEvent: 'tour_inbox_step8',
				},
				{
					target: '[data-onboarding="list-controls-actions"]',
					title: t`general_tour-feedback_inbox-step_inbox_actions-title`,
					placement: 'bottom-start',
					content: (
						<>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_inbox_actions-row1"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_inbox_actions-row2"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_inbox_actions-row3"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
						</>
					),
					dataTrackEvent: 'tour_inbox_step9',
				},
				{
					target: '[data-onboarding="list-controls-sort"]',
					title: t`Sort your feedback`,
					placement: 'bottom-start',
					content: (
						<>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_sort-row1"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
							<Row>
								<Trans
									i18nKey="general_tour-feedback_inbox-step_sort-row2"
									components={{
										strong: <strong />,
									}}
								/>
							</Row>
						</>
					),
					dataTrackEvent: 'tour_inbox_step10',
				},
				// {
				// 	target:'[data-onboarding="feedback-inbox-sort"]',
				// 	title:t`Sort your feedback`,
				// 	content:<>
				// 		<Row>
				// 			<Trans>
				// 				You can <strong>sort</strong> your feedback by feedback date and customer name.
				// 			</Trans>
				// 		</Row>
				// 		<Row>
				// 			<Trans>
				// 				Use the <strong>filter button</strong> to hide and show the filter sidebar.
				// 			</Trans>
				// 		</Row>
				// 	</>
				// }
			]}
			onClose={() => {
				onClose();
			}}
			{...props}
		/>
	);
}

export function DashboardTour({ ...props }) {
	const { t } = useTranslation();
	return (
		<Joyride
			disableOverlay
			steps={[
				{
					target: '[data-onboarding="edit-dashboard-button"]',
					title: t`general_tour-dashboard-step_layout-title`,
					content: <Row>{t`general_tour-dashboard-step_layout-row1`}</Row>,
				},
				{
					target: '[data-onboarding="add-chart-button"]',
					title: t`general_tour-dashboard-step_charts-title`,
					placement: 'top',
					content: (
						<Row>
							<Trans
								i18nKey="general_tour-dashboard-step_charts-row1"
								components={{
									strong: <strong />,
								}}
							/>
						</Row>
					),
				},
			]}
			{...props}
		/>
	);
}

export function DashboardLayoutTour(props) {
	const { t } = useTranslation();
	return (
		<Joyride
			disableOverlay
			steps={[
				{
					target: '[data-onboarding="dashboard-chart-card"]',
					title: t`general_tour-dashboard_layout-step_rearrange-title`,
				},
				{
					target: '.react-resizable-handle',
					title: t`general_tour-dashboard_layout-step_resize-title`,
				},
				{
					target: '[data-onboarding="add-chart-button"]',
					title: t`general_tour-dashboard_layout-step_add-title`,
				},
				{
					target: '[data-onboarding="save-layout"]',
					title: t`general_tour-dashboard_layout-step_save-title`,
				},
			]}
			{...props}
		/>
	);
}
