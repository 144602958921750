import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@/styles';
import { Autocomplete } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	root: {
		maxHeight: 72,
		overflowY: 'auto',
		overflowX: 'hidden',
	},
}));

export default function DeploymentEditorMultipleValuesAutocomplete({
	value,
	options = [],
	onChange,
	onAddInput = () => {},
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const ref = useRef(null);

	// Normalize input value to always be an array internally
	const normalizedValue = Array.isArray(value) ? value : value ? [value] : [];

	const handleChange = (event, newValue) => {
		if (!onChange) return;

		const outputValue = newValue.length === 1 ? newValue[0] : newValue;
		onChange(event, outputValue);
	};

	useEffect(() => {
		setTimeout(() => {
			if (ref.current) {
				ref.current.scrollTop = ref.current.scrollHeight;
			}
		});
	}, [value]);

	return (
		<Autocomplete
			ref={ref}
			value={normalizedValue}
			options={options}
			onChange={handleChange}
			classes={classes}
			includeInputInList
			multiple
			freeSolo
			clearOnBlur={false}
			fullWidth
			onBlur={e => {
				const trimmedValue = e.target.value.trim();
				if (trimmedValue) {
					onAddInput(trimmedValue);
				}
			}}
			onPaste={e => {
				e.preventDefault();
				const pasteValue = e.clipboardData
					.getData('Text')
					.split(',')
					.map(value => value.trim())
					.filter(Boolean);
				onAddInput(pasteValue);
			}}
		/>
	);
}
