const ruleTests = {
	required: value => String(value).length > 0,
	min: (value, length) => String(value).length >= length,
	max: (value, length) => String(value).length <= length,
	email: value => /\S+@\S+\.\S+/.test(value),
	phone: value => value.length > 9 && value.match(/^[\(\)\s\-\+\d]{10,17}$/),
	phone_alt: value =>
		(value.length > 9 && value.match(/^[\(\)\s\-\+\d]{10,17}$/)) ||
		(value.length > 8 && value.match(/^[^0][\(\)\s\-\+\d]{8,15}$/)), //also validate phone without starting 0
	custom: (value, customFn, customProps) => customFn(value, customProps),
	mfa_token: value => value.match(/^[\d]{7}$/),
	url: value => {
		const localhostRegex = /^(?:https?:\/\/)localhost(?::\d{2,5})?$/i;
		const urlRegex =
			/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

		return localhostRegex.test(value) || urlRegex.test(value);
	},
	urlHttps: value => {
		return /^(?:(?:(?:https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
			value
		);
	},
	alphaNumeric: value => {
		return (
			value.search(
				/[^A-Za-z0-9ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåçèéêëìíîïðñòóôõöùúûüýÿ :;.,?!\(\)'_-]/g
			) === -1
		);
	},
	domain: value => {
		return /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/.test(
			value
		);
	},
	analyticsId: value => /^(G|UA)-[a-zA-Z0-9-]+$/.test(value),
};

function validate(value, rules = []) {
	//rules may be an array or object here, calling object entries makes them the same structure here
	const rulesArr = Array.isArray(rules)
		? rules
		: typeof rules === 'object'
		? Object.entries(rules)
		: [];

	for (let rule of rulesArr) {
		const [ruleName, ruleParameters] = rule;

		const ruleNameUsedForTest = ruleName?.includes('custom') ? 'custom' : ruleName;

		if (typeof ruleTests[ruleNameUsedForTest] !== 'function') {
			return {
				valid: true,
			};
		}

		const tested = ruleTests[ruleNameUsedForTest](value, ruleParameters);

		if (!tested) {
			return {
				valid: false,
				failedRule: ruleName,
			};
		}
	}

	return { valid: true };
}

function testValidObject(validObject) {
	return (
		Object.keys(validObject).filter(key => validObject[key]).length ===
		Object.keys(validObject).length
	);
}

export { validate, testValidObject, ruleTests };
