import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import Grid from './Grid';
import Card from './Card';
import CardContent from './CardContent';
import CardActions from './CardActions';
import Typography from './Typography';
import ActionButton from './ActionButton';
import ActionIconButton from './ActionIconButton';
import Box from './Box';

const useStyles = makeStyles(theme => ({
	card: {
		'&:not(:first-child)': {
			marginTop: theme.spacing(1),
		},
	},
	cardContent: {
		paddingBottom: theme.spacing(0),
	},
	cardActions: {
		paddingTop: theme.spacing(0),
	},
	orText: {
		textTransform: 'uppercase',
	},
}));

export default function EditorNestedOrAndArrayConditions({
	children = () => {},
	orArray,
	conditionName,
	onAddAnd = () => {},
	onAddOr = () => {},
	onRemoveAnd = () => {},
	hintText,
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Box width="100%">
			{orArray.map((andArr, orIndex) => {
				return (
					<Fragment key={'or' + orIndex}>
						<Card
							className={classes.card}
							key={orIndex}
						>
							<CardContent className={classes.cardContent}>
								{Array.isArray(andArr) &&
									andArr.map((conditionObj, andIndex) => {
										return (
											<Grid
												container
												key={andIndex}
											>
												<Grid
													item
													xs
												>
													{children({ conditionObj, andIndex, andArr, orIndex })}
												</Grid>

												<Grid item>
													<ActionIconButton
														action="remove_list"
														onClick={() =>
															onRemoveAnd({ andArr, orIndex, andIndex, conditionName })
														}
													/>
												</Grid>
											</Grid>
										);
									})}
								{hintText && (
									<Typography
										variant="caption"
										color="textSecondary"
									>
										{hintText}
									</Typography>
								)}
							</CardContent>

							<CardActions className={classes.cardActions}>
								<ActionButton
									label={t`And`}
									action="add_list"
									onClick={() => onAddAnd({ orIndex, conditionName })}
								/>
							</CardActions>
						</Card>
						{orIndex !== orArray.length - 1 && (
							<Typography
								mt={0.5}
								mb={0.5}
								align="center"
								display="block"
								variant="caption"
								color="textSecondary"
								fontWeight="medium"
								className={classes.orText}
							>
								{t`Or`}
							</Typography>
						)}
					</Fragment>
				);
			})}
			<Box
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
			>
				<ActionButton
					label={t`Or`}
					action="add_list"
					onClick={() => onAddOr({ conditionName })}
				/>
			</Box>
		</Box>
	);
}
