import React from 'react';

import { useTranslation } from 'react-i18next';
import { SimpleDialog, Typography } from '@/components/Layout';
import { useDeploymentStore } from './DeploymentStore';

export default function PendingDraftDialog() {
	const { t } = useTranslation();
	const _pendingChange = useDeploymentStore(store => store._pendingChange);
	const acceptChange = useDeploymentStore(store => store.actions.acceptChange);
	const rejectChange = useDeploymentStore(store => store.actions.rejectChange);

	return (
		<SimpleDialog
			title={t`data_collection-deployment_editor-draft_dialog-title`}
			submit={t`data_collection-deployment_editor-draft_dialog-submit`}
			open={Boolean(_pendingChange)}
			onClose={rejectChange}
			onBack={rejectChange}
			onSubmit={acceptChange}
			data-track-event="deployment_draft_pending_dialog_new_draft"
		>
			<Typography>{t`data_collection-deployment_editor-draft_dialog-content`}</Typography>
		</SimpleDialog>
	);
}
