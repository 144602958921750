import React, { useEffect, useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@/styles';

import { useAppContext } from '@/components/AppContext';
import { Alert, Grid, ActionButton } from '@/components/Layout';
import PreviewHelper from './PreviewHelper';
import DummyGridHeader from './DummyGridHeader';
import DummyGridWeb from './DummyGridWeb';
import DummyGridMobile from './DummyGridMobile';
import RenderSurvey from './RenderSurvey';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			margin: 0,
			padding: '0!important',
		},
	},
	marginTop: {
		marginTop: theme.spacing(5),
	},
}));

export default function PreviewIframeContent({
	viewType,
	surveySettings,
	iFrame,
	dismissedHelper,
	anchorEl,
	selectedLanguage,
	setDismissedHelper = () => {},
	setAnchorEl = () => {},
}) {
	const classes = useStyles();
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const { campaign, formType, buttonSettings, buttonSettingsMobile, dataLoaded } =
		surveySettings;
	const [scriptLoaded, setScriptLoaded] = useState(false);

	const contentWindow = iFrame?.current?.contentWindow;
	const surveyContainer = contentWindow?.document;

	const currentButtonHidden = anchorEl?.offsetHeight === 0 && anchorEl?.offsetWidth === 0;

	useEffect(() => {
		surveyContainer.addEventListener('mopinion_shown', () => {
			setDismissedHelper(true);
		});
	}, [surveyContainer]);

	useEffect(() => {
		//resets the popover to make it re-appear in the correct location
		setAnchorEl(null);
		if (
			surveyContainer &&
			buttonSettings &&
			buttonSettingsMobile &&
			scriptLoaded &&
			dataLoaded &&
			formType
		) {
			//we need the timeout to give the button time to reposition and finish its transition
			setTimeout(() => {
				const modalButton = surveyContainer?.querySelector('.btn-open-survey');
				const sliderButton = surveyContainer?.querySelector('.btn-open-slider');
				setAnchorEl(modalButton ?? sliderButton ?? null);
			}, 1000);
		}
	}, [
		surveyContainer,
		buttonSettings,
		buttonSettingsMobile,
		scriptLoaded,
		dataLoaded,
		formType,
	]);

	const NoVisibleButtonAlert = () => {
		return (
			<Snackbar open>
				<Alert
					severity="info"
					variant="filled"
					action={
						<ActionButton
							variant="outlined"
							color="inherit"
							size="small"
							onClick={() => {
								try {
									iFrame.current.contentWindow.srv.openModal();
								} catch (e) {
									enqueueSnackbar(t`data_collection-preview-alert-something_wrong`);
								}
							}}
						>
							{t`data_collection-preview-alert-feeback_button_open`}
						</ActionButton>
					}
				>
					{t`data_collection-preview-alert-feeback_button_message`}
				</Alert>
			</Snackbar>
		);
	};

	if (!contentWindow) {
		return null;
	}

	if (campaign === 'sdk') {
		return (
			<Grid container>
				<Grid
					item
					xs={12}
				>
					<RenderSurvey
						surveySettings={surveySettings}
						contentWindow={contentWindow}
						scriptLoaded={scriptLoaded}
						setScriptLoaded={setScriptLoaded}
						selectedLanguage={selectedLanguage}
					/>
				</Grid>
			</Grid>
		);
	}

	if (campaign === 'email') {
		return (
			<Grid
				container
				className={classes.marginTop}
			>
				<Grid
					item
					xs={1}
				></Grid>
				<Grid
					item
					xs={10}
				>
					<RenderSurvey
						surveySettings={surveySettings}
						scriptLoaded={scriptLoaded}
						contentWindow={contentWindow}
						setScriptLoaded={setScriptLoaded}
						selectedLanguage={selectedLanguage}
					/>
				</Grid>
				<Grid
					item
					xs={1}
				></Grid>
			</Grid>
		);
	}

	return (
		<>
			{viewType === 'desktop' && (
				<>
					<DummyGridHeader persistent={!app.layout.persistentDrawer} />
					<DummyGridWeb
						surveySettings={surveySettings}
						scriptLoaded={scriptLoaded}
						contentWindow={contentWindow}
						setScriptLoaded={setScriptLoaded}
						selectedLanguage={selectedLanguage}
					/>
				</>
			)}
			{viewType === 'mobile' && (
				<DummyGridMobile
					surveySettings={surveySettings}
					scriptLoaded={scriptLoaded}
					contentWindow={contentWindow}
					setScriptLoaded={setScriptLoaded}
					selectedLanguage={selectedLanguage}
				/>
			)}
			{formType !== 'embed' && anchorEl && currentButtonHidden && (
				<NoVisibleButtonAlert />
			)}

			{formType !== 'embed' && anchorEl && !currentButtonHidden && !dismissedHelper && (
				<PreviewHelper
					setDismissedHelper={setDismissedHelper}
					setAnchorEl={setAnchorEl}
					formType={surveySettings.formType}
					buttonSettings={
						viewType === 'mobile'
							? surveySettings.buttonSettingsMobile
							: surveySettings.buttonSettings
					}
					anchorEl={anchorEl}
				/>
			)}
		</>
	);
}
