import set from 'lodash.set';

import { defaultBlocks, formBorderRadius, buttonBorderRadius } from './constants';

import {
	rangeArray,
	rnd,
	removeNestedObjKeys,
	tryParse,
	isObjectWithKeys,
} from '@/utils';

export function getFormType(trigger = {}, modal) {
	if (trigger?.slider?.type && modal) {
		return {
			type: 'slide',
			typeLabel: 'Slide-in',
			position: trigger.slider.position,
		};
	} else if (modal) {
		return {
			type: 'modal',
			typeLabel: 'Modal',
			position: trigger.button.position,
		};
	} else {
		return {
			typeLabel: 'Embedded',
			type: 'embed',
		};
	}
}

export function createSurveyBlockObject({
	typeName,
	system_var,
	defaults = {},
	survey_id,
	id,
	properties = {},
	type = '',
}) {
	const uuid = rnd();

	return {
		datafield: {
			var_type: defaults.data_type_id,
			system_var,
			hidden: 0,
			show_actions: 0,
			survey_id,
		},
		typeName: typeName ? typeName : type,
		id: `new_${uuid}`,
		properties: {
			required: false,
			...properties,
		},
		rules: [],
		tooltip: null,
		uuid,
		...defaults,
	};
}

export function createIntroThanksBlock({ type = 'text', value = '', ...props }) {
	return {
		type,
		value,
		genID: rnd(),
		align: 'center',
		hide_on_init: false,
		...(type === 'img' && {
			url: '',
			width: '100%',
		}),
		...(type === 'link' && {
			description: '',
			hyperlinkhref: '',
			hyperlinktext: '',
			show_as_buttons: false,
			show_as_panels: false,
			use_as: 'link',
		}),
		...props,
	};
}

//Map the used state to an object we can save
export function mapStateToSaveableData(state, surveyId) {
	return {
		surveyId,
		new_survey: JSON.stringify(state.properties),
		new_map: JSON.stringify({ layout: state.map }),
		block_params: JSON.stringify(
			Object.keys(state.blocks).reduce((allBlocks, currentBlockId) => {
				const newKey =
					currentBlockId.indexOf('new') > -1
						? currentBlockId
						: `block_id_${currentBlockId}`;

				//only save if it's not a default block
				if (!defaultBlocks[currentBlockId]) {
					allBlocks[newKey] = state.blocks[currentBlockId];
				}
				return allBlocks;
			}, {})
		),
		new_rules: JSON.stringify(
			(Array.isArray(state.newRules) ? state.newRules : []).map(ruleId => {
				return {
					...state.rules[ruleId],
					rule_id: state.rules[ruleId].id,
				};
			})
		),
		delete_rules: JSON.stringify(state.removedRules),
	};
}

export function formatMediaWidth(width) {
	return Number(String(width).replace('%', '').replace('px', ''));
}

export function getEmbedID(str, type) {
	const regex = {
		youtube:
			/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
		vimeo: /vimeo.*(?:\/|clip_id=)([0-9a-z]*)/i,
	};
	const testedURL = str.match(regex[type]);
	return testedURL ? testedURL[1] : false;
}

//to do keep querystring if exists
export function getQueryParams(str) {
	return str.split('?')[1];
}

export function makeEmbedURL(str, type) {
	const base = {
		youtube: 'https://youtube.com/embed/',
		vimeo: 'https://player.vimeo.com/video/',
	};
	const id = getEmbedID(str, type);

	const urlParams = new URLSearchParams(getQueryParams(str));

	const r = id
		? `${base[type] + id}${urlParams.toString() ? '?' + urlParams.toString() : ''}`
		: str;

	return r;
}

export function getEmbedQueryValue(str, type) {
	const urlParams = new URLSearchParams(getQueryParams(str));
	return urlParams.get(type);
}

export function updateEmbedQueryValue(str, type, value, updateType = 'set') {
	const urlParams = new URLSearchParams(getQueryParams(str));
	urlParams[updateType](type, value);
	return `${str.split('?')[0]}?${urlParams.toString()}`;
}

export function logicRuleBase({
	isGlobal,
	survey_id,
	useTriggerElement,
	uuid = '',
	conversational,
	always,
	target = '',
}) {
	return isGlobal
		? {
				name: 'Form logic',
				level: 0,
				trigger: 'ready',
				condition: {},
				action: {
					action: 'prefill',
					targets: [],
					source: 'cookie',
					key: '',
					value: '',
					values: [],
				},
				id: 0,
				survey_id,
				uuid,
		  }
		: {
				name: 'Question logic',
				level: 1,
				trigger: 'change',
				condition: {
					always,
					operator: '===',
					concat: '||',
					...(useTriggerElement ? { trigger_element: '', values: [] } : { elements: [] }),
				},
				action: {
					action: conversational ? 'jump_to' : 'show',
					//used for old skool logic, can hide/show multiple blocks
					targets: [],
					//used for conversational -> jump_to logic
					target,
				},
				id: 0,
				survey_id,
				uuid,
		  };
}

//Why oh why... :(
//Todo: we need to fix this mess of a link block to be more standardized...
export function linkBlockToHtml({
	hyperlinkhref = '',
	hyperlinktext = '',
	linktype = '',
	use_as = '',
	linktype_function = '',
}) {
	const useAsHref =
		use_as && use_as === 'link'
			? hyperlinkhref
			: use_as && use_as !== 'link'
			? use_as
			: linktype && linktype === 'link'
			? hyperlinkhref
			: linktype && linktype === 'function'
			? linktype_function
			: '';

	return hyperlinktext.replace('/*', `<a href="${useAsHref}">`).replace('*/', '</a>');
}

export function parseLinkHtml(html = '') {
	const div = document.createElement('div');
	div.innerHTML = html;

	const anchorTag = div.querySelector('a');
	const url = anchorTag ? anchorTag.getAttribute('href') : '';
	const plainText = div.textContent.trim();
	const anchorText = anchorTag ? anchorTag.textContent : '';
	const textWithLink = anchorText
		? plainText.replace(anchorText, `/*${anchorText}*/`)
		: plainText;

	return {
		url,
		plainText,
		anchorText,
		textWithLink,
	};
}

export function parseLinkString(linkStr = '') {
	const linkText = linkStr.match(/\/\*(.*)\*\//gi)
		? linkStr
				.match(/\/\*(.*)\*\//gi)[0]
				.replace('/*', '')
				.replace('*/', '')
		: '';

	const linkTextArray = linkStr.split(/[\/\*\*\/]+/).filter(Boolean);

	return {
		linkText,
		linkTextArray,
	};
}
//end link block mess...

//Translation stuff
export function translationBase({
	countryKey = '',
	country = '',
	countrycode = '',
	map = [],
	blocks = {},
	properties = {},
}) {
	const blocksToTranslate = map.reduce((currentBlockId, allBlocks, index) => {
		allBlocks[currentBlockId] = {};
		return allBlocks;
	}, {});

	return {
		[countryKey]: {
			blocks: blocksToTranslate,
			text: {
				errors: {},
			},
			properties: {
				advanced: {},
			},
			country,
			countrycode,
		},
	};
}
//end stranslation stuff

//design mapping
export function makeSaveableDesign(design = {}, currentDesignState) {
	const {
		name = 'Custom Theme',
		id = 0,
		logo = '',
		theme = 1,
		custom,
		type = 'web',
		avatar,
	} = design;

	const customStr = typeof custom === 'object' ? JSON.stringify(custom) : custom;

	return {
		name,
		id: id,
		logo,
		theme,
		custom: currentDesignState
			? JSON.stringify(mapDesignStateToCustomCss(currentDesignState))
			: customStr,
		type,
		email_widgets: currentDesignState?.widget
			? JSON.stringify(currentDesignState?.widget)
			: {},
		avatar,
	};
}

export function kebabToCamel(str) {
	return str.replace(/([-_][a-z])/g, group =>
		group.toUpperCase().replace('-', '').replace('_', '')
	);
}

export function camelToKebab(str) {
	return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

export function mapDesignStateToCustomCss(designState = {}) {
	if (Object.keys(designState).length === 0) return {};

	const {
		widget,
		otherCss = {},
		advanced,
		reactDesignState,
		useReact,
		...react
	} = designState;

	return {
		...(reactDesignState && { reactDesignState: reactDesignState }),
		...(Object.keys(react).length > 0 && { reactDesignState: react }),
		...otherCss,
		...(advanced && { advanced }),
		useReact: true,
	};
}

export function mapCustomCssToDesignState(design = {}) {
	if (!design) return;

	const { email_widgets, custom, type } = design;

	const parsedCss = tryParse(custom);
	const widget = tryParse(email_widgets);

	if (!parsedCss || Object.keys(parsedCss).length === 0) return;

	//Grab the reactDesignState (if exists)
	//Grab useReact param (if true the form was last edited in Raspberry so we dont have to map the otherCss if false the form was last edited in classic so we do have to map otherCss)
	const { reactDesignState = {}, useReact, advanced, ...otherCss } = parsedCss;

	//If useReact is true the design was last edited in RB so simply return the reactDesignState
	if (useReact) return { ...reactDesignState, widget, advanced };

	//If the design type is sdk we have to map the old design to a reactdesign
	if (type === 'sdk') {
		let reactDesign = {};
		if (otherCss.headerBgColor) {
			set(reactDesign, 'header.backgroundColor.inactive', otherCss.headerBgColor);
		}
		if (otherCss.headerTextColor) {
			set(reactDesign, 'header.color.inactive', otherCss.headerTextColor);
		}
		if (otherCss.actionButtonBgColor) {
			set(reactDesign, 'header.backgroundColor.inactive', otherCss.actionButtonBgColor);
		}
		if (otherCss.groupsAsCards) {
			set(reactDesign, 'all.groupsAsCards', otherCss.groupsAsCards);
		}
		if (otherCss.selectedControlColor) {
			set(reactDesign, 'all.backgroundColor.selected', otherCss.selectedControlColor);
		}
		if (otherCss.requiredMarkColor) {
			set(reactDesign, 'title.requiredMark.color.inactive', otherCss.requiredMarkColor);
		}
		if (otherCss.errorColor) {
			set(reactDesign, 'error.color.inactive', otherCss.errorColor);
		}

		return reactDesign;
	}

	//Here we iterate over the otherCss, all remaining legacy css statements to hydrate the reactDesignState with
	const parsedDesignCustom = Object.keys(otherCss).reduce(
		(designObject, cssKey, index) => {
			const mapProps = (mappedKey, elementState = 'inactive') => {
				Object.keys(otherCss[cssKey]).forEach(cssProp => {
					const cssValue = otherCss[cssKey][cssProp];

					switch (cssProp) {
						case 'outline':
							if (mappedKey === 'wcag') {
								const objCss = cssValue.split(' ');
								set(
									designObject,
									`${mappedKey}.${kebabToCamel(cssProp)}`,
									objCss[objCss.length - 1]
								);
							} else {
								set(designObject, `${mappedKey}.${kebabToCamel(cssProp)}`, cssValue);
							}
							break;
						case 'color':
						case 'background-color':
						case 'background':
						case 'border-color':
							set(
								designObject,
								`${mappedKey}.${kebabToCamel(
									cssProp !== 'background' ? cssProp : 'backgroundColor'
								)}.${elementState}`,
								cssValue
							);
							break;

						default:
							set(designObject, `${mappedKey}.${kebabToCamel(cssProp)}`, cssValue);
					}
				});
			};

			switch (cssKey) {
				case 'body':
					mapProps('body');
					break;

				case '#surveyTitle':
				case '#surveyBody':
				case '#surveyHead':
					mapProps('header');
					break;

				case '#surveyBody #closeModalBtn:before':
				case '#surveyBody #closeModalBtn:after':
					mapProps('header.close');
					break;

				case '.btn':
					mapProps('navButton');
					break;

				case '.btn:hover':
					mapProps('navButton', 'hover');
					break;

				case '.btn.btn-prev':
					mapProps('prevButton');
					break;

				case '.btn.btn-prev:hover':
					mapProps('prevButton', 'hover');
					break;

				case '.btn.btn-primary':
				case '.btn.btn-next':
					mapProps('nextButton');
					break;

				case '.btn.btn-primary:hover':
				case '.btn.btn-next:hover':
					mapProps('nextButton', 'hover');
					break;

				case '.link_block a':
					mapProps('link');
					break;

				case '.link_block a:hover':
					mapProps('link', 'hover');
					break;

				case '.link_block a.btn':
					mapProps('linkButton');
					break;

				case '.link_block a.btn:hover':
					mapProps('linkButton', 'hover');
					break;

				case 'input[type="text"]':
				case 'textarea':
					mapProps('input');
					break;

				case 'input[type="text"]:focus':
				case 'textarea:focus':
					mapProps('input', 'focus');
					break;

				case 'input[type="checkbox"] + label':
				case 'input[type="radio"] + label':
					mapProps('radioCheck');
					break;

				// case 'input[type="checkbox"] + label':
				// case 'input[type="radio"] + label':
				//   mapProps('radioCheck', 'hover');
				//   break;

				case 'input[type="checkbox"]:checked + label':
				case 'input[type="radio"]:checked + label':
					mapProps('radioCheck', 'selected');
					break;

				case '.category-wrapper.button input[type="radio"] + label':
				case '.checkbox-wrapper.button input[type="radio"] + label':
				case '.gcr-wrapper.button input[type="radio"] + label':
				case '.radio-wrapper.button input[type="radio"] + label':
					mapProps('button');
					break;

				case '.category-wrapper.button input[type="radio"] + label:hover':
				case '.checkbox-wrapper.button input[type="radio"] + label:hover':
				case '.gcr-wrapper.button input[type="radio"] + label:hover':
				case '.radio-wrapper.button input[type="radio"] + label:hover':

				case '.category-wrapper.button input[type="radio"] + label:focus':
				case '.checkbox-wrapper.button input[type="radio"] + label:focus':
				case '.gcr-wrapper.button input[type="radio"] + label:focus':
				case '.radio-wrapper.button input[type="radio"] + label:focus':

				case '.category-wrapper.button input[type="radio"] + label:active':
				case '.checkbox-wrapper.button input[type="radio"] + label:active':
				case '.gcr-wrapper.button input[type="radio"] + label:active':
				case '.radio-wrapper.button input[type="radio"] + label:active':
					mapProps('button', 'hover');
					break;

				case '.category-wrapper.button input[type="radio"]:checked + label':
				case '.checkbox-wrapper.button input[type="radio"]:checked + label':
				case '.gcr-wrapper.button input[type="radio"]:checked + label':
				case '.radio-wrapper.button input[type="radio"]:checked + label':
					mapProps('button', 'selected');
					break;

				case 'legend':
					mapProps('title');
					break;

				case '.section-title':
					mapProps('sectionBreak.title');
					break;

				case '.section-description':
					mapProps('sectionBreak.body');
					break;

				case '.nice-select':
				case '.nice-select span':
					mapProps('select');
					break;

				case '.nice-select .option.focus':
				case '.nice-select .option:hover':
				case '.option.selected.focus':
					mapProps('select.option', 'selected');
					break;

				case '.ui-stars-star:before':
					mapProps('starRating');
					break;

				case '.ui-stars-star-hover:after':
					mapProps('starRating', 'hover');
					break;

				case '.ui-stars-star-on:after':
					mapProps('starRating', 'selected');
					break;

				case '.rating-group label':
					mapProps('numericRating');
					break;

				case '.rating-group label:hover':
					mapProps('numericRating', 'hover');
					break;

				case '.rating-group input[type="radio"]:checked + label':
					mapProps('numericRating', 'selected');
					break;

				case '.thumbs-wrapper.button input[type="radio"] + label.label_thumbs_positive i':
					mapProps('thumbsUp');
					break;

				case '.thumbs-wrapper.button input[type="radio"] + label.label_thumbs_positive:hover i':
					mapProps('thumbsUp', 'hover');
					break;

				case '.thumbs-wrapper.button input[type="radio"]:checked + label.label_thumbs_positive i':
					mapProps('thumbsUp', 'selected');
					break;

				case '.thumbs-wrapper.button input[type="radio"] + label.label_thumbs_negative i':
					mapProps('thumbsDown');
					break;

				case '.thumbs-wrapper.button input[type="radio"] + label.label_thumbs_negative:hover i':
					mapProps('thumbsDown', 'hover');
					break;

				case '.thumbs-wrapper.button input[type="radio"]:checked + label.label_thumbs_negative i':
					mapProps('thumbsDown', 'selected');
					break;

				case '#surveyWindowWrap .btn-open-slider':
				case '.btn-open-survey':
					mapProps('feedbackButton');
					break;
				case '#surveyWindowWrap .btn-open-slider:hover':
				case '.btn-open-survey:hover':
					mapProps('feedbackButton', 'hover');
					break;

				case '.form-actions':
					mapProps('footer');
					break;

				case '.alert-danger':
					mapProps('error');
					break;

				case '#surveyBody .wcag a:focus':
					mapProps('wcag');
					break;

				case '.custom-section':
					mapProps('customPage');
					break;

				case '.custom-section a':
					mapProps('customPage.link');
					break;
				case '.custom-section a:hover':
					mapProps('customPage.link', 'hover');
					break;

				case '.custom-section a.btn':
					mapProps('customPage.linkButton');
					break;
				case '.custom-section a.btn:hover':
					mapProps('customPage.linkButton', 'hover');
					break;

				case '.custom-section a.panel':
					mapProps('customPage.panel');
					break;
				case '.custom-section a.panel:hover':
					mapProps('customPage.panel', 'hover');
					break;

				case 'advanced':
					designObject['advanced'] = otherCss['advanced'];
					break;
				default:
					break;
			}

			return designObject;
		},
		{}
	);

	return Object.assign(
		{},
		reactDesignState,
		parsedDesignCustom,
		{ otherCss },
		{ advanced }
	);
}
//end design mapping

export function makeTemplateObjectFromState(state, language = 1) {
	const typeCounter = {};

	const makeTranslationObject = (translation = {}) => {
		const keepAsIs = ['languageTest', 'toggleOptions', 'useToggle', 'useTranslation'];

		return translation
			? Object.keys(translation).reduce((allLanguages, currentLanguageKey, index) => {
					if (keepAsIs.indexOf(currentLanguageKey) === -1) {
						allLanguages[currentLanguageKey] = {
							...translation[currentLanguageKey],
							...(translation[currentLanguageKey]?.blocks && {
								blocks: state.map.reduce(
									(allTranslationBlocks, currentBlockId, index) => {
										const currentTranslationBlockValue =
											translation[currentLanguageKey].blocks[currentBlockId] ?? {};
										allTranslationBlocks[index] = currentTranslationBlockValue;
										return allTranslationBlocks;
									},
									{}
								),
							}),
						};
					} else {
						allLanguages[currentLanguageKey] = translation[currentLanguageKey];
					}

					return allLanguages;
			  }, {})
			: {};
	};

	return {
		template: JSON.stringify([
			state.map.reduce((allBlocks, currentBlockId, index) => {
				//first we need to deep clone the object else we will create unwanted mutation in the state object
				const block = removeNestedObjKeys(
					JSON.parse(JSON.stringify(state.blocks[currentBlockId] ?? {})),
					'data_field'
				);
				delete block.id;
				delete block.data_field_group;
				delete block.rules;
				delete block.uuid;
				block.type = block.typeName;
				block.system_var = typeCounter[block.typeName]
					? `${block.typeName?.toUpperCase()}${++typeCounter[block.typeName]}`
					: `${block.typeName?.toUpperCase()}${(typeCounter[block.typeName] = 1)}`;

				block.tooltip = block.tooltip ? block.tooltip : '';
				allBlocks[`q_${index}_${block.typeName}`] = block;
				return allBlocks;
			}, {}),
		]),
		text: JSON.stringify({ ...state.properties.text }),
		trigger: JSON.stringify({ ...state.properties.trigger }),
		advanced: JSON.stringify({ ...state.properties.advanced }),
		post_submit: JSON.stringify({ ...state.properties.post }),
		modal: state.properties.modal,
		translation: JSON.stringify({
			...makeTranslationObject(state.properties.translation),
		}),
		survey_format: state.properties.conversational ? 'conversational' : 'form',
	};
}

export function createLandingsPageURL(domain, properties = {}) {
	const url = new URL(
		`https://${domain}/survey/public/take-survey/${properties.survey_key}`
	);

	if (properties.advanced?.landingspageUrl) {
		url.searchParams.set('mail_url', properties.advanced.landingspageUrl);
	}
	return url.href;
}

export function createShareURL(shareTo, surveyURL, surveyTitle) {
	switch (shareTo) {
		case 'email':
			return `mailto:?subject=${surveyTitle}&body=We would like your opinion, please fill in our feedback form: ${surveyURL}`;
		case 'facebook':
			return `https://www.facebook.com/sharer/sharer.php?u=${surveyURL}=${surveyTitle}`;
		case 'twitter':
			return `https://twitter.com/intent/tweet?source=${surveyURL}&text=${surveyTitle}:${surveyTitle}`;
		case 'linkedin':
			return `https://www.linkedin.com/shareArticle?url=${surveyURL}&title=${surveyTitle}`;
		default:
			return '';
	}
}

export function splitWidget(block, design) {
	// does the email widget need a seperate head/body snippet?
	if (!block || Object.keys(block).length === 0 || !design) {
		return false;
	}

	function findHoverStyle(block) {
		//custom styles used in email widget head
		switch (block) {
			case 'nps':
			case 'numeric':
			case 'bar':
				return design?.numericRating?.color?.hover ||
					design?.numericRating?.borderColor?.hover ||
					design?.numericRating?.backgroundColor?.hover ||
					design?.widget?.numericRating?.color?.hover ||
					design?.widget?.numericRating?.borderColor?.hover ||
					design?.widget?.numericRating?.backgroundColor?.hover
					? true
					: false;
			case 'stars':
				return design?.starRating?.color ||
					design?.starRating?.borderColor ||
					design?.starRating?.backgroundColor ||
					design?.widget?.starRating?.color ||
					design?.widget?.starRating?.borderColor ||
					design?.widget?.starRating?.backgroundColor
					? true
					: false;
			case 'category':
			case 'gcr':
				return design?.button?.color?.hover ||
					design?.button?.borderColor?.hover ||
					design?.button?.backgroundColor?.hover ||
					design?.widget?.button?.color?.hover ||
					design?.widget?.button?.borderColor?.hover ||
					design?.widget?.button?.backgroundColor?.hover
					? true
					: false;
			case 'thumbs':
				return design?.thumbsUp?.color ||
					design?.thumbsDown?.color ||
					design?.widget?.thumbsUp?.color ||
					design?.widget?.thumbsDown?.color
					? true
					: false;
			case 'feedback_button':
				return design?.feedbackButton?.backgroundColor?.hover ||
					design?.feedbackButton?.color?.hover
					? true
					: false;
			default:
				return false;
		}
	}

	//Do we need to split the widget HTML into css & html?
	const type = block.typeName === 'rating' ? block.properties.type : block.typeName;

	//elements that always need extra css styles in email widget
	const useStandardCss = ['ces', 'emoji'].indexOf(type) > -1;
	const hasTooltip = design?.widget?.tooltip?.value ? true : false;
	const hasCaptions = block?.properties?.showCaptions ? true : false;
	const useExtraCSS = findHoverStyle(type);

	return useStandardCss || hasTooltip || hasCaptions || useExtraCSS;
}

export function generateEmailTemplateString(
	state,
	design = {},
	domain,
	block,
	advanced = {},
	feedbackButton = {},
	feedbackButtonText = 'Feedback'
) {
	if (!block || Object.keys(design).length === 0) {
		return false;
	}

	const whichDesign =
		(block?.uuid || block.id) && design?.widget?.syncStyles
			? design
			: design?.widget ?? {};

	//for now are always fetched from the widget design
	//captions are always inherited from the question in the feedback form
	const whichBlockProps = design?.widget ?? {};

	const assetsUrl = 'https://app.mopinion.com';
	const iconApiUrl = `${document.location.origin}/survey/public/email-icon`;

	const pageUrl = new URL(createLandingsPageURL(domain, state.properties));

	function getTypeByBlockType(block) {
		switch (block) {
			case 'nps':
			case 'numeric':
			case 'bar':
				return 'numericRating';
			case 'stars':
				return 'starRating';
			case 'category':
			case 'gcr':
				return 'button';
			default:
				return '';
		}
	}

	const iconSet = design?.widget?.withIcons?.widgetElements
		? design.widget.withIcons.widgetElements
				.filter(element => element.icon)
				.map(element => element.icon)
		: [];

	function getCustomStyle(type, elementType, fallback = '#222222') {
		if (!design.widget) {
			return fallback;
		}

		switch (elementType) {
			case 'widgetAlign':
				return (design?.widget[type]?.align || whichDesign[type]?.align) ?? fallback;
			case 'color':
				return (
					(design?.widget[type]?.color?.inactive || whichDesign[type]?.color?.inactive) ??
					fallback
				);
			case 'colorHover':
				return (
					(design?.widget[type]?.color?.hover || whichDesign[type]?.color?.hover) ??
					fallback
				);
			case 'fontSize':
				return (
					(design?.widget[type]?.fontSize || whichDesign[type]?.fontSize) ?? fallback
				);
			case 'fontWeight':
				return (
					(design?.widget[type]?.fontWeight || whichDesign[type]?.fontWeight) ?? fallback
				);
			case 'fontStyle':
				return (
					(design?.widget[type]?.fontStyle || whichDesign[type]?.fontStyle) ?? fallback
				);
			case 'textAlign':
				return (
					(design?.widget[type]?.textAlign || whichDesign[type]?.textAlign) ?? fallback
				);
			case 'border':
				return (
					(design?.widget[type]?.borderColor?.inactive ||
						whichDesign[type]?.borderColor?.inactive) ??
					fallback
				);
			case 'borderHover':
				return (
					(design?.widget[type]?.borderColor?.hover ||
						whichDesign[type]?.borderColor?.hover) ??
					fallback
				);
			case 'background':
				return (
					(design?.widget[type]?.backgroundColor?.inactive ||
						whichDesign[type]?.backgroundColor?.inactive) ??
					fallback
				);
			case 'backgroundHover':
				return (
					(design?.widget[type]?.backgroundColor?.hover ||
						whichDesign[type]?.backgroundColor?.hover) ??
					fallback
				);
			default:
				return '';
		}
	}

	function addLegend(tdLength = 2) {
		if (whichBlockProps?.legend?.legendLow || whichBlockProps?.legend?.legendHigh) {
			const even = tdLength % 2 === 0;
			const colspan = Math.floor(tdLength / 2);

			return `<tr width="100%">
                  <td colspan=${colspan} style="text-align:left;color:#727272;">
                    ${whichBlockProps?.legend?.legendLow ?? ''}          
                  </td>
                  ${!even ? '<td></td>' : ''}
                  <td colspan=${colspan} style="text-align:right;color:#727272;">
                    ${whichBlockProps?.legend?.legendHigh ?? ''}          
                  </td>
                </tr>`;
		}
		return '';
	}

	function addTooltip(tooltip, offset = '0px') {
		if (whichBlockProps?.tooltip?.value) {
			return `<table style="width:100%;" class="tooltip-table">
                  <tbody>
                    <tr>
                      <td style="text-align:right;">
                        <div class="tooltip-wrapper" style="mso-hide:all;max-height:0;max-width:100%;overflow:visible!important;margin-bottom:${offset};">
                          <div style="position:relative;Margin-top:0px;display:inline-block;width:100%;max-width:350px;">
                            <div style="z-index:10;position:absolute;overflow:visible!important;right:0">
                              <a href="#tooltip" width="20" style="font-weight:bold;height:20px;width:20px;display:inline-block;text-align:center;background-color:#333;color:#fff;border-radius:10px;text-decoration:none;max-height:none!important;line-height:1.5;">?</a>
                              <div style="max-height:0px;overflow:hidden;min-width:50px;">
                                <span style="display:block;padding:5px 10px;font-size:14px;line-height:normal;font-family:Arial;border-radius:3px;background-color:#222;color:#ffffff;opacity:.8;text-align:left;">${tooltip}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>`;
		}
		return '';
	}

	function addIcon(icon = 'cog', color = 'ffffff', alt = '', height = '16px') {
		const faIcon = icon.split('fa-');
		const plainIcon = faIcon[faIcon.length - 1];
		const strippedHeight = height.replace('px', '');

		const generatedIcon = `<img height="${strippedHeight}" style="height:${height};vertical-align:middle;padding-right:5px" alt="${alt}" src="${iconApiUrl}?icon=${plainIcon}&color=${color.replace(
			'#',
			''
		)}" />`;

		return generatedIcon;
	}

	function calcTableWidth(block, type) {
		//For emoji/stars/thumbs we need to set a max table width to correctly align the tooltip  & legend
		switch (type) {
			case 'stars':
				return block.properties.scale * 33 + 'px';
			case 'emoji':
				return '400px';
			case 'thumbs':
				return '250px';
			default:
				return '100%';
		}
	}

	//Map layout and find possible website data elements containing url_parameters -> if found add to landingpage url
	state.map.forEach(id => {
		const block = getBlock(state.blocks, id);

		if (block.typeName === 'website_data') {
			Object.values(block.properties?.elements ?? {}).forEach(websiteDataElement => {
				if (websiteDataElement.type === 'url_parameter') {
					pageUrl.searchParams.set(websiteDataElement.value, 'ENTER_VALUE');
				}
			});
		}
	});

	const type = block.typeName === 'rating' ? block.properties.type : block.typeName;
	const blockParam = `${block.typeName}_${String(block.id).replace('new_', '')}`;

	let snippet = '';
	let generalCss = '';

	const tooltip = whichBlockProps?.tooltip?.value ?? '';

	const tablePadding = '5px 0px 30px 0px';
	const titleWrapper = '5px 0px 0px 0px';
	//const titlePadding = whichBlockProps?.tooltip?.value ? '10px 0px 10px 0px' : '10px 0px 0px 0px';
	const titlePadding =
		['category', 'gcr'].indexOf(type) > -1 ? '10px' : '10px 0px 10px 0px';

	const maxTableWidth =
		['stars', 'emoji', 'thumbs'].indexOf(type) > -1
			? calcTableWidth(block, type)
			: '100%';
	const tooltipOffset = ['emoji'].indexOf(type) > -1 ? '20px' : '10px';

	if (type === 'feedback_button') {
		const inactiveColor = feedbackButton?.colorInactive ?? '#fff';
		const inactiveBackground = feedbackButton?.backgroundInactive ?? '#bbb';
		const hoverColor = feedbackButton?.colorHover ?? '#fff';
		const hoverBackground = feedbackButton?.backgroundHover ?? '#aaa';

		generalCss = `@media screen and (max-width:9999px){
                  .m-feedback-hover a:hover{
                    color:${hoverColor}!important;
                    background-color:${hoverBackground}!important;
                    border-color:${hoverBackground}!important;
                  }
                }`;

		snippet = `<table width="100%">
      <tbody>
        <tr>
          <td align="center" style="padding: 20px 20px 20px 20px;">
            <table>
              <tr>
                <td width="200" class="m-feedback-hover" height="50" style="text-align:center;">
                  <a href="${pageUrl.href}" target="_blank" style="font-family:inherit;width:100%;font-size:16px;height:50px;line-height:50px;border-radius:4px;color:#fff;color:${inactiveColor};background-color:${inactiveBackground};padding:0px 20px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;">
                    ${feedbackButtonText}
                  </a>
                </td>
              </tr>
            </table>
        </tr>
      </tbody>
    </table>`;
	} else {
		const hoverColor = getCustomStyle(getTypeByBlockType(type), 'colorHover', '#222222');
		const hoverBackground = getCustomStyle(
			getTypeByBlockType(type),
			'backgroundHover',
			'#ffffff'
		);
		const hoverBorder = getCustomStyle(
			getTypeByBlockType(type),
			'borderHover',
			'#222222'
		);

		const baseColor = getCustomStyle(getTypeByBlockType(type), 'color', '#222222');
		const baseBackground = getCustomStyle(
			getTypeByBlockType(type),
			'background',
			'#ffffff'
		);
		const baseBorder = getCustomStyle(getTypeByBlockType(type), 'border', '#222222');

		generalCss = `@media screen and (max-width:9999px){
                  .tooltip-wrapper{
                    margin-top:-26px;
                  }
                  .tooltip-wrapper a:hover + div{
                    max-height:none!important;
                  }
                  .label-wrapper .ces-score:hover + div{
                    max-height:none!important;
                  }
                  .label-wrapper .label-score:hover + div{
                    max-height:none!important;
                  }
                  .m-score-hover{
                    transition: 0.3s !important;
                  }
                  .m-score-hover span a{
                    transition: 0.3s !important;
                  }
                  .m-score-hover:hover{
                    background-color:${
											hoverBackground !== '#ffffff' ? hoverBackground : baseBackground
										}!important;
                    border-color:${
											hoverBorder !== '#222222' ? hoverBorder : baseBorder
										}!important;
                    color:${hoverColor !== '#222222' ? hoverColor : baseColor}!important;
                  }
                  .m-score-hover:hover a{
                    color:${hoverColor !== '#222222' ? hoverColor : baseColor}!important;
                  }
                  ${(() => {
										switch (type) {
											case 'ces':
												return `
                          .ces-1 span.ces-score a:hover{
                            color:#e51c23!important;
                            border-bottom:4px solid #e51c23!important;
                          }
                          .ces-2 span.ces-score a:hover{
                            color:#ff5722!important;
                            border-bottom:4px solid #ff5722!important;
                          }
                          .ces-3 span.ces-score a:hover{
                            color:#FFC107!important;
                            border-bottom:4px solid #FFC107!important;
                          }
                          .ces-4 span.ces-score a:hover{
                            color:#259b24!important;
                            border-bottom:4px solid #259b24!important;
                          }
                          .ces-5 span.ces-score a:hover{
                            color:#259b24!important;
                            border-bottom:4px solid #259b24!important;
                          }
                      `;
											case 'stars':
												return `
                          .m-star .star-hover{
                            display:none;
                          }
                          .m-star:hover .star-default{
                            display:none;
                          }
                          .m-star:hover .star-hover{
                            display:block;
                          }
                        `;
											case 'emoji':
												return `
                          .m-emoji .emoji-hover{
                            display:none;
                          }
                          .m-emoji:hover .emoji-default{
                            display:none;
                          }
                          .m-emoji:hover .emoji-hover{
                            display:block;
                          }
                        `;
											case 'thumbs':
												return `
                          .m-thumbs .thumbs-hover{
                            display:none;
                          }
                          .m-thumbs:hover .thumbs-default{
                            display:none;
                          }
                          .m-thumbs:hover .thumbs-hover{
                            display:block;
                          }
                        `;
											default:
												return '';
										}
									})()}  
                }
                `;

		const titleColor = getCustomStyle('title', 'color', '#222222');
		const titleSize = getCustomStyle('title', 'fontSize', '16px');
		const titleWeight = getCustomStyle('title', 'fontWeight', 'normal');
		const titleStyle = getCustomStyle('title', 'fontStyle', 'normal');
		const titleAlign = getCustomStyle('title', 'textAlign', 'left');
		const widgetAlign = getCustomStyle('widget', 'widgetAlign', 'left');

		snippet = `<table width="100%" id="m-snippet" style="max-width:${maxTableWidth};${
			widgetAlign === 'center'
				? 'margin:auto;'
				: widgetAlign === 'right'
				? 'margin-right:0;margin-left:auto;'
				: 'margin-left:0;margin-right:auto;'
		}">
      <tbody>
        <tr>
          <td style="padding:${titleWrapper}"> 
            <table style="width:100%;border-collapse:collapse;border-spacing:0px;">
              <tr>
                <td style="padding:${titlePadding}; text-align:${
			['stars', 'emoji', 'thumbs'].indexOf(type) > -1 ? widgetAlign : titleAlign
		}; color:${titleColor}; font-size:${titleSize}; font-weight:${titleWeight}; font-style:${titleStyle};">
                  ${block.title}
                </td>
              </tr>
              <!--[if !mso]><!-->
                ${
									tooltip &&
									`<tr>
                    <td style="text-align:right;">
                      ${addTooltip(tooltip, tooltipOffset)}
                    </td>
                  </tr>`
								}
              <!--<![endif]-->
            </table>
          </td>
        </tr>
        <tr>
        ${(() => {
					switch (type) {
						case 'thumbs':
							let posUrl = new URL(pageUrl);
							posUrl.searchParams.set(blockParam, 'positive');

							let negUrl = new URL(pageUrl);
							negUrl.searchParams.set(blockParam, 'negative');

							const thumbsDownColor = getCustomStyle('thumbsDown', 'color', '#f28d91');
							const thumbsUpColor = getCustomStyle('thumbsUp', 'color', '#92cd91');
							const thumbsDownColorHover = getCustomStyle(
								'thumbsDown',
								'colorHover',
								'#f28d91'
							);
							const thumbsUpColorHover = getCustomStyle(
								'thumbsUp',
								'colorHover',
								'#92cd91'
							);

							return `
              <td align=${widgetAlign} style="padding:${tablePadding}">
                ${
									block?.properties?.showCaptions
										? `<table style="border-collapse:collapse;border-spacing:0px;">
                    <tr>
                      <td class="m-thumbs" width="70" height="40" style="border:none;outline:none;padding:0;vertical-align:top;">
                        <div class="label-wrapper" style="mso-hide:all;max-height:0;max-width:100%;overflow:visible!important;margin-bottom:0;">
                          <div style="position:relative;Margin-top:0px;display:inline-block;width:100%;">
                            <div style="z-index:2;position:relative;overflow:visible!important;right:0">
                              
                              <span class="label-score" style="display:block;">
                                <a target="_blank" ${
																	!advanced.disableLinks && `href=${posUrl.href}`
																} style="cursor:pointer;width:40px;height:40px;display:block;text-decoration:none;outline:none;">
                                  ${
																		design?.thumbsUp?.color?.inactive ||
																		design?.widget?.thumbsUp?.color?.inactive
																			? `<span class="thumbs-default">${addIcon(
																					iconSet[0] ?? 'thumbs-up',
																					thumbsUpColor,
																					'thumbs-up',
																					'35px'
																			  )}</span>
                                  <span class="thumbs-hover">${addIcon(
																		iconSet[0] ?? 'thumbs-up',
																		thumbsUpColorHover,
																		'thumbs-up',
																		'35px'
																	)}</span>`
																			: `<span>${addIcon(
																					iconSet[0] ?? 'thumbs-up',
																					thumbsUpColor,
																					'thumbs-up',
																					'35px'
																			  )}</span>`
																	}
                                </a>
                              </span>
                              <!--[if !mso]><!-->
                                <div style="max-height:0px;overflow:hidden;min-width:50px;position:absolute;top:0;margin-top:40px;">
                                  <span style="display:block;padding:5px 10px;font-size:14px;line-height:normal;font-family:Arial;border-radius:3px;background-color:#222;color:#ffffff;opacity:.8;text-align:left;">${
																		block.properties.elements[1].label
																	}</span>
                                </div>
                              <!--<![endif]-->
                            </div>
                          </div>
                        </div>
                      </td>
                      <td  class="m-thumbs" width="70" height="40" style="border:none;outline:none;padding:0;vertical-align:top;">
                        <div class="label-wrapper" style="mso-hide:all;max-height:0;max-width:100%;overflow:visible!important;margin-bottom:0;">
                          <div style="position:relative;Margin-top:0px;display:inline-block;width:100%;">
                            <div style="z-index:2;position:relative;overflow:visible!important;right:0">
                              
                              <span class="label-score" style="display:block;">
                                <a target="_blank" ${
																	!advanced.disableLinks && `href=${negUrl.href}`
																} style="cursor:pointer;width:40px;height:40px;display:block;text-decoration:none;outline:none;">
                                  ${
																		design?.thumbsUp?.color?.inactive ||
																		design?.widget?.thumbsUp?.color?.inactive
																			? `<span class="thumbs-default">${addIcon(
																					iconSet[1] ?? 'thumbs-down',
																					thumbsDownColor,
																					'thumbs-down',
																					'35px'
																			  )}</span>
                                  <span class="thumbs-hover">${addIcon(
																		iconSet[1] ?? 'thumbs-down',
																		thumbsDownColorHover,
																		'thumbs-down',
																		'35px'
																	)}</span>`
																			: `<span>${addIcon(
																					iconSet[1] ?? 'thumbs-down',
																					thumbsDownColor,
																					'thumbs-down',
																					'35px'
																			  )}</span>`
																	}
                                </a>
                              </span>
                              <!--[if !mso]><!-->
                                <div style="max-height:0px;overflow:hidden;min-width:50px;position:absolute;top:0;margin-top:40px;">
                                  <span style="display:block;padding:5px 10px;font-size:14px;line-height:normal;font-family:Arial;border-radius:3px;background-color:#222;color:#ffffff;opacity:.8;text-align:left;">${
																		block.properties.elements[2].label
																	}</span>
                                </div>
                              <!--<![endif]-->
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>`
										: `<table style="border-collapse:collapse;border-spacing:0px;">
                    <tr>
                      <td class="m-thumbs" width="70" height="40" style="border:none;outline:none;padding:0;" >
                        <a target="_blank" ${
													!advanced.disableLinks && `href=${posUrl.href}`
												} style="cursor:pointer;width:40px;height:40px;display:block;text-decoration:none;outline:none;">
                          ${
														design?.thumbsUp?.color?.inactive ||
														design?.widget?.thumbsUp?.color?.inactive
															? `<span class="thumbs-default">${addIcon(
																	iconSet[0] ?? 'thumbs-up',
																	thumbsUpColor,
																	'thumbs-up',
																	'35px'
															  )}</span>
                           <span class="thumbs-hover">${addIcon(
															iconSet[0] ?? 'thumbs-up',
															thumbsUpColorHover,
															'thumbs-up',
															'35px'
														)}</span>`
															: `<span>${addIcon(
																	iconSet[0] ?? 'thumbs-up',
																	thumbsUpColor,
																	'thumbs-up',
																	'35px'
															  )}</span>`
													}
                        </a>
                      </td>
                      <td class="m-thumbs" width="70" height="40" style="border:none;outline:none;padding:0;">
                        <a target="_blank" ${
													!advanced.disableLinks && `href=${negUrl.href}`
												} style="cursor:pointer;width:40px;height:40px;display:block;text-decoration:none;outline:none;">
                          ${
														design?.thumbsUp?.color?.inactive ||
														design?.widget?.thumbsUp?.color?.inactive
															? `<span class="thumbs-default">${addIcon(
																	iconSet[1] ?? 'thumbs-down',
																	thumbsDownColor,
																	'thumbs-down',
																	'35px'
															  )}</span>
                          <span class="thumbs-hover">${addIcon(
														iconSet[1] ?? 'thumbs-down',
														thumbsDownColorHover,
														'thumbs-down',
														'35px'
													)}</span>`
															: `<span>${addIcon(
																	iconSet[1] ?? 'thumbs-down',
																	thumbsDownColor,
																	'thumbs-down',
																	'35px'
															  )}</span>`
													}
                        </a>
                      </td>
                    </tr>
                  </table>`
								}
              </td>`;
						case 'stars':
							const starColor = getCustomStyle('starRating', 'color', '#fcc83b');
							const starColorHover = getCustomStyle(
								'starRating',
								'colorHover',
								'#fcc83b'
							);

							const starScale = block?.properties?.scale ?? 10;
							const starElements = Object.keys(block.properties.elements).filter(
								(label, index) => {
									if (index < starScale) {
										return label;
									}
								}
							);

							return `
              <td align=${widgetAlign} style="padding:${tablePadding};">
                <table>
                  <tr>
                  ${starElements
										.map((key, index) => {
											pageUrl.searchParams.set(blockParam, key);

											return block?.properties?.showCaptions
												? `<td width="100" height="40" class="m-star" style="border:none;outline:none;padding:0;vertical-align:top">
                          <div class="label-wrapper" style="mso-hide:all;max-height:0;max-width:100%;overflow:visible!important;margin-bottom:0;">
                            <div style="position:relative;Margin-top:0px;display:inline-block;width:100%;">
                              <div style="z-index:2;position:relative;overflow:visible!important;right:0">
                                
                                <span class="label-score" style="display:block;">
                                  <a ${
																		!advanced.disableLinks && `href=${pageUrl.href}`
																	} target="_blank" style="cursor:pointer;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;">
                                    ${
																			design?.starRating?.color?.inactive ||
																			design?.widget?.starRating?.color?.inactive
																				? `<span class="star-default">${addIcon(
																						'star',
																						starColor,
																						key,
																						'30px'
																				  )}</span>
                                       <span class="star-hover">${addIcon(
																					'star',
																					starColorHover,
																					key,
																					'30px'
																				)}</span>`
																				: `<img height="30" width="31" src="${assetsUrl}/assets/img/star.png" alt="${key}">`
																		}
                                  </a>
                                </span>
                                <!--[if !mso]><!-->
                                  <div style="max-height:0px;overflow:hidden;min-width:50px;position:absolute;margin-top:5px;">
                                    <span style="display:block;padding:5px 10px;font-size:14px;line-height:normal;font-family:Arial;border-radius:3px;background-color:#222;color:#ffffff;opacity:.8;text-align:left;">${
																			block.properties.elements[index + 1].label
																		}</span>
                                  </div>
                                <!--<![endif]-->
                              </div>
                            </div>
                          </div>
                        </td>
                        `
												: `<td width="30" class="m-star" height="40" style="border-spacing:1;border-collapse:separate;text-align:center;">
                          <a ${
														!advanced.disableLinks && `href=${pageUrl.href}`
													} target="_blank" style="cursor:pointer;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;">
                            ${
															design?.starRating?.color?.inactive ||
															design?.widget?.starRating?.color?.inactive
																? `<span class="star-default">${addIcon(
																		'star',
																		starColor,
																		key,
																		'30px'
																  )}</span>
                               <span class="star-hover">${addIcon(
																	'star',
																	starColorHover,
																	key,
																	'30px'
																)}</span>`
																: `<img height="30" width="31" src="${assetsUrl}/assets/img/star.png" alt="${key}">`
														}
                          </a>
                        </td>`;
										})
										.join('')}
                  </tr>
                  ${addLegend(starScale)}
                </table>
              </td>
              `;

						case 'emoji':
							const emojiRange = [
								{ value: 1, img: 'angry', alt: 'Emoji angry' },
								{ value: 2, img: 'sad', alt: 'Emoji sad' },
								{ value: 3, img: 'neutral', alt: 'Emoji neutral' },
								{ value: 4, img: 'happy', alt: 'Emoji happy' },
								{ value: 5, img: 'extra-happy', alt: 'Emoji very happy' },
							];

							return `
              <td align=${widgetAlign} style="padding:${tablePadding};">
                <table width="100%" style="border-collapse:collapse;border-spacing:0px;">
                  <tr>
                  ${emojiRange
										.map((obj, index) => {
											pageUrl.searchParams.set(blockParam, obj.value);
											return block?.properties?.showCaptions
												? `<td class="m-emoji" width="100" height="59" style="border:none;outline:none;padding:0;vertical-align:top">
                          <div class="label-wrapper" style="mso-hide:all;max-height:0;max-width:100%;overflow:visible!important;margin-bottom:0;">
                            <div style="position:relative;Margin-top:0px;display:inline-block;width:100%;">
                              <div style="z-index:2;position:relative;overflow:visible!important;right:0">
                                
                                <span class="label-score" style="display:block;">
                                  <a target="_blank" ${
																		!advanced.disableLinks && `href=${pageUrl.href}`
																	} style="cursor:pointer;width:60px;height:59px;display:block;text-decoration:none;outline:none;">
                                    <span class="emoji-default">
                                      <img height="100%" width="100%" src="${assetsUrl}/assets/img/surveys/emoji/${
														obj.img
												  }.png" alt="${obj.alt}">
                                    </span>
                                    <span class="emoji-hover">
                                      <img height="100%" width="100%" src="${assetsUrl}/assets/img/surveys/emoji/${
														obj.img
												  }-hover.png" alt="${obj.alt}">
                                    </span>
                                  </a>
                                </span>
                                <!--[if !mso]><!-->
                                  <div style="max-height:0px;overflow:hidden;min-width:50px;position:absolute;top:0;margin-top:65px;">
                                    <span style="display:block;padding:5px 10px;font-size:14px;line-height:normal;font-family:Arial;border-radius:3px;background-color:#222;color:#ffffff;opacity:.8;text-align:left;">${
																			block.properties.emoji[index + 1].label
																		}</span>
                                  </div>
                                <!--<![endif]-->
                              </div>
                            </div>
                          </div>
                        </td>
                        `
												: `<td class="m-emoji" width="100" height="59" style="border:none;outline:none;padding:0;" >
                        <a target="_blank" ${
													!advanced.disableLinks && `href=${pageUrl.href}`
												} style="cursor:pointer;width:60px;height:59px;display:block;text-decoration:none;outline:none;">
                          <span class="emoji-default">
                            <img height="100%" width="100%" src="${assetsUrl}/assets/img/surveys/emoji/${
														obj.img
												  }.png" alt="${obj.alt}">
                          </span>
                          <span class="emoji-hover">
                            <img height="100%" width="100%" src="${assetsUrl}/assets/img/surveys/emoji/${
														obj.img
												  }-hover.png" alt="${obj.alt}">
                          </span>
                        </a>
                      </td>
                      `;
										})
										.join('')}
                  </tr>
                  ${addLegend(emojiRange.length)}
                </table>
              </td>
              `;

						case 'bar':
							const barOptions = [
								{ value: 1, text: '--' },
								{ value: 2, text: '-' },
								{ value: 3, text: '0' },
								{ value: 4, text: '+' },
								{ value: 5, text: '++' },
							];

							const barTableWidth = Math.round(100 / barOptions.length);
							const barTableColor = getCustomStyle('numericRating', 'border', '#dddddd');
							const barTableBgColor = getCustomStyle(
								'numericRating',
								'background',
								'#ffffff'
							);
							const barFontColor = getCustomStyle('numericRating', 'color', '#727272');

							return `
              <td align="center" style="padding:${tablePadding};">
                <table width="100%">
                  <tr>
                  ${barOptions
										.map(({ value, text }) => {
											pageUrl.searchParams.set(blockParam, value);
											return `
                      <td width="${barTableWidth}%" height="50" class="m-score-hover" style="border-spacing:1;border-collapse:separate;border:1px solid ${barTableColor};color:${barFontColor};background-color:${barTableBgColor};text-align:center;">
                        <a ${
													!advanced.disableLinks && `href=${pageUrl.href}`
												} target="_blank" style="cursor:pointer;padding:10px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;color:${barFontColor};">
                          ${text}
                        </a>
                      </td>
                      `;
										})
										.join('')}
                  </tr>
                  ${addLegend(barOptions.length)}
                </table>
              </td>
              `;
						case 'nps':
						case 'numeric':
							const range =
								type === 'nps'
									? block?.properties?.reverseScore
										? rangeArray(0, 10).reverse()
										: rangeArray(0, 10)
									: rangeArray(
											block.properties.includeZero ? 0 : 1,
											block.properties.scale || 10
									  );

							const numericTableWidth = Math.round(100 / range.length);
							const numericTableColor = getCustomStyle(
								'numericRating',
								'border',
								'#dddddd'
							);
							const numericTableBgColor = getCustomStyle(
								'numericRating',
								'background',
								'#ffffff'
							);
							const numericFontColor = getCustomStyle(
								'numericRating',
								'color',
								'#727272'
							);

							return `
              <td align="center" style="padding:${tablePadding}">
                <table width="100%">
                  <tr>
                  ${range
										.map(value => {
											pageUrl.searchParams.set(blockParam, value);
											return `
                      <td width="${numericTableWidth}%" class="m-score-hover" height="50" style="border-spacing:1;border-collapse:separate;border:1px solid ${numericTableColor};color:${numericFontColor};background-color:${numericTableBgColor};text-align:center;">
                        <a ${
													!advanced.disableLinks && `href=${pageUrl.href}`
												} target="_blank" style="cursor:pointer;padding:10px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;color:${numericFontColor};">
                          ${value}
                        </a>
                      </td>
                      `;
										})
										.join('')}
                  </tr>
                  ${addLegend(range.length)}
                </table>
              </td>
              `;
						case 'gcr':
							const gcrTableColor = getCustomStyle('button', 'border', '#dddddd');
							const gcrTableBgColor = getCustomStyle('button', 'background', '#ffffff');
							const gcrFontColor = getCustomStyle('button', 'color', '#727272');
							const gcrFontSize = getCustomStyle('button', 'fontSize', '14px');

							let gcrOptions = Object.values(block.properties.elements).map(element => {
								return { value: element.value, label: element.label };
							});

							if (block?.properties?.reverseScore) {
								gcrOptions = gcrOptions.reverse();
							}

							return `
              <td align="center" style="padding:${tablePadding}">
                <table width="100%" style="border-spacing:10px;border-collapse:separate;">
                  <tr>
                  ${gcrOptions
										.map(({ value, label }, index) => {
											pageUrl.searchParams.set(blockParam, value);
											return `<td width="33%" height="50" class="m-score-hover" style="color:${gcrFontColor};text-align:center;border:1px solid ${gcrTableColor};background-color:${gcrTableBgColor};font-size:${gcrFontSize};">
                          <a ${
														!advanced.disableLinks && `href=${pageUrl.href}`
													} target="_blank" style="cursor:pointer;padding:8px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;border-radius:3px;color:${gcrFontColor};font-size:${gcrFontSize}">
                            ${
															iconSet.length
																? addIcon(
																		iconSet[index],
																		gcrFontColor,
																		label,
																		gcrFontSize
																  )
																: ''
														}
                            ${label}
                          </a>
                        </td>
                        ${(index + 1) % 3 === 0 ? `</tr><tr>` : ''}
                        `;
										})
										.join('')}
                  </tr>
                </table>
              </td>
              `;
						case 'category':
							const categoryTableColor = getCustomStyle('button', 'border', '#dddddd');
							const categoryTableBgColor = getCustomStyle(
								'button',
								'background',
								'#ffffff'
							);
							const categoryFontColor = getCustomStyle('button', 'color', '#727272');
							const categoryFontSize = getCustomStyle('button', 'fontSize', '14px');

							const categoryOptions = Object.values(block.properties.elements).map(
								element => {
									return { label: element.label, icon: element.icon };
								}
							);

							const categoryTdWidth =
								categoryOptions.length === 2
									? '50%'
									: categoryOptions.length === 4
									? '25%'
									: '33%';

							return `
              <td align="center" style="padding:${tablePadding}">
                <table width="100%" style="border-spacing:10px;border-collapse:separate;">
                  <tr>
                  ${categoryOptions
										.map(({ label }, index) => {
											pageUrl.searchParams.set(blockParam, label);
											return `<td width="${categoryTdWidth}" class="m-score-hover" height="50" style="color:${categoryFontColor};border:1px solid ${categoryTableColor};background-color:${categoryTableBgColor};text-align:center;">

                          <a ${
														!advanced.disableLinks && `href=${pageUrl.href}`
													} target="_blank" style="cursor:pointer;border-radius:3px;padding:8px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;color:${categoryFontColor};font-size:${categoryFontSize}">
                            ${
															iconSet.length
																? addIcon(
																		iconSet[index],
																		categoryFontColor,
																		label,
																		categoryFontSize
																  )
																: ''
														}
                            <span style="display:inline-block;vertical-align:middle">${label}</span>
                          </a>
    
                        </td>
                        ${(index + 1) % 3 === 0 ? `</tr><tr>` : ''}
                    `;
										})
										.join('')}
                  </tr>
                </table>
              </td>
              `;
						case 'ces':
							let cesOptions = [
								{ value: 1, text: '--' },
								{ value: 2, text: '-' },
								{ value: 3, text: '0' },
								{ value: 4, text: '+' },
								{ value: 5, text: '++' },
							];

							if (block?.properties?.reverseScore) {
								cesOptions = cesOptions.reverse();
							}

							const cesTableWidth = Math.round(100 / cesOptions.length);

							return `
              <td align="center" style="padding:${tablePadding};">
                <table width="100%">
                  <tr>
                  ${cesOptions
										.map(({ value, text, color }, index) => {
											pageUrl.searchParams.set(blockParam, value);
											return `
                      <td width="${cesTableWidth}%" height="50" class="ces-${value}" style="vertical-align:top;border-spacing:1;border-collapse:separate;border:0px;color:#727272;text-align:center;">
                        ${
													block?.properties?.showCaptions
														? `<div class="label-wrapper" style="mso-hide:all;max-height:0;max-width:100%;overflow:visible!important;margin-bottom:0;">
                              <div style="position:relative;Margin-top:0px;display:inline-block;width:100%;">
                                <div style="z-index:2;position:relative;overflow:visible!important;right:0">
                                  
                                  <span class="ces-score" style="display:block;">
                                    <a ${
																			!advanced.disableLinks && `href=${pageUrl.href}`
																		} target="_blank" style="cursor:pointer;padding:10px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;color:#727272;max-height:none!important;border-bottom:4px solid #727272;">
                                      ${text}
                                    </a>
                                  </span>
                                  <!--[if !mso]><!-->
                                    <div style="max-height:0px;overflow:hidden;min-width:50px;position:absolute;top:0;margin-top:45px;">
                                      <span style="display:block;padding:5px 10px;font-size:14px;line-height:normal;font-family:Arial;border-radius:3px;background-color:#222;color:#ffffff;opacity:.8;text-align:left;">${
																				block.properties.elements[index + 1].label
																			}</span>
                                    </div>
                                  <!--<![endif]-->
                                </div>
                              </div>
                            </div>`
														: `<span class="ces-score" style="display:block;">
                            <a ${
															!advanced.disableLinks && `href=${pageUrl.href}`
														} target="_blank" style="cursor:pointer;padding:10px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;color:#727272;max-height:none!important;border-bottom:4px solid #727272;">
                              ${text}
                            </a>
                          </span>`
												}
                      </td>
                      `;
										})
										.join('')}
                  </tr>
                  ${addLegend(cesOptions.length)}
                </table>
              </td>
              `;
						default:
							return '';
					}
				})()}
        </tr>
      </tbody>
    </table>`;
	}

	if (advanced.cssOnly) {
		return `<!-- Start Mopinion Email snippet CSS ( place in <head> ) -->
        <style>
          ${generalCss}
        </style>
      <!-- Start Mopinion Email snippet CSS --> `;
	} else if (advanced.htmlOnly) {
		return `<!-- Start Mopinion Email Snippet HTML ( place in <body> ) -->
          ${snippet}
      <!-- Start Mopinion Email Snippet HTML ( place in <body> ) --> `;
	} else {
		return `<!-- Start Mopinion Email Feedback Snippet -->
        <head>
          <style>
            ${generalCss}
          </style>
        </head>
        <body>
          ${snippet}
        </body>
      <!-- End Mopinion Email Feedback Snippet --> `;
	}
}

export function legacyGenerateEmailTemplateString(
	state,
	domain,
	block = {},
	feedbackButtonBg,
	feedbackButtonText
) {
	const assetsUrl = 'https://app.mopinion.com';

	const pageUrl = new URL(createLandingsPageURL(domain, state.properties));

	//Map layout and find possible website data elements containing url_parameters -> if found add to landingpage url
	state.map.forEach(id => {
		const block = getBlock(state.blocks, id);

		if (block.typeName === 'website_data') {
			Object.values(block.properties?.elements ?? {}).forEach(websiteDataElement => {
				if (websiteDataElement.type === 'url_parameter') {
					pageUrl.searchParams.set(websiteDataElement.value, 'ENTER_VALUE');
				}
			});
		}
	});

	const type = block.typeName === 'rating' ? block.properties.type : block.typeName;
	const blockParam = `${block.typeName}_${String(block.id).replace('new_', '')}`;

	let snippet = '';

	if (type === 'feedback_button') {
		snippet = `<table width="100%">
      <tbody>
        <tr>
          <td align="center" style="padding: 20px 20px 20px 20px;">
            <table>
              <tr>
                <td width="200" height="50" style="text-align:center;">
                  <a href="${pageUrl.href}" target="_blank" style="font-family:inherit;width:100%;font-size:16px;height:50px;line-height:50px;border-radius:4px;color:#fff;background-color:${feedbackButtonBg};padding:0px 20px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;">
                    ${feedbackButtonText}
                  </a>
                </td>
              </tr>
            </table>
        </tr>
      </tbody>
    </table>`;
	} else {
		snippet = `<table width="100%">
      <tbody>
        <tr>
          <td style="padding: 10px 30px 0px 30px; text-align:center;">${block.title}</td>
        </tr>
        <tr>
        ${(() => {
					switch (type) {
						case 'thumbs':
							let posUrl = new URL(pageUrl);
							posUrl.searchParams.set(blockParam, 'positive');

							let negUrl = new URL(pageUrl);
							negUrl.searchParams.set(blockParam, 'negative');

							return `
              <td align="center" style="padding: 20px 30px 20px 30px;">
                <table style="border-collapse:collapse;border-spacing:0px;">
                  <tr>
                    <td width="94" height="73" style="border:none;outline:none;padding:0;" >
                      <a target="_blank" href="${posUrl.href}" style="width:94px;height:73px;display:block;text-decoration:none;outline:none;">
                        <img height="73" width="94" src="${assetsUrl}/assets/img/thumbup.jpg" alt="Thumbs up">
                      </a>
                    </td>
                    <td width="94" height="73" style="border:none;outline:none;padding:0;">
                      <a target="_blank" href="${negUrl.href}" style="width:94px;height:73px;display:block;text-decoration:none;outline:none;">
                        <img height="73" width="94" src="${assetsUrl}/assets/img/thumbdown.jpg" alt="Thumbs down">
                      </a>
                    </td>
                  </tr>
                </table>
              </td>
              `;
						case 'stars':
							return `
              <td align="center" style="padding: 20px 20px 20px 20px;">
                <table>
                  <tr>
                  ${Object.keys(block.properties.elements)
										.map(key => {
											const el = block.properties.elements[key];
											pageUrl.searchParams.set(blockParam, key);

											return `<td width="30" height="40" style="border-spacing:1;border-collapse:separate;text-align:center;">
                        <a href="${pageUrl.href}" target="_blank" style="text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;">
                          <img height="30" width="31" src="${assetsUrl}/assets/img/star.png" alt="${key}">
                        </a>
                      </td>`;
										})
										.join('')}
                  </tr>
                </table>
              </td>
              `;

						case 'emoji':
							const emojiRange = [
								{ value: 1, img: 'angry', alt: 'Emoji angry' },
								{ value: 2, img: 'sad', alt: 'Emoji sad' },
								{ value: 3, img: 'neutral', alt: 'Emoji neutral' },
								{ value: 4, img: 'happy', alt: 'Emoji happy' },
								{ value: 5, img: 'extra-happy', alt: 'Emoji very happy' },
							];

							return `
              <td align="center" style="padding: 20px 30px 20px 30px;">
                <table width="100%" style="border-collapse:collapse;border-spacing:0px;">
                  <tr>
                  ${emojiRange
										.map(obj => {
											pageUrl.searchParams.set(blockParam, obj.value);
											return `
                      <td width="60" height="59" style="border:none;outline:none;padding:0;" >
                        <a target="_blank" href="${pageUrl.href}" style="width:60px;height:59px;display:block;text-decoration:none;outline:none;">
                          <img height="100%" width="100%" src="${assetsUrl}/assets/img/surveys/emoji/${obj.img}.png" alt="${obj.alt}">
                        </a>
                      </td>
                      `;
										})
										.join('')}
                  </tr>
                </table>
              </td>
              `;

						case 'bar':
							const barOptions = [
								{ value: 1, text: '--' },
								{ value: 2, text: '-' },
								{ value: 3, text: '0' },
								{ value: 4, text: '+' },
								{ value: 5, text: '++' },
							];

							return `
              <td align="center" style="padding: 20px 20px 20px 20px;">
                <table width="100%">
                  <tr>
                  ${barOptions
										.map(({ value, text }) => {
											pageUrl.searchParams.set(blockParam, value);
											return `
                      <td width="${tableWidth}" height="50" style="border-spacing:1;border-collapse:separate;border:1px solid ${tableColor};color:${fontColor};background-color:${tableBgColor};text-align:center;">
                        <a href="${pageUrl.href}" target="_blank" style="padding:10px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;color:${fontColor};">
                          ${text}
                        </a>
                      </td>
                      `;
										})
										.join('')}
                  </tr>
                </table>
              </td>
              `;
						case 'nps':
						case 'numeric':
							const range =
								type === 'nps'
									? rangeArray(0, 10)
									: rangeArray(
											block.properties.includeZero ? 0 : 1,
											block.properties.scale || 10
									  );

							const tableWidth = Math.round(100 / rangeArray.length);
							//will change
							const tableColor = '#dddddd';
							const tableBgColor = '#ffffff';
							const fontColor = '#727272';

							return `
              <td align="center" style="padding: 20px 20px 20px 20px;">
                <table width="100%">
                  <tr>
                  ${range
										.map(value => {
											pageUrl.searchParams.set(blockParam, value);
											return `
                      <td width="${tableWidth}" height="50" style="border-spacing:1;border-collapse:separate;border:1px solid ${tableColor};color:${fontColor};background-color:${tableBgColor};text-align:center;">
                        <a href="${pageUrl.href}" target="_blank" style="padding:10px;text-decoration:none;font-weight:bold;text-align:center;display:block;outline:none;margin:auto;color:${fontColor};">
                          ${value}
                        </a>
                      </td>
                      `;
										})
										.join('')}
                  </tr>
                </table>
              </td>
              `;
					}
				})()}
        </tr>
      </tbody>
    </table>`;
	}

	return `
  <!-- Start Mopinion Email Feedback Snippet -->
  ${snippet}
  <!-- End Mopinion Email Feedback Snippet --> 
  `;
}

export function designHasCustom({ custom = {}, email_widgets = {} }) {
	const parsedCustom = typeof custom === 'object' && custom ? custom : tryParse(custom);
	const parsedWidget =
		typeof email_widgets === 'object' && email_widgets
			? email_widgets
			: tryParse(email_widgets);
	const { useReact, otherCss, ...styles } = parsedCustom;

	return (
		isObjectWithKeys(styles) ||
		isObjectWithKeys(otherCss) ||
		isObjectWithKeys(parsedWidget)
	);
}

export function isUuid(id) {
	return isNaN(Number(id));
}

export function getBlock(blocks = {}, idOrUuid) {
	if (isUuid(idOrUuid)) {
		return (
			Object.values(blocks).find(
				block => block.uuid == String(idOrUuid).split('_').pop()
			) || {}
		);
	}

	return blocks[idOrUuid] || {};
}

export function getIdFromFieldName(field = '') {
	if (typeof field !== 'string') {
		return '';
	}

	const split = field.split('_');

	//sub fields of contact blocks contain
	if (field.includes('contact') && split.length === 3) {
		return split[1];
	}

	return split.pop();
}

export function getQuestionNumber(block = {}, map = []) {
	const index = map.indexOf(block.id);
	if (index === -1) {
		return index;
	}

	return index + 1;
}

export function getRule(rules, idOrUuid) {
	if (isUuid(idOrUuid)) {
		return Object.values(rules).find(rule => rule.uuid == idOrUuid) || {};
	}

	return rules[idOrUuid] || {};
}

export function removeNewFromId(str) {
	return String(str).replace('new_', '');
}

//Extract the possible values from the block or generated them because they are hardcoded values
export function extractValuesFromBlock(block) {
	const {
		typeName,
		properties,
		properties: { elements = {}, labelsAsValue, includeZero, scale, emoji } = {},
	} = block;

	switch (typeName) {
		case 'nps':
			return [...Array(11)].map((x, i) => ({ value: i, label: i }));

		case 'ces':
			return Object.keys(elements).map(key => ({
				value: key,
				label: elements[key].label,
			}));

		case 'rating':
			switch (properties.type) {
				case 'stars':
					return [...Array(scale ? scale : 10)].map((x, i) => ({
						value: i + 1,
						label: i + 1,
					}));

				case 'numeric':
					return [
						...Array(scale ? scale + (includeZero ? 1 : 0) : includeZero ? 11 : 10),
					].map((x, i) => ({
						value: includeZero ? i : i + 1,
						label: includeZero ? i : i + 1,
					}));

				case 'bar':
					return [...Array(5)].map((x, i) => ({ value: i + 1, label: i + 1 }));

				case 'emoji':
					return Object.keys(emoji).map(key => ({
						value: key,
						label: emoji[key]?.label,
					}));
			}

		case 'gcr':
		case 'thumbs':
		case 'category':
		case 'select':
		case 'radio':
		case 'checkbox':
			let values = Object.keys(elements).map(key => ({
				value: key,
				label: elements[key].label,
			}));

			if (properties.elements_extra?.[1]) {
				values.push({
					value: Number(Object.keys(elements).pop()) + 1,
					label: properties.elements_extra[1].label,
				});
			}

			return values;

		case 'screenshot':
			return [{ value: 1, label: 'taken' }];

		default:
			return [];
	}
}

export function getFormBorderRadius(path) {
	return formBorderRadius[path ?? 'semirounded'];
}

export function getButtonBorderRadius(path) {
	return buttonBorderRadius[path ?? 'semirounded'];
}

export function getThemeColors(
	designCustom = {},
	defaultThemeColors = ['#03a9f4', '#0288d1', '#B3E5FC', '#212121']
) {
	const colorsInCustom = Object.keys(designCustom).reduce((collectedColors, key) => {
		const cssRule = designCustom[key];

		if (typeof cssRule === 'object') {
			try {
				Object.keys(cssRule).forEach(ruleKey => {
					if (
						['color', 'backgroundColor'].includes(ruleKey) &&
						cssRule[ruleKey]['inactive'] &&
						collectedColors.includes(cssRule[ruleKey]['inactive']) === false
					) {
						collectedColors.push(cssRule[ruleKey]['inactive']);
					}
				});
			} catch (e) {}
		}

		return collectedColors;
	}, []);

	const themeColors = [...new Set(colorsInCustom), ...defaultThemeColors].slice(0, 4);

	return themeColors;
}

export function getImagePath(path = '') {
	return `https://cacheorcheck.mopinion.com/survey/public/image?file=${path}`;
}

//filter out default themes added for customers
export function isDefaultTheme(theme = '') {
	const defaults = [
		'survey-default.css',
		'survey-purple-rain.css',
		'survey-indigo-dream.css',
		'survey-minty-green.css',
		'survey-redtastic.css',
		'survey-pink-panther.css',
		'survey-material-white.css',
	];
	const themeName = theme.split('/');
	return defaults.includes(themeName[themeName.length - 1]);
}
