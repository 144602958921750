import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import useOnboardingContext from './useOnboardingContext';
import { useTranslation, Trans } from 'react-i18next';

import TrialMenuStepper from './TrialMenuStepper';
import {
	ActionButton,
	Typography,
	CardContent,
	CardActions,
	Grid,
} from '@/components/Layout';
import { onboarding_add_form_to_deployment_gif } from '@/img';

import { DeploymentExplainer } from '@/components/Explainer';

const useStyles = makeStyles(theme => ({
	cardActions: {
		padding: theme.spacing(2),
	},
	marginLeft: {
		marginLeft: 'auto',
	},
	marginRight: {
		marginRight: 'auto',
	},
	italic: {
		fontStyle: 'italic',
	},
	image: {
		'& img': {
			marginTop: theme.spacing(2),
			maxWidth: 175,
		},
	},
}));

export default function TrialFlowDeployment({ isReturningTrial }) {
	const onboarding = useOnboardingContext();
	const { url } = onboarding;
	const location = useLocation();

	if (isReturningTrial) {
		return <WelcomeBack />;
	}
	if (url.includes('deployment/edit')) {
		return <DeploymentEditContent />;
	}
	if (onboarding?.from?.includes('deployment/edit')) {
		return <FinishedDeploymentContent />;
	}
	return <DeploymentListContent />;
}

function DeploymentListContent() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const { trialStep, channel } = onboarding;
	const classes = useStyles();

	return (
		<>
			<CardContent>
				<TrialMenuStepper activeStep={trialStep - 1} />
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs
					>
						<Trans
							i18nKey={`onboarding-trial_menu-step2-list-text-${channel}`}
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p: <Typography variant="body1" />,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					className={classes.marginRight}
					color="primary"
					data-track-event="onboarding_trial_step_deployment_show_explainer"
				>
					<DeploymentExplainer className={classes.marginLeft} />
				</ActionButton>
				<ActionButton
					variant="contained"
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'toggle_trial_menu',
							payload: {
								open: false,
							},
						});
					}}
					data-track-event="onboarding_trial_step_deployment_list"
					data-test-element="onboarding_trial_step_deployment_list"
				>
					{t`onboarding-trial_menu-step2-list-button_start`}
				</ActionButton>
			</CardActions>
		</>
	);
}

function DeploymentEditContent() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const { trialStep } = onboarding;
	const classes = useStyles();

	return (
		<>
			<CardContent>
				<TrialMenuStepper activeStep={trialStep - 1} />
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						className={classes.image}
					>
						<img src={onboarding_add_form_to_deployment_gif} />
					</Grid>
					<Grid
						item
						xs
					>
						<Trans
							i18nKey="onboarding-trial_menu-step2-edit-text"
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p: <Typography variant="body1" />,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					variant="contained"
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'toggle_trial_menu',
							payload: {
								open: false,
							},
						});
					}}
					data-track-event="onboarding_trial_step_deployment_editor"
					data-test-element="onboarding_trial_step_deployment_editor_button"
				>
					{t`onboarding-trial_menu-step2-edit-button_start`}
				</ActionButton>
			</CardActions>
		</>
	);
}

function FinishedDeploymentContent() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const { trialStep } = onboarding;
	const classes = useStyles();

	return (
		<>
			<CardContent>
				<TrialMenuStepper activeStep={trialStep} />
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs
					>
						<Trans
							i18nKey="onboarding-trial_menu-step3-list-text"
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p1: (
									<Typography
										variant="body1"
										paragraph
										italic
									/>
								),
								p2: <Typography variant="body1" />,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					variant="contained"
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'set_trial_step',
							payload: {
								step: 4,
								url: '/reporting/inbox',
							},
						});
					}}
					data-track-event="onboarding_trial_step_deployment_finished"
					data-test-element="onboarding_trial_step_deployment_finished_button"
				>
					{t`onboarding-trial_menu-step3-list-button_start`}
				</ActionButton>
			</CardActions>
		</>
	);
}

function WelcomeBack() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const { trialStep } = onboarding;
	const classes = useStyles();

	return (
		<>
			<CardContent>
				<TrialMenuStepper activeStep={trialStep} />
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						className={classes.image}
					>
						<img src={`/assets/img/r/onboarding/onboarding_edit_form.svg`} />
					</Grid>
					<Grid
						item
						xs
					>
						<Trans
							i18nKey="onboarding-trial_menu-step2-welcome_back-title"
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p: <Typography variant="body1" />,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					variant="contained"
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'toggle_trial_menu',
							payload: {
								open: false,
							},
						});
					}}
					data-track-event="onboarding_trial_step_deployment_welcome_back"
				>
					{t`onboarding-trial_menu-step2-welcome_back-button_continue`}
				</ActionButton>
			</CardActions>
		</>
	);
}
