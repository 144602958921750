import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useOneEffect } from '@/hooks';
import { SimpleDialog, Typography } from '@/components/Layout';

import { useDeploymentStore } from './DeploymentStore';

export default function DeploymentDraftFoundDialog() {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const currentDeployment = useDeploymentStore(store => store.current);
	const draftDeployment = useDeploymentStore(store => store.draft);
	const setVersion = useDeploymentStore(store => store.actions.setVersion);

	useOneEffect({
		condition: () => currentDeployment?.datetime && draftDeployment?.datetime,
		effect: () => {
			if (draftDeployment?.rules?.length === 0) {
				return;
			}

			const deploymentTime = new Date(currentDeployment.datetime).getTime();
			const draftTime = new Date(draftDeployment.datetime).getTime();

			if (draftTime > deploymentTime) {
				setOpen(true);
			}
		},
	});
	return (
		<SimpleDialog
			open={open}
			onClose={() => setOpen(false)}
			onSubmit={() => {
				setVersion('draft');
				setOpen(false);
			}}
			title={t`data_collection-deployment_draft_found_dialog-title`}
			cancel={t`data_collection-deployment_draft_found_dialog-stay_on_current`}
			submit={t`data_collection-deployment_draft_found_dialog-go_to_draft`}
			data-track-event="deployment_draft_found_dialog_go_to_draft"
		>
			<Typography>{t`data_collection-deployment_draft_found_dialog-content`}</Typography>
		</SimpleDialog>
	);
}
