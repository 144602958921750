import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';

import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InboxIcon from '@material-ui/icons/Inbox';
import TagsIcon from '@material-ui/icons/Label';
import ReassignIcon from '@material-ui/icons/CompareArrows';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RefreshIcon from '@material-ui/icons/Refresh';
import ChevronDown from '@material-ui/icons/ExpandMore';
import ChevronUp from '@material-ui/icons/ExpandLess';
import ViewIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ShareIcon from '@material-ui/icons/Share';
import ActionIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReplyIcon from '@material-ui/icons/Reply';
import InvoiceIcon from '@material-ui/icons/Receipt';
import DetailIcon from '@material-ui/icons/ZoomIn';
import DetailLessIcon from '@material-ui/icons/ZoomOut';
import MenuIcon from '@material-ui/icons/Menu';
import LogicIcon from '@material-ui/icons/CallSplit';
import FilterIcon from '@material-ui/icons/FilterList';
import CloneIcon from '@material-ui/icons/FlipToFront';
import HelpIcon from '@material-ui/icons/HelpOutline';
import CodeIcon from '@material-ui/icons/Code';
import NewWindowIcon from '@material-ui/icons/OpenInNew';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SettingsIcon from '@material-ui/icons/Settings';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import DownloadIcon from '@material-ui/icons/GetApp';
import SetActiveIcon from '@material-ui/icons/CheckCircleOutlined';
import UsersIcon from '@material-ui/icons/Group';
import ExpandAll from '@material-ui/icons/UnfoldMore';
import CollapseAll from '@material-ui/icons/UnfoldLess';
import CopyIcon from '@material-ui/icons/FileCopy';
import BookmarkFilledIcon from '@material-ui/icons/Bookmark';
import BookmarkIcon from '@material-ui/icons/BookmarkBorder';
import FeedbackIcon from '@material-ui/icons/Feedback';

import { Link } from 'react-router-dom';

import { TrialIcon } from '@/components/Icons';
import RenderConditional from './RenderConditional';
import Tooltip from './Tooltip';

const useStyles = makeStyles(theme => ({
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

function getIcon(action) {
	switch (action) {
		case 'detail':
			return DetailIcon;
		case 'less_detail':
			return DetailLessIcon;
		case 'check':
			return CheckIcon;
		case 'add':
			return AddIcon;
		case 'more':
		case 'options':
		case 'more_vert':
			return MoreVertIcon;
		case 'edit':
			return EditIcon;
		case 'close':
			return CloseIcon;
		case 'undo':
			return UndoIcon;
		case 'redo':
			return RedoIcon;
		case 'remove_list':
		case 'minus':
			return RemoveIcon;
		case 'plus':
			return PlusIcon;
		case 'delete':
			return DeleteIcon;
		case 'inbox':
			return InboxIcon;
		case 'tags':
			return TagsIcon;
		case 'reassign':
			return ReassignIcon;
		case 'back':
			return ArrowBackIcon;
		case 'to':
			return ArrowForwardIcon;
		case 'refresh':
			return RefreshIcon;
		case 'up':
		case 'expand_less':
			return ChevronUp;
		case 'down':
		case 'expand_more':
			return ChevronDown;
		case 'next':
			return ChevronRightIcon;
		case 'previous':
			return ChevronLeftIcon;
		case 'view':
			return ViewIcon;
		case 'hide':
			return HideIcon;
		case 'lock':
			return LockIcon;
		case 'reply':
			return ReplyIcon;
		case 'share':
			return ShareIcon;
		case 'add_action':
			return ActionIcon;
		case 'receipt':
		case 'invoice':
			return InvoiceIcon;
		case 'menu':
			return MenuIcon;
		case 'trial':
			return TrialIcon;
		case 'filters':
			return FilterIcon;
		case 'logic':
			return LogicIcon;
		case 'clone':
			return CloneIcon;
		case 'help':
			return HelpIcon;
		case 'code':
			return CodeIcon;
		case 'new_window':
		case 'ext_link':
			return NewWindowIcon;
		case 'notification':
			return NotificationsIcon;
		case 'notification_active':
			return NotificationsActiveIcon;
		case 'settings':
		case 'cog':
			return SettingsIcon;
		case 'fire':
			return WhatsHotIcon;
		case 'set_active':
			return SetActiveIcon;
		case 'download':
			return DownloadIcon;
		case 'users':
			return UsersIcon;
		case 'expand_all':
			return ExpandAll;
		case 'collapse_all':
			return CollapseAll;
		case 'copy':
			return CopyIcon;
		case 'bookmark':
			return BookmarkIcon;
		case 'bookmarked':
			return BookmarkFilledIcon;
		case 'feedback':
			return FeedbackIcon;
		default:
			return null;
	}
}

const ActionIconButton = React.forwardRef(function ActionIconButton(
	{
		children,
		action,
		IconProps = {},
		tooltip,
		loading,
		link,
		badge,
		BadgeProps = {},
		dataTrackEvent,
		...props
	},
	ref
) {
	const classes = useStyles();
	const Icon = getIcon(action);

	return (
		<RenderConditional
			component={Tooltip}
			condition={Boolean(tooltip)}
			title={tooltip}
			wrapDisabled={props.disabled}
		>
			<IconButton
				{...((link || props.to) && { component: Link })}
				ref={ref}
				data-track-event={dataTrackEvent}
				aria-label={tooltip ?? action?.replace('_', ' ')}
				{...props}
				disabled={loading || props.disabled}
			>
				{loading && (
					<CircularProgress
						size={24}
						className={classes.loader}
					/>
				)}
				{Icon && (
					<RenderConditional
						condition={Boolean(badge)}
						component={Badge}
						badgeContent={badge}
						color="primary"
						{...BadgeProps}
					>
						<Icon {...IconProps} />
					</RenderConditional>
				)}
				{children}
			</IconButton>
		</RenderConditional>
	);
});

export default ActionIconButton;
