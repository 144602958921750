import React, { forwardRef, useMemo } from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from './TextField';

export default forwardRef(function Autocomplete(
	{
		label,
		error,
		helperText,
		autoFocus,
		variant,
		placeholder,
		startAdornment,
		disabled,
		ListBoxProps: ListBoxPropsFromProps = {},
		'data-test-element': dataTestElement = '',
		...props
	},
	ref
) {
	const ListBoxProps = useMemo(() => {
		return {
			...ListBoxPropsFromProps,
			...(dataTestElement && { 'data-test-element': `${dataTestElement}-listbox` }),
		};
	}, [ListBoxPropsFromProps, dataTestElement]);

	return (
		<MuiAutocomplete
			ref={ref}
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					error={error}
					helperText={helperText}
					autoFocus={autoFocus}
					variant={variant}
					placeholder={placeholder}
					InputProps={{
						...params.InputProps,
						...(startAdornment && { startAdornment }),
					}}
					disabled={disabled}
				/>
			)}
			openOnFocus={autoFocus}
			ListboxProps={ListBoxProps}
			data-test-element={dataTestElement}
			{...props}
		/>
	);
});
