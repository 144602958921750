import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
	helperText: {
		color: theme.palette.text.helperText,
	},
}));

export default function ListItemWithRightContent({
	primary,
	secondary,
	onClick,
	ListItemProps = {},
	GridProps = {},
	textGridItemProps = {},
	actionGridItemProps = {},
	ListItemTextProps = {},
	lessenTextOpacity,
	action,
	icon,
	...props
}) {
	const classes = useStyles();

	const ListItemPropsExtended = {
		...(typeof onClick === 'function' && {
			onClick,
			button: true,
		}),
		...ListItemProps,
		...props,
	};

	const ListItemTextPropsExtended = {
		primary,
		secondary,
		...ListItemTextProps,
		...(lessenTextOpacity && {
			primaryTypographyProps: {
				color: 'textSecondary',
			},
			secondaryTypographyProps: {
				className: classes.helperText,
			},
		}),
	};

	return (
		<ListItem {...ListItemPropsExtended}>
			{icon && <ListItemIcon>{icon}</ListItemIcon>}
			<Grid
				container
				alignItems="center"
				{...GridProps}
			>
				<Grid
					item
					xs
					{...textGridItemProps}
				>
					<ListItemText {...ListItemTextPropsExtended} />
				</Grid>
				<Grid
					item
					{...actionGridItemProps}
				>
					{action}
				</Grid>
			</Grid>
		</ListItem>
	);
}
