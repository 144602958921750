import React, { useState, useEffect } from 'react';
import { useAppContext } from '@/components/AppContext';
import { makeStyles } from '@/styles';
import { tryParse } from '@/utils';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			margin: 0,
			padding: '0!important',
		},
	},
	boxShadow: {
		boxShadow:
			'0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
	},
	sdkHeader: {
		'& .mop-conversation-container': {
			'&:before': {
				content: "''",
				display: 'block',
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: 20,
				background: '#eee',
				zIndex: 9997,
			},
			'&:after': {
				content: "''",
				display: 'block',
				position: 'fixed',
				bottom: 0,
				left: 0,
				width: '100%',
				height: '80%',
				background: props => props.formBackgroundColor,
				zIndex: -1,
			},
		},
		'& .mopinion-survey-content': {
			'&:before': {
				content: "''",
				display: 'block',
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: 20,
				background: '#eee',
				zIndex: 9997,
			},
			'&:after': {
				content: "''",
				display: 'block',
				position: 'fixed',
				bottom: 0,
				left: 0,
				width: '100%',
				height: '95%',
				background: props => props.formBackgroundColor,
				zIndex: -1,
			},
		},
		'& .mopinion-survey-content[data-mop-form-type="embed"] .mopinion-survey-output': {
			paddingTop: props => props.sdkHeaderHeight,
			borderTopLeftRadius: props => `${props.sdkBorderRadius}px!important`,
			borderTopRightRadius: props => `${props.sdkBorderRadius}px!important`,
		},
		'& .mopinion-survey-content[data-mop-form-type="embed"] .language-toggle-wrap': {
			top: props => props.sdkHeaderHeight,
		},
		'& .mopinion-survey-content[data-mop-form-type="embed"] #surveyHead': {
			position: 'fixed!important',
			top: 0,
			width: '100%',
			zIndex: 10000,
			fontSize: '.7em',
			borderTopLeftRadius: props => `${props.sdkBorderRadius}px!important`,
			borderTopRightRadius: props => `${props.sdkBorderRadius}px!important`,
			'& img': {
				display: 'none',
			},
		},
		'& .mopinion-survey-content[data-mop-form-type="embed"] #surveyTitle': {
			paddingLeft: '30px;',
			'&:before': {
				content: "''",
				height: '20px',
				width: '2px',
				background: '#fff',
				transform: 'rotate(45deg)',
				display: 'block',
				position: 'absolute',
				top: theme.spacing(2.5),
				left: theme.spacing(3),
			},
			'&:after': {
				content: "''",
				height: '20px',
				width: '2px',
				background: '#fff',
				transform: 'rotate(135deg)',
				display: 'block',
				position: 'absolute',
				top: theme.spacing(2.5),
				left: theme.spacing(3),
			},
		},
	},
	forceEmbedOpen: {
		/* force open with css, else weird render issues happen when switching display type */
		'& .mopinion-survey-content[data-mop-form-type="embed"]': {
			display: 'block!important',
		},
	},
}));

export default function RenderSurvey({
	surveySettings,
	contentWindow,
	scriptLoaded,
	selectedLanguage,
	setScriptLoaded = () => {},
}) {
	const [sdkHeaderHeight, setSdkHeaderHeight] = useState(70);
	const [formBackgroundColor, setFormBackgroundColor] = useState('#ffffff');
	const sdkBorderRadius = 10;

	const { app } = useAppContext();
	const classes = useStyles({ sdkHeaderHeight, sdkBorderRadius, formBackgroundColor });

	const {
		dataLoaded,
		formType,
		buttonSettings,
		buttonSettingsMobile,
		surveyKey,
		campaign,
		survey,
	} = surveySettings;

	const checkSdkHeaderHeight = () => {
		let counter = 0;

		const applyHeight = setInterval(() => {
			const headerOffset =
				contentWindow.document.getElementById('surveyHead')?.offsetHeight ?? 0;

			const progressOffset =
				contentWindow.document.getElementById('progress')?.offsetHeight ?? 0;

			if (headerOffset || progressOffset) {
				setSdkHeaderHeight(headerOffset + progressOffset);
				clearInterval(applyHeight);
			} else if (counter > 50) {
				clearInterval(applyHeight);
			} else {
				counter++;
			}
		}, 100);
	};

	const runSurvey = () => {
		const vars = {
			key: surveyKey,
			domain: app.domain,
			divName: 'surveyContent_preview',
			trigger_method: 'passive',
			forceHeader: ['sdk', 'email'].includes(campaign),
			forceType: ['sdk', 'email'].includes(campaign) ? 'embed' : formType,
			forceBreakPoint: 360,
			forceButton: {
				position: buttonSettings?.position,
				position_mobile: buttonSettingsMobile?.position,
				buttonStyleWeb: buttonSettings?.style,
				buttonStyleMobile: buttonSettingsMobile?.style,
			},
			webView: ['sdk'].includes(campaign),
		};
		//use timeout to prevent weird bug where survey is loaded with wrong settings
		setTimeout(() => {
			if (campaign === 'sdk') {
				//fake the correct title & body message for sdk forms
				const postSubmit = tryParse(survey.post_submit);
				const surveyText = tryParse(survey.text);
				if (postSubmit?.exit_page_content && postSubmit?.thanks_page?.elements) {
					const sdkThanksElements = Object.values(postSubmit.thanks_page.elements).reduce(
						(acc, cur, index) => {
							const element =
								index === 0
									? {
											align: 'center',
											hide_on_init: false,
											type: 'text',
											value: postSubmit.exit_page_content,
									  }
									: {
											type: '',
									  };
							acc[index + 1] = element;
							return acc;
						},
						{}
					);

					contentWindow.srv.setProperties(surveyKey, {
						thanksPage: {
							title: surveyText?.lastPageTitle ?? postSubmit?.thanks_page?.title,
							elements: sdkThanksElements,
						},
					});
				}
			}
			contentWindow.srv.open(vars);
		}, 100);
	};

	useEffect(() => {
		if (campaign === 'sdk') {
			try {
				contentWindow.document.addEventListener('mopinion_loaded', e => {
					checkSdkHeaderHeight();

					const formCssProps = getComputedStyle(
						contentWindow.document.getElementsByClassName('mopinion-survey-output')?.[0]
					);

					const formBackground = formCssProps.getPropertyValue('background-color');
					setFormBackgroundColor(formBackground);
				});

				contentWindow.document.addEventListener('mopinion_language_changed', () => {
					checkSdkHeaderHeight();
				});
			} catch (e) {}
		}
	}, []);

	useEffect(() => {
		if (selectedLanguage) {
			try {
				contentWindow.srv.setLanguage(surveyKey, selectedLanguage);
				if (campaign === 'sdk') {
					runSurvey();
					checkSdkHeaderHeight();
				}
			} catch (x) {}
		}
	}, [selectedLanguage, surveyKey, campaign]);

	useEffect(() => {
		if (!app.surveyJsUrl) {
			return;
		}
		if (contentWindow.document.srv) {
			return;
		}

		const script = document.createElement('script');
		script.src = app.surveyJsUrl;

		script.id = 'surveyScript';
		script.async = true;
		script.onload = () => setScriptLoaded(true);

		contentWindow.document.head.appendChild(script);
	}, [contentWindow.document, app.surveyJsUrl]);

	useEffect(() => {
		if (
			dataLoaded &&
			scriptLoaded &&
			buttonSettings &&
			buttonSettingsMobile &&
			formType
		) {
			runSurvey();
		}
	}, [dataLoaded, scriptLoaded, buttonSettings, buttonSettingsMobile, formType]);

	return (
		<div
			id="surveyContent_preview"
			className={`${campaign === 'web' ? classes.forceEmbedOpen : ''} 
          ${campaign === 'sdk' ? classes.sdkHeader : ''}
          ${campaign === 'email' ? classes.boxShadow : ''}`}
		/>
	);
}
