import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { IntroductionTour } from '@/components/Onboarding';
import { Typography } from '@/components/Layout';

export default function DeploymentAddFormIntroductionTour() {
	const steps = useMemo(() => {
		return [
			{
				target: '[data-onboarding="add-form-fab"]',
				placement: 'bottom',
				title: (
					<Trans i18nKey="data_collection-deployment_editor-add_form_introduction_tour-step1-title" />
				),
				content: (
					<Trans
						i18nKey="data_collection-deployment_editor-add_form_introduction_tour-step1-content"
						components={{
							strong: <strong />,
							row: (
								<Typography
									display="block"
									variant="body2"
									mb={2}
								/>
							),
						}}
					/>
				),
				dataTrackEvent: 'deployment_editor_add_form_introduction_tour-step1',
				disableOverlay: false,
				disableScrolling: true,
			},
			{
				target: '[data-onboarding="deployment-sidebar"]',
				placement: 'left',
				title: (
					<Trans i18nKey="data_collection-deployment_editor-add_form_introduction_tour-step2-title" />
				),
				content: (
					<Trans i18nKey="data_collection-deployment_editor-add_form_introduction_tour-step2-content" />
				),
				dataTrackEvent: 'deployment_editor_add_form_introduction_tour-step2',
				disableOverlay: false,
				disableScrolling: true,
			},
		];
	}, []);

	return (
		<IntroductionTour
			seenKey="seen_deployment_editor_add_form_introduction_tour"
			steps={steps}
			disableScrolling
		/>
	);
}
