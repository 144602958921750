import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@/styles';
import {
	ActionButton,
	ActionIconButton,
	Box,
	Typography,
	Popover,
	Collapse,
	Divider,
} from '@/components/Layout';
import { UserAvatar, UserName } from '@/components/Users';
import { CollapsedSummary, ConditionList } from './DeploymentEditorItem';
import { useDeploymentStore } from './DeploymentStore';

const useStyles = makeStyles(theme => ({
	addCopiedConditionMenu: {
		height: 600,
		width: 600,
		overflowY: 'auto',
	},
}));

export default function AddConditionPopover({ open, anchorEl, onClose, editorType }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [deleteConditionLoading, setDeleteConditionLoading] = useState({});

	const selectedRuleId = useDeploymentStore(state => state.temporaryRule?.id);
	const savedConditions = useDeploymentStore(state => state.savedConditions);
	const openConditions = useDeploymentStore(state => state.openConditions);
	const { addCondition, deleteCondition, toggleOpenConditions, addSavedCondition } =
		useDeploymentStore(state => state.actions);

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			PaperProps={{
				className: classes.addCopiedConditionMenu,
			}}
		>
			<Box
				px={2}
				pt={2}
			>
				<Typography
					variant="h6"
					gutterBottom
				>
					{t`data_collection-deployment_editor_rule_drawer-add_condition_popover-title`}
				</Typography>
				<Typography
					variant="body1"
					color="textSecondary"
				>
					{t`data_collection-deployment_editor_rule_drawer-add_condition_popover-description`}
				</Typography>
			</Box>
			<Box
				p={2}
				pt={3}
				pb={3}
			>
				<ActionButton
					action="add"
					onClick={() => {
						addCondition({
							ruleId: selectedRuleId,
							editorType,
						});
						enqueueSnackbar(
							t`data_collection-deployment_editor_rule_drawer-add_condition_popover-snackbar-add_new_condition`
						);
						onClose();
					}}
					label={t`data_collection-deployment_editor_rule_drawer-add_condition_popover-add_new_condition`}
					fullWidth={false}
				/>
			</Box>
			<Divider />
			<Box py={3}>
				<Typography
					mb={1}
					ml={2}
					variant="subtitle"
				>
					{t`data_collection-deployment_editor_rule_drawer-add_condition_popover-saved_conditions`}
				</Typography>
				{savedConditions.map((savedCondition, index) => {
					const {
						id,
						name,
						user_uuid: userUuid,
						condition_set: conditionSet,
					} = savedCondition;

					return (
						<Box
							px={2}
							py={2}
							key={index}
						>
							<Box
								display="flex"
								alignItems="center"
							>
								<Box flexGrow={1}>
									<Typography
										gutterBottom
										variant="subtitle2"
									>
										{name}
									</Typography>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="flex-start"
										my={1}
									>
										<Box>
											<Box
												display="flex"
												alignItems="center"
											>
												<Box mr={1}>
													<UserAvatar
														userUuid={userUuid}
														size="xsmall"
													/>
												</Box>
												<UserName
													userUuid={userUuid}
													fontWeight={400}
												/>
											</Box>
										</Box>
										{!openConditions.has(id) && (
											<>
												<Box
													mx={1}
													color="text.secondary"
												>
													|
												</Box>
												<CollapsedSummary conditionSet={conditionSet} />
											</>
										)}
									</Box>
								</Box>
								<Box>
									<ActionButton
										action="add"
										variant="text"
										size="small"
										onClick={() => {
											addSavedCondition({ conditionSet });
											enqueueSnackbar(
												t(
													`data_collection-deployment_editor_rule_drawer-add_condition_popover-snackbar-add_saved_condition`,
													{
														name,
													}
												)
											);
											onClose();
										}}
									>
										{t`data_collection-deployment_editor_rule_drawer-add_condition_popover-add_condition_set`}
									</ActionButton>
									<ActionIconButton
										action={openConditions.has(id) ? 'expand_less' : 'expand_more'}
										tooltip={
											openConditions.has(id)
												? t`data_collection-deployment_editor_rule_drawer-collapse`
												: t`data_collection-deployment_editor_rule_drawer-expand`
										}
										onClick={() => {
											toggleOpenConditions(id);
										}}
									/>
									<ActionIconButton
										action="delete"
										tooltip={t`data_collection-deployment_editor_rule_drawer-remove_condition_set`}
										loading={deleteConditionLoading[id]}
										onClick={() => {
											setDeleteConditionLoading(prev => ({
												...prev,
												[id]: true,
											}));
											deleteCondition({
												id,
												onSuccess: () => {
													enqueueSnackbar(
														t`data_collection-deployment_editor_rule_drawer-remove_condition_set-snackbar`
													);
													setDeleteConditionLoading(prev => ({
														...prev,
														[id]: false,
													}));
												},
											});
										}}
									/>
								</Box>
							</Box>
							<Collapse in={openConditions.has(id)}>
								<Box>
									<ConditionList
										conditions={conditionSet}
										ruleId={selectedRuleId}
										conditionIndex={index}
									/>
								</Box>
							</Collapse>
						</Box>
					);
				})}
			</Box>
		</Popover>
	);
}
