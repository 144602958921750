import React, { useMemo } from 'react';

import { makeStyles } from '@/styles';
import { tryParse } from '@/utils';
import { useAppContext } from '@/components/AppContext';
import { usePermissionContext } from '@/components/Permission';
import DataSourceAvatar from './DataSourceAvatar';
import { useDataSourceContext } from './DataSourceContext';
import {
	Autocomplete,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListSubheader,
} from '@/components/Layout';
import { useTranslation } from 'react-i18next';

function determineDisplayType({ survey_type, modal, trigger = '' }) {
	if (survey_type !== 'builder') {
		return null;
	}

	if (!modal) {
		return 'embed';
	}
	const parsed = tryParse(trigger);
	return parsed?.slider?.type || 'modal';
}

function determineSdkType({ campaign, survey_format, sdk_type }) {
	if (campaign === 'sdk') {
		return sdk_type === 'webview' || survey_format === 'conversational'
			? '-webview'
			: '-native';
	}
	return '';
}

const useStyles = makeStyles(theme => ({
	listbox: {
		padding: 0,
		maxHeight: '60vh',
	},
	subheader: {
		backgroundColor: '#fff',
	},
}));

export default function DataSourceAutocomplete({
	options: optionsFromProps,
	value,
	onChange,
	getByProp = 'survey_key',
	multiple,
	placeholder: placeholderFromProps,
	showValueAsAdornment,
	...props
}) {
	const classes = useStyles();
	const { datasource } = useDataSourceContext();
	const { projects } = usePermissionContext();
	const { app } = useAppContext();
	const { t } = useTranslation();

	const options = optionsFromProps ?? datasource.forms.asArray ?? [];

	const sortedFilteredOptions = useMemo(
		() =>
			[...options]
				.filter(option => {
					const project = app.projects.byKey[option.project_id];
					return projects?.includes(project?.uuid);
				})
				.sort((a, b) => {
					if (a.project_id < b.project_id) {
						return -1;
					}
					if (a.project_id > b.project_id) {
						return 1;
					}
					return 0;
				}),
		[options, projects, app.projects.byKey]
	);

	const selected = options.filter(option => {
		if (multiple) {
			return value?.includes(option?.[getByProp]);
		}
		return option?.[getByProp] === value;
	});

	const placeholder =
		placeholderFromProps ?? t`app_general-datasource_autocomplete-placeholder`;
	return (
		<Autocomplete
			value={multiple ? selected : selected[0]}
			autoHighlight
			multiple={multiple}
			options={sortedFilteredOptions}
			onChange={onChange}
			groupBy={option => option.project_id}
			classes={{
				listbox: classes.listbox,
			}}
			filterOptions={(options, { inputValue }) =>
				options.filter(option =>
					[
						option?.name,
						option?.survey_key,
						option?.id,
						app.projects.byKey[option.project_id]?.name,
						option?.campaign,
						...(option?.campaign === 'sdk' ? ['app', 'in-app'] : []),
						option?.survey_format,
						option?.sdk_type,
						...(option?.campaign === 'sdk' && !option?.sdk_type ? ['native'] : []),
						determineDisplayType(option),
					].some(value => {
						if (!value || typeof value === 'object') {
							return false;
						}
						return String(value).toLowerCase().includes(inputValue?.toLowerCase());
					})
				)
			}
			renderGroup={params => [
				<ListSubheader
					key={params.key}
					className={classes.subheader}
				>
					{app.projects.byKey[params.group]?.name}
				</ListSubheader>,
				params.children,
			]}
			getOptionLabel={option => option?.name}
			renderOption={option => {
				const displayType = determineDisplayType(option);
				const sdkType = determineSdkType(option);
				return (
					<ListItem disableGutters>
						<ListItemAvatar>
							<DataSourceAvatar
								survey_type={option.survey_type}
								survey_format={option.survey_format}
								campaign={option.campaign}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={option.name}
							secondary={t(
								`app_general-datasource_item-display_type${sdkType}-${displayType}`
							)}
						/>
					</ListItem>
				);
			}}
			placeholder={placeholder}
			data-test-element="datasource-autocomplete"
			{...props}
		/>
	);
}
