import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@/styles';
import { SimpleDialog, TextField, Typography } from '@/components/Layout';
import { useDeploymentStore } from './DeploymentStore';

const useStyles = makeStyles(theme => ({
	content: {
		'& > *': {
			marginBottom: theme.spacing(2),
		},
	},
	nameInput: {
		marginTop: theme.spacing(2),
	},
}));

export default function SaveConditionDialog({ open, onClose, conditionSet }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const { saveCondition } = useDeploymentStore(store => store.actions);

	const handleSubmit = () => {
		setLoading(true);
		saveCondition({
			conditionSet,
			name,
			onSuccess: () => {
				enqueueSnackbar(
					t`data_collection-deployment_editor_rule_drawer-condition_sets-save_success`
				);
				setLoading(false);
				handleClose();
			},
		});
	};

	const handleClose = () => {
		setName('');
		onClose();
	};

	const isValid = name.length >= 3;

	return (
		<SimpleDialog
			open={open}
			onClose={handleClose}
			title={t`data_collection-deployment_editor_rule_drawer-condition_sets-save_dialog-title`}
			dataTrackEvent="deployment_editor_save_condition_set"
			content={
				<div className={classes.content}>
					<Typography>
						{t`data_collection-deployment_editor_rule_drawer-condition_sets-save_dialog-description`}
					</Typography>
					<TextField
						className={classes.nameInput}
						fullWidth
						autoFocus
						label={t`data_collection-deployment_editor_rule_drawer-condition_sets-save_dialog-name_label`}
						placeholder={t`data_collection-deployment_editor_rule_drawer-condition_sets-save_dialog-name_placeholder`}
						value={name}
						onChange={e => setName(e.target.value)}
						error={name.length > 0 && !isValid}
						helperText={
							name.length > 0 && !isValid
								? t`data_collection-deployment_editor_rule_drawer-condition_sets-save_dialog-name_error`
								: ''
						}
					/>
				</div>
			}
			submit={t`data_collection-deployment_editor_rule_drawer-condition_sets-save_dialog-save`}
			cancel={t`data_collection-deployment_editor_rule_drawer-condition_sets-save_dialog-cancel`}
			onSubmit={handleSubmit}
			disabled={!isValid}
			loading={loading}
		/>
	);
}
