import React from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { Typography, Autocomplete, InputAdornment } from '@/components/Layout';
import { FilterGroup } from '@/components/FilteredLists';
import { DataSourceAutocomplete } from '@/components/DataSources';
import { useDeploymentStore } from './DeploymentStore';
import DeploymentIcon from './DeploymentIcon';

const useStyles = makeStyles(theme => ({
	filterGroupWrap: {
		padding: theme.spacing(1),
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
		'& > div': {
			maxWidth: '100%',
		},
	},
}));

export default function DeploymentEditorFilterSidebar({ editorType }) {
	const classes = useStyles();
	const autocompleteSurveyOptions = useDeploymentStore(store =>
		store.surveysByProject.flatMap(project => project[`${editorType}_surveys`])
	);

	const autocompleteLocationOptions = useDeploymentStore(store =>
		(store.workingDraft.rules ?? []).flatMap(rule =>
			rule.if.flatMap(
				condition =>
					condition.location?.flatMap(
						location => location?.map(locationOr => locationOr?.value) ?? []
					) ?? []
			)
		)
	);

	const filters = useDeploymentStore(store => store.filters);
	const { setFilter } = useDeploymentStore(store => store.actions);

	const { t } = useTranslation();

	return (
		<div className={classes.filterGroupWrap}>
			<div>
				<Typography variant="h6">
					{t`app_general-filtered_list-sidebar-header`}
				</Typography>
			</div>
			<div>
				<FilterGroup noCollapse>
					<DataSourceAutocomplete
						label={t`data_collection-deployment_editor_filter_sidebar-datasource_filter-label`}
						placeholder={t`data_collection-deployment_editor_filter_sidebar-datasource_filter-placeholder`}
						variant="filled"
						value={filters?.surveys ?? []}
						multiple
						onChange={(e, value) => {
							setFilter({
								filter: 'surveys',
								value: value.map(survey => survey.survey_key),
							});
						}}
						options={autocompleteSurveyOptions}
					/>
				</FilterGroup>
			</div>
			{autocompleteLocationOptions.length > 0 && (
				<div>
					<FilterGroup noCollapse>
						<Autocomplete
							label={t`data_collection-deployment_editor_filter_sidebar-location_filter-label`}
							placeholder={t`data_collection-deployment_editor_filter_sidebar-location_filter-placeholder`}
							value={filters?.location ?? ''}
							multiple={false}
							options={autocompleteLocationOptions}
							onInputChange={(e, value) => setFilter({ filter: 'location', value })}
							freeSolo
							variant="filled"
							startAdornment={
								<InputAdornment position="start">
									<DeploymentIcon icon="location" />
								</InputAdornment>
							}
						/>
					</FilterGroup>
				</div>
			)}
			<div>
				<FilterGroup
					noCollapse
					filterName={t`data_collection-deployment_editor_filter_sidebar-trigger_filter-label`}
					filterKey="trigger"
					multiple
					filters={[
						{
							label: t`data_collection-deployment_editor_filter_sidebar-trigger_filter-passive`,
							value: 'passive',
						},
						{
							label: t`data_collection-deployment_editor_filter_sidebar-trigger_filter-proactive`,
							value: 'proactive',
						},
						{
							label: t`data_collection-deployment_editor_filter_sidebar-trigger_filter-exit`,
							value: 'exit',
						},
					].map(filter => {
						if (filters.trigger?.includes(filter.value)) {
							filter.selected = true;
						}
						return filter;
					})}
					onChange={({ key, value }) => setFilter({ filter: key, value })}
				/>
			</div>

			<div>
				<FilterGroup
					noCollapse
					filterName={t`data_collection-deployment_editor_filter_sidebar-pause_filter-label`}
					filterKey="pause"
					filters={[
						{
							label: t`data_collection-deployment_editor_filter_sidebar-pause_filter-active`,
							value: 'active',
						},
						{
							label: t`data_collection-deployment_editor_filter_sidebar-pause_filter-paused`,
							value: 'paused',
						},
					].map(filter => {
						if (filters.pause === filter.value) {
							filter.selected = true;
						}
						return filter;
					})}
					onChange={({ key, value }) => setFilter({ filter: key, value })}
				/>
			</div>
		</div>
	);
}
