import React from 'react';

import { useTranslation } from 'react-i18next';

import { useListFilterContext, FilterGroup } from '@/components/FilteredLists';
import { SearchField } from '@/components/Layout';
import { DataSourceAutocomplete } from '@/components/DataSources';
import { UserAutocomplete } from '@/components/Users';

export default function DeploymentListFilters({ datasources = [] }) {
	const { setSingleFilter, setMultiFilter, filters } = useListFilterContext();
	const { t } = useTranslation();

	return (
		<>
			<div>
				<SearchField
					variant="filled"
					fullWidth
					onChange={e => setSingleFilter('search', e.target.value)}
					value={filters.search ?? ''}
				/>
			</div>

			<FilterGroup noCollapse>
				<DataSourceAutocomplete
					label={t('data_collection-deployment_list_filters-datasource')}
					options={datasources}
					variant="filled"
					value={filters.datasources ?? []}
					multiple
					onChange={(e, value) =>
						setMultiFilter(
							'datasources',
							value.map(datasource => datasource.survey_key)
						)
					}
				/>
			</FilterGroup>

			<FilterGroup noCollapse>
				<UserAutocomplete
					label={t('data_collection-deployment_list_filters-users')}
					variant="filled"
					value={filters.edited_by ?? []}
					multiple
					onChange={(e, value) =>
						setMultiFilter(
							'edited_by',
							value.map(user => user.uuid)
						)
					}
				/>
			</FilterGroup>

			<FilterGroup
				filterName={t('data_collection-deployment_list_filters-status')}
				filterKey="status"
				filters={[
					{
						label: t('data_collection-deployment_list_filters-status-active'),
						value: 'active',
					},
					{
						label: t('data_collection-deployment_list_filters-status-not_active'),
						value: 'not_active',
					},
				].map(filter => {
					if (filters.status === filter.value) {
						filter.selected = true;
					}
					filter.key = 'status' + filter.value;
					return filter;
				})}
				onChange={({ key, value }) => setSingleFilter(key, value)}
			/>

			<FilterGroup
				filterName={t('data_collection-deployment_list_filters-channel')}
				filterKey="channel"
				filters={[
					{
						label: t('data_collection-deployment_list_filters-channel-web'),
						value: 'web',
					},
					{
						label: t('data_collection-deployment_list_filters-channel-sdk'),
						value: 'sdk',
					},
				].map(filter => {
					if (filters.channel === filter.value) {
						filter.selected = true;
					}
					filter.key = 'channel' + filter.value;
					return filter;
				})}
				onChange={({ key, value }) => setSingleFilter(key, value)}
			/>
		</>
	);
}
