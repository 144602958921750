import { rnd, ucFirst } from '../../utils';
import { format } from '../../utils/date';

export function makeDeploymentCode(id = '') {
	return `<!-- Mopinion Pastea.se  start -->
	<script type="text/javascript">(function(){var id="${id}";var js=document.createElement("script");js.setAttribute("type","text/javascript");js.setAttribute("src","https://deploy.mopinion.com/js/pastease.js");js.async=true;document.getElementsByTagName("head")[0].appendChild(js);var t=setInterval(function(){try{Pastease.load(id);clearInterval(t)}catch(e){}},50)})();</script>
	<!-- Mopinion Pastea.se end -->`;
}

export function operators(type) {
	switch (type) {
		case 'location':
		case 'referrer':
			return [
				{ value: 'exists', label: 'URL contains' },
				{ value: 'does not exist', label: 'URL does not contain' },
				{ value: 'matches exactly', label: 'URL matches exactly' },
			];

		case 'cookie':
		case 'css_selector':
			return [
				{ label: 'exists', value: 'exists' },
				{ label: 'does not exist', value: 'does not exist' },
				{ label: 'contains', value: 'contains' },
				{ label: 'matches exactly', value: 'matches exactly' },
				{ label: 'does not contain', value: 'does not contain' },
			];

		case 'js':
			return [
				{ label: 'exists', value: 'exists' },
				{ label: 'does not exist', value: 'does not exist' },
				{ label: 'contains', value: 'contains' },
				{ label: 'matches exactly', value: 'matches exactly' },
				{ label: 'does not contain', value: 'does not contain' },
				{ label: 'is boolean', value: 'is boolean' },
			];

		case 'clock':
		case 'date':
			return [
				{ label: 'before', value: 'earlier' },
				{ label: 'exactly', value: 'exactly' },
				{ label: 'after', value: 'later' },
				{ label: 'between', value: 'between' },
			];

		case 'type':
			return [
				{ label: 'new', value: 'new' },
				{ label: 'returning', value: 'returning' },
			];

		case 'number_of_pages':
			return [
				{ label: 'at least', value: 'min' },
				{ label: 'at most', value: 'max' },
			];
		case 'scrollpos':
			return [
				{ label: 'top', value: 'top' },
				{ label: 'bottom', value: 'bottom' },
			];

		case 'form_events':
		case 'event_listeners':
			return [
				{ label: 'has', value: 'contains' },
				{ label: 'has not', value: 'does not contain' },
			];
		default:
			return [];
	}
}

export function eventOptions(type) {
	switch (type) {
		case 'form_events':
			return [
				{ label: 'been shown', value: 'shown' },
				{ label: 'been closed', value: 'hidden' },
				{ label: 'been completed', value: 'feedback_sent' },
			];

		case 'event_listeners':
			return [
				{ label: 'been clicked', value: 'click' },
				{ label: 'recieved input', value: 'input' },
				{ label: 'been hovered', value: 'mouseover' },
			];

		default:
			return [];
	}
}

export function innerConditionBase(type) {
	switch (type) {
		case 'location':
		case 'referrer':
			return { value: '', operator: 'exists', regex: false };

		case 'js':
		case 'cookie':
		case 'css_selector':
			return { name: '', value: '', operator: 'exists' };

		case 'date':
			return { operator: 'earlier', date: format(new Date(), 'dd/MM/yyyy'), date2: '' };

		case 'clock':
			return { operator: 'earlier', time: '12:00', time2: '' };

		case 'scrollpos':
			return { from: 'top', px: 0 };

		case 'number_of_pages':
			return { check: 'min', amount: 1 };

		case 'event_listeners':
			return {
				selector: '',
				event: 'click',
				operator: 'contains',
			};

		case 'form_events':
			return {
				formKey: '',
				event: 'shown',
				operator: 'contains',
			};

		default:
			return {};
	}
}

export function ruleBase({
	surveyKey,
	domain,
	editorType = 'web',
	sdkType = 'native',
	surveyFormat = 'form',
	surveyType = 'web',
	webview = false,
}) {
	const rule = {
		id: rnd(),
		if: [conditionBase(editorType)],
		then: [
			{
				service: 'mopinion',
				args: [surveyKey, domain, 'surveyContent'],
				...(editorType === 'sdk'
					? {
							webview:
								webview || surveyType === 'web' || surveyFormat === 'conversational',
					  }
					: {
							disableMakeDiv: false,
							useQuerySelectorAll: false,
					  }),
			},
		],
	};
	return rule;
}

export function conditionBase(editorType) {
	return editorType === 'sdk'
		? {
				trigger: 'passive',
				mobile: {
					events: ['_button'],
					target: [],
				},
				percentage: null,
				time: 0,
				date: {},
				clock: {},
				session: 30,
		  }
		: {
				trigger: 'passive',
				percentage: null,
				time: null,
				time_after_valid: null,
				time_in_session: null,
				location: [],
				js: [],
				cookie: [],
				referrer: [],
				scrollpos: {},
				number_of_pages: {},
				type: null,
				devices: [],
				session: 30,
				css_selector: [],
				date: {},
				clock: {},
				form_events: [],
				event_listeners: [],
		  };
}

export function valueLabel(value, key) {
	switch (value) {
		case 'passive':
			return 'by button';
		case 'exit':
			return 'on page leave';
		case 'proactive':
			return 'proactively';
	}

	switch (key) {
		case 'percentage':
			return `% of users`;
	}
}

export function triggerLabelSDK(trigger) {
	switch (trigger) {
		case 'passive':
			return 'open on every event';
		case 'proactive':
			return 'open once per event';
	}
}

export function innerArrayConditionLabel(arr) {
	return arr.flatMap((innerArr, arrIndex) => {
		return Array.isArray(innerArr) && innerArr.length > 0
			? [
					...(arrIndex !== 0 && arrIndex !== arr.length ? ['or'] : []),

					...innerArr.flatMap((obj, innerArrIndex) => {
						return [
							...(innerArrIndex !== 0 && innerArrIndex !== innerArr.length
								? ['and']
								: []),
							...(obj.name ? [obj.name] : []),
							obj.operator,
							obj.value,
						];
					}),
			  ]
			: [];
	});
}

export function hasDeploymentValue(value) {
	if (!value) return value;

	if (typeof value === 'string') {
		return value.length > 0;
	}

	if (typeof value === 'number') {
		return !isNaN(value);
	}

	if (typeof value === 'object') {
		if (Array.isArray(value)) {
			return value.length > 0;
		} else {
			return Object.values(value).some(v => v);
		}
	}
}

export function isMultipleCssSelector(str) {
	try {
		const [firstSelector] = String(str).split(' ');

		if (firstSelector.charAt(0).match(/[a-zA-Z]/g)) {
			return (
				document.createElement(firstSelector).toString() !== '[object HTMLUnknownElement]'
			);
		} else if (firstSelector.charAt(0).match(/[.\[]/g)) {
			return true;
		}

		return false;
	} catch (e) {
		return false;
	}
}

function getFormKeyFromRule(rule) {
	return rule?.then?.[0]?.args?.[0];
}

function rulesContainFormKey(rules = [], formKey) {
	return rules.some(rule => {
		return getFormKeyFromRule(rule) === formKey;
	});
}

function mapMissingFormKeys(rulesA = [], rulesB = []) {
	return rulesA
		.map(rule => getFormKeyFromRule(rule))
		.filter(formKey => !rulesContainFormKey(rulesB, formKey));
}

function mapChangedRules(rulesA = [], rulesB = [], addedForms = []) {
	return rulesA
		.filter(rule => {
			const matchingRule = rulesB.find(ruleB => rule.id === ruleB.id);
			//we only want to map rules that still exist
			if (!matchingRule) {
				return true;
			}

			return JSON.stringify(rule) !== JSON.stringify(matchingRule);
		})
		.map(rule => getFormKeyFromRule(rule))
		.filter(formKey => !addedForms.includes(formKey));
}

export function diffDeployRules(newRules, oldRules) {
	const added = mapMissingFormKeys(newRules, oldRules);
	const removed = mapMissingFormKeys(oldRules, newRules);
	const changed = mapChangedRules(newRules, oldRules, added);

	return {
		added,
		removed,
		changed,
	};
}

export function parseDeploymentResponse(deployment = {}) {
	const normalizeConditionsToOrAnd = [
		'location',
		'referrer',
		'cookie',
		'js',
		'css_selector',
	];
	return {
		...deployment,
		rules: deployment.rules
			? deployment.rules.map(rule => {
					return {
						...rule,
						if: rule.if.map(ruleIf => {
							const newIf = Object.entries(ruleIf).reduce((acc, [key, value]) => {
								//check if deployment contains old setups with location, referrer, cookies, js vars and html content
								//old setup is an array of objects or strings
								//new setup is an array of arrays with objects
								if (normalizeConditionsToOrAnd.includes(key) && Array.isArray(value)) {
									acc[key] = value.map(condition => {
										if (typeof condition === 'string') {
											const base = innerConditionBase(key);
											return [
												{
													...base,
													value: condition,
												},
											];
										}
										if (condition?.value) {
											const base = innerConditionBase(key);
											return [
												{
													...base,
													...condition,
												},
											];
										}
										return condition;
									});
									return acc;
								}
								acc[key] = value;
								return acc;
							}, {});
							return newIf;
						}),
					};
			  })
			: [],
	};
}
