import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

import ListItemWithRightContent from './ListItemWithRightContent';

const useStyles = makeStyles(theme => ({
	list: {
		width: '100%',
	},
	wrap: {
		flexWrap: 'wrap',
	},
}));

export default memo(function EditorOptionList({ options = [], startDivider, ...props }) {
	const classes = useStyles();
	return (
		<List
			className={classes.list}
			disablePadding
			{...props}
		>
			{startDivider && <Divider />}
			{options.map((option, index) => {
				if (option.hasOwnProperty('show') && !option.show) return null;
				const { ListItemProps = {}, onClick = () => {} } = option;

				const className = `${option.className ? option.className : ''} ${
					ListItemProps.className ? option.ListItemProps.className : ''
				} ${option.wrap ? classes.wrap : ''}`;

				if (option.subheader) {
					return (
						<ListSubheader
							{...ListItemProps}
							{...option}
							className={className}
							key={'editor-option-' + index}
						>
							{option.subheader}
						</ListSubheader>
					);
				}

				if (option.custom) {
					return (
						<ListItem
							onClick={onClick}
							{...ListItemProps}
							{...option}
							className={className}
							key={'editor-option-' + index}
						>
							{option.custom}
						</ListItem>
					);
				}

				return (
					<ListItemWithRightContent
						onClick={onClick}
						ListItemProps={ListItemProps}
						{...option}
						className={className}
						key={'editor-option-' + index}
					/>
				);
			})}
		</List>
	);
});
