import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import HelpIcon from '@material-ui/icons/HelpOutline';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import FeedbackIcon from '@material-ui/icons/Feedback';

import { TrialIcon } from '../Icons';
import RenderConditional from './RenderConditional';

const useStyles = makeStyles(theme => ({
	listItem: {
		borderRadius: theme.shape.borderRadius,
		'&:hover': {
			backgroundColor: theme.palette.darkMode.action.hover,
		},
	},
	icon: {
		color: 'inherit',
	},
}));

function getIcon(name) {
	switch (name) {
		case 'help':
			return HelpIcon;
		case 'trial':
			return TrialIcon;
		case 'whats_new':
			return WhatsHotIcon;
		case 'feedback':
			return FeedbackIcon;
		default:
			return null;
	}
}

const MainMenuListItem = forwardRef(function MainMenuListItem(
	{ primary, action, className = '', tooltip, dataTrackEvent = null, ...props },
	ref
) {
	const classes = useStyles();
	const Icon = getIcon(action);

	return (
		<RenderConditional
			condition={Boolean(tooltip)}
			component={Tooltip}
			title={tooltip}
		>
			<ListItem
				button
				ref={ref}
				className={`${classes.listItem} ${className}`}
				data-track-event={dataTrackEvent}
				{...props}
			>
				<ListItemIcon className={classes.icon}>{Icon ? <Icon /> : null}</ListItemIcon>
				<ListItemText
					primary={primary}
					primaryTypographyProps={{
						variant: 'body2',
					}}
				/>
			</ListItem>
		</RenderConditional>
	);
});

export default MainMenuListItem;
