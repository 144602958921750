import React, { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

import { PageHeader } from '@/components/App';
import {
	Box,
	Grid,
	AddCard,
	Loader,
	EmptyState,
	ActionButton,
	constants,
} from '@/components/Layout';
import {
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
	useFilteredList,
} from '@/components/FilteredLists';
import { useAppContext } from '@/components/AppContext';
import { useMjolnir, useEFM } from '@/components/Ajax';
import { UpgradeOverlay } from '@/components/Billing';
import DeploymentCard from './DeploymentCard';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

import DeploymentAddDialog from './DeploymentAddDialog';
import DeploymentListFilters from './DeploymentListFilters';
export default function DeploymentList(props) {
	const { t } = useTranslation();
	const { app } = useAppContext();

	const hasEditPermission = useHasPermission();
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [filtersOpen, setFiltersOpen] = useState(
		window.innerWidth > constants.filterDrawerBreakpoint
	);

	const [deployments, loading, error, loadData] = useMjolnir(
		`/api/1/pastease/${app.domain}/${app.organisations.current.org_id}`
	);

	const [{ datasources = [] }, loadingDatasources, errorDatasources, loadDatasources] =
		useEFM(
			'/survey/ajax/get-surveys-from-organisation',
			{},
			undefined,
			undefined,
			response => {
				const webSurveys =
					response.surveys?.flatMap(project => project.web_surveys) ?? [];
				const sdkSurveys =
					response.surveys?.flatMap(project => project.sdk_surveys) ?? [];

				return {
					datasources: [...webSurveys, ...sdkSurveys],
				};
			}
		);

	const filterState = useFilteredList({
		filters: {},
		other: {},
		sort: 'date',
		order: 'desc',
	});
	const { filters, order, hasFilters } = filterState;

	const filteredAndSortedDeployments = useMemo(() => {
		const filteredDeployments = deployments?.deployments?.filter(deployment => {
			const searchFilter = filters.search
				? deployment.name.toLowerCase().includes(filters.search.toLowerCase())
				: true;

			const statusFilter = !filters.status
				? true
				: filters.status === 'active'
				? deployment.published
				: !deployment.published;

			const datasourceFilter = filters.datasources
				? deployment.rules?.some(rule =>
						filters.datasources.includes(rule.then?.[0].args[0])
				  )
				: true;

			const editedByFilter = filters.edited_by
				? filters.edited_by.includes(deployment.user_uuid)
				: true;

			const channelFilter = filters.channel
				? filters.channel === deployment.type ||
				  (filters.channel === 'web' && !deployment.type)
				: true;

			const allFilters =
				searchFilter &&
				statusFilter &&
				datasourceFilter &&
				editedByFilter &&
				channelFilter;

			return allFilters;
		});

		const sortedDeployments = (filteredDeployments ?? []).sort((a, b) => {
			const aTime = a.datetime
				? new Date(a.datetime).getTime()
				: order === 'desc'
				? -Infinity
				: Infinity;
			const bTime = b.datetime
				? new Date(b.datetime).getTime()
				: order === 'desc'
				? -Infinity
				: Infinity;
			return order === 'desc' ? bTime - aTime : aTime - bTime;
		});

		return sortedDeployments;
	}, [deployments, filters, order]);

	return (
		<ListFilterProvider
			{...filterState}
			singleFilters={['status', 'search', 'channel']}
			multiFilters={['datasources', 'edited_by']}
			filterLabels={{
				datasources: {
					label: t('data_collection-deployment_list_filters-datasource'),
					...datasources.reduce((acc, datasource) => {
						acc[datasource.survey_key] = datasource.name;
						return acc;
					}, {}),
				},
				channel: {
					label: t('data_collection-deployment_list_filters-channel'),
					web: t('data_collection-deployment_list_filters-channel-web'),
					sdk: t('data_collection-deployment_list_filters-channel-sdk'),
				},
				edited_by: {
					label: t('data_collection-deployment_list_filters-users'),
					...app.users.asArray.reduce((acc, user) => {
						acc[user.uuid] = user.firstname + ' ' + user.lastname;
						return acc;
					}, {}),
				},
				status: {
					label: t('data_collection-deployment_list_filters-status'),
					active: t('data_collection-deployment_list_filters-status-active'),
					not_active: t('data_collection-deployment_list_filters-status-not_active'),
				},
			}}
		>
			<>
				<PageHeader title={t`data_collection-deployment_list-page_header`} />
				<ListWithSidebar
					drawerContent={
						<DeploymentListFilters
							deployments={deployments}
							datasources={datasources}
						/>
					}
					open={filtersOpen}
					onClose={() => setFiltersOpen(false)}
				>
					<ListControls
						sortOptions={[
							{
								label: t('data_collection-deployment_list_filters-sort-date-desc'),
								value: 'date-desc',
							},
							{
								label: t('data_collection-deployment_list_filters-sort-date-asc'),
								value: 'date-asc',
							},
						]}
						toggleFilterDrawer={() => setFiltersOpen(prev => !prev)}
					/>
					{loading && (
						<Loader
							empty={!deployments?.deployments}
							appBar
						/>
					)}
					{filteredAndSortedDeployments.length > 0 && (
						<Grid
							container
							spacing={3}
						>
							{!app.session.onboarding_trial && (
								<Grid
									item
									xs={6}
									sm={4}
									md={3}
								>
									<NoPermissionTooltip>
										<AddCard
											onClick={() => setAddDialogOpen(true)}
											label={t`data_collection-deployment_list-add_card`}
											maxReached={
												get(deployments, 'deployments', []).length >=
												app.package.deployments
											}
											subject={t`deployments`}
											disabled={!hasEditPermission}
											dataTestElement="deployment-list-create-new"
										/>
									</NoPermissionTooltip>
								</Grid>
							)}
							{filteredAndSortedDeployments.map(deployment => {
								const { type = 'web' } = deployment;
								return (
									<Grid
										item
										xs={6}
										sm={4}
										md={3}
										key={deployment.id}
									>
										<UpgradeOverlay
											message={t(
												`data_collection-deployment_list-deployment_card-product_not_allowed-${type}`
											)}
											needsUpgrade={
												!app.api.packageProductAllowed(deployment.type ?? 'web')
											}
											product={type}
										>
											<Box
												height="100%"
												width="100%"
												flexDirection="column"
												display="flex"
												clone
											>
												<DeploymentCard
													{...deployment}
													loadDeployments={loadData}
												/>
											</Box>
										</UpgradeOverlay>
									</Grid>
								);
							})}
						</Grid>
					)}
					{!loading && filteredAndSortedDeployments.length === 0 && (
						<EmptyState
							image={<img src={`/assets/img/r/emptystate/deployments.gif`} />}
							primary={
								hasFilters
									? t`data_collection-deployments-emptystate_filters-title`
									: t`data_collection-deployments-emptystate_default-title`
							}
							secondary={
								hasFilters
									? t`data_collection-deployments-emptystate_filters-text`
									: t`data_collection-deployments-emptystate_default-text`
							}
							action={
								hasFilters ? null : (
									<NoPermissionTooltip>
										<ActionButton
											variant="contained"
											color="primary"
											onClick={() => setAddDialogOpen(true)}
											disabled={!hasEditPermission}
											data-test-element="deployment-list-create-new"
										>
											{t`data_collection-deployments-emptystate_default-button_new_deployment`}
										</ActionButton>
									</NoPermissionTooltip>
								)
							}
						/>
					)}
				</ListWithSidebar>

				<DeploymentAddDialog
					open={addDialogOpen}
					onClose={() => {
						setAddDialogOpen(false);
						loadData();
					}}
				/>
			</>
		</ListFilterProvider>
	);
}
