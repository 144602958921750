import { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../AppContext';
import { axios, axiosInstance, CancelToken } from '@/lib/axios';
import objectToFormData from 'object-to-formdata';
import { useSessionStorage } from '@/hooks';
import { deepEqual } from '@/utils';

function useEFM(
	url,
	params = {},
	wait,
	poll,
	responseParser,
	{ onSuccess = () => {}, disableCache, checkEquality = false } = {}
) {
	const {
		app,
		app: { users, projects, organisations, date, tokens },
		cancelPending,
	} = useAppContext();

	const [data, setData] = useSessionStorage(
		url + Object.values(params).join('-'),
		{},
		true,
		disableCache
	);

	const [didInitialFetch, setDidInitialFetch] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const cancel = useRef(() => {});
	const intervalId = useRef(0);

	function loadData({ extraParams = {} } = {}) {
		setLoading(true);

		try {
			cancel.current();
		} catch (e) {}

		axiosInstance
			.post(
				url,
				objectToFormData({
					...params,
					...extraParams,
					token: tokens.csrf,
				}),
				{ cancelToken: new CancelToken(cancelToken => (cancel.current = cancelToken)) }
			)
			.then(r => {
				if (r.data.code === 204) {
					app.dispatch({
						type: 'set_logged_out',
						payload: r.data.logout_reason,
					});
					return;
				}

				setDidInitialFetch(true);
				setLoading(false);
				const parsed =
					typeof responseParser === 'function' ? responseParser(r.data) : r.data;
				if (!checkEquality || (checkEquality && !deepEqual(data, parsed))) {
					setData(parsed);
				}
				onSuccess(parsed);
			})
			.catch(e => {
				if (!axios.isCancel(e)) {
					setLoading(false);
					setError(e);
				}
			});
		// .finally(r => {

		// })
	}

	useEffect(() => {
		if (cancelPending) {
			try {
				cancel.current();
			} catch (e) {}
		}
	}, [cancelPending]);

	useEffect(() => {
		if (poll) {
			intervalId.current = setInterval(() => {
				if (!wait) {
					loadData();
				}
			}, poll);
		}
		if (!wait) {
			loadData();
		}
		return () => {
			cancel.current();
			if (intervalId.current) clearInterval(intervalId.current);
		};
	}, [
		url,
		JSON.stringify(params),
		users.current.id,
		projects.current.id,
		organisations.current.org_id,
		date.fromDate,
		date.toDate,
		app.package.name,
		wait,
		poll,
	]);

	return [data, loading, error, loadData, setData];
}

export default useEFM;
