import React, { memo, useMemo, forwardRef } from 'react';

import PausedIcon from '@material-ui/icons/Pause';

import { useTranslation, Trans } from 'react-i18next';
import { ContainerQuery } from 'react-container-query';

import { makeStyles } from '@/styles';
import { DataSourceAvatar } from '@/components/DataSources';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	OptionalDraggable,
	ActionButton,
	ActionIconButton,
	Card,
	CardContent,
	Box,
	Typography,
	Grid,
	Collapse,
	Divider,
	Skeleton,
} from '@/components/Layout';
import {
	useHasPermission,
	permissionSubjects,
	useHasProjectPermission,
} from '@/components/Permission';
import { ErrorBoundary } from '@/components/Utility';
import { tryParse } from '@/utils';
import DeploymentIcon from './DeploymentIcon';
import { hasDeploymentValue } from './deploymentUtils';
import { useDeploymentStore } from './DeploymentStore';

const useStyles = makeStyles(theme => ({
	spacer: {
		paddingBottom: theme.spacing(3),
	},
	chip: {
		margin: theme.spacing(0.25),
	},
	condition: {
		position: 'relative',
		'&:not(:first-child)': {
			marginTop: theme.spacing(2),
		},
	},
	card: props => ({
		position: 'relative',
		cursor: 'pointer',
		...(!props.hasProjectPermission && {
			opacity: 0.7,
		}),
	}),
	paused: {
		position: 'absolute',
		backgroundColor: 'rgba(255,255,255,.9)',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: 10,
	},
	deleted: {
		zIndex: 11,
	},
	extraDense: {
		paddingTop: theme.spacing(0.25),
		paddingBottom: theme.spacing(0.25),
	},
	conditionListText: {
		//	color:theme.palette.text.secondary
	},
	conditionListIcon: {
		//color:theme.palette.secondary.light
	},
	conditionSummary: {
		wordWrap: 'break-word',
	},
	alignMiddleInline: {
		verticalAlign: 'middle',
	},
	code: {
		backgroundColor: theme.palette.grey[100],
		LineHeight: 1.4,
		fontSize: '.85em',
		borderRadius: 2,
		padding: '0 3px',
	},
	emphasis: {
		fontSize: 'inherit',
	},
	and: {
		margin: theme.spacing(0, 0.5),
		textTransform: 'uppercase',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.hint,
		fontSize: theme.typography.caption.fontSize,
	},
	or: {
		marginRight: theme.spacing(0.5),
		textTransform: 'uppercase',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.hint,
		fontSize: theme.typography.caption.fontSize,
	},
	summarySpaceRight: {
		marginRight: theme.spacing(0.25),
	},
	summarySpaceLeft: {
		marginLeft: theme.spacing(0.25),
	},
	emph: {
		//backgroundColor: theme.palette.grey[100],
		// textDecoration: `underline dashed ${theme.palette.text.hint}`,
		wordWrap: 'break-word',
	},
}));

function determineDisplayType({ survey_type, modal, trigger = '' }) {
	if (survey_type !== 'builder') {
		return null;
	}

	if (!modal) {
		return 'embed';
	}
	const parsed = tryParse(trigger);
	return parsed?.slider?.type || 'modal';
}

function determineSdkFormType({ campaign, advanced, sdk_type, survey_format }) {
	if (campaign === 'sdk' || tryParse(advanced).sdk_survey) {
		return sdk_type === 'webview' || survey_format === 'conversational'
			? 'webview'
			: 'native';
	}
	return 'webview';
}

function parseSelector(selector = '') {
	if (/^[a-zA-Z]/.test(selector)) {
		return '#' + selector;
	}
	return selector;
}

const PausedOverlay = memo(() => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<div className={classes.paused}>
			<div>
				<div>
					<PausedIcon
						color="action"
						className={classes.alignMiddleInline}
					/>
					<Typography
						variant="subtitle2"
						display="inline"
						className={classes.alignMiddleInline}
						color="textSecondary"
					>
						{t`data_collection-deployment_item-form_paused_overlay-title`}
					</Typography>
				</div>
				<Typography
					variant="caption"
					color="textSecondary"
				>
					{t`data_collection-deployment_item-form_paused_overlay-text`}
				</Typography>
			</div>
		</div>
	);
});

const DeploymentEditorItem = forwardRef(function DeploymentEditorItem(
	{ selected, form = {}, rule = {}, index, loading, startOpen = false, editorType },
	ref
) {
	const { t } = useTranslation();
	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.deployments,
	});
	const hasProjectPermission = useHasProjectPermission({
		projectId: form.project_id,
	});
	const classes = useStyles({
		hasProjectPermission,
	});

	const { deleteRule, toggleRuleExpanded, setSelectedRule } = useDeploymentStore(
		store => store.actions
	);
	const isOpen = useDeploymentStore(store => store.openRules.has(rule.id));

	const displayType = determineDisplayType(form);
	const formType = determineSdkFormType(form);
	const renderingOptions = rule.then[0] ?? {};

	return (
		<div className={classes.spacer}>
			<Card
				ref={ref}
				elevation={selected ? 6 : 1}
				className={classes.card}
				data-onboarding="deployment-editor-item"
				data-test-element="deployment-editor-item"
				onClick={() => toggleRuleExpanded(rule.id)}
			>
				{Object.keys(form).length === 0 && !loading && (
					<div className={`${classes.paused} ${classes.deleted}`}>
						<div>
							<div>
								<Typography
									variant="subtitle2"
									display="inline"
									className={classes.alignMiddleInline}
									color="textSecondary"
								>{t`data_collection-deployment_item-form_removed_overlay-title`}</Typography>
							</div>
							<Typography
								variant="caption"
								color="textSecondary"
							>
								{t`data_collection-deployment_item-form_removed_overlay-text`}
							</Typography>
							<Box mt={1}>
								<ActionButton
									onClick={() =>
										deleteRule({
											index,
											ruleId: rule.id,
										})
									}
									action="delete"
									label={t`data_collection-deployment_item-form_removed_overlay-button`}
								/>
							</Box>
						</div>
					</div>
				)}
				<CardContent>
					<ContainerQuery
						query={{
							twoCol: { minWidth: 500 },
						}}
					>
						{matches => (
							<>
								<Grid
									container
									alignItems="center"
									spacing={3}
								>
									<Grid item>
										{loading ? (
											<Skeleton
												variant="circle"
												width={40}
												height={40}
											/>
										) : (
											<DataSourceAvatar
												survey_format={form.survey_format}
												survey_type={form.survey_type}
												campaign={form.campaign}
											/>
										)}
									</Grid>
									<Grid
										item
										xs
									>
										<Typography
											variant="h6"
											display="block"
										>
											{loading ? (
												<Skeleton
													variant="text"
													width={120}
													height={20}
												/>
											) : (
												form.name
											)}
										</Typography>
										<Typography
											variant="subtitle1"
											display="block"
											color="textSecondary"
										>
											{loading ? (
												<Skeleton
													variant="text"
													width={60}
													height={16}
												/>
											) : editorType === 'sdk' ? (
												t(
													`data_collection-deployment_item-display_type-${displayType}-${formType}`
												)
											) : (
												t(`data_collection-deployment_item-display_type-${displayType}`)
											)}
										</Typography>
										{!isOpen && (
											<Box mt={1}>
												{rule.if.map((conditionSet, index) => (
													<CollapsedSummary
														key={rule.id + index + 'collapsed-summary'}
														editorType={editorType}
														conditionSet={conditionSet}
														displayType={displayType}
													/>
												))}
											</Box>
										)}
									</Grid>
									<Grid item>
										{hasEditPermission && hasProjectPermission && (
											<>
												<ActionIconButton
													onClick={e => {
														e.stopPropagation();
														setSelectedRule(rule.id);
														toggleRuleExpanded(rule.id, true);
													}}
													tooltip={t`data_collection-deployment_item-edit_form-tooltip`}
													action="edit"
												/>
												<ActionIconButton
													onClick={e => {
														e.stopPropagation();
														deleteRule({
															index,
															ruleId: rule.id,
														});
													}}
													tooltip={t`data_collection-deployment_item-remove_form-tooltip`}
													data-test-element="deployment_editor_remove_deployment_button"
													action="remove_list"
												/>
											</>
										)}

										<ActionIconButton
											action={isOpen ? 'expand_less' : 'expand_more'}
											onClick={e => {
												e.stopPropagation();
												toggleRuleExpanded(rule.id);
											}}
										/>
									</Grid>
								</Grid>
								<Collapse
									in={isOpen}
									unmountOnExit
								>
									<Box mt={2}>
										<Grid
											container
											spacing={rule.if.length > 1 ? 1 : 0}
										>
											{rule.if.map((conditionSet, conditionIndex) => {
												const conditionsObject = {
													...conditionSet,
													...(editorType === 'sdk' && {
														...conditionSet.mobile,
														mobile: null,
													}),
												};

												return (
													<Grid
														item
														key={'condition' + rule.id + conditionIndex}
														xs={matches.twoCol && rule.if.length > 1 ? 6 : 12}
													>
														<div
															className={classes.condition}
															key={rule.id}
														>
															{conditionSet.pause && <PausedOverlay />}
															<Typography
																variant="subtitle2"
																color="textSecondary"
															>
																{t(
																	`data_collection-deployment_item-condition_set-label`,
																	{ number: conditionIndex + 1 }
																)}
															</Typography>
															<ConditionList
																conditions={conditionsObject}
																ruleId={rule.id}
																conditionIndex={conditionIndex}
																editorType={editorType}
																displayType={displayType}
															/>
														</div>
													</Grid>
												);
											})}
										</Grid>
									</Box>
									{editorType !== 'sdk' && (
										<Box mt={2}>
											<Box mb={2}>
												<Divider />
											</Box>
											<Box>
												<Typography
													mb={1}
													display="block"
													variant="subtitle2"
													color="textSecondary"
												>
													{t(`data_collection-deployment_item-form_placement_header`)}
												</Typography>
												<Box
													display="flex"
													alignItems="center"
												>
													<Box mr={4}>
														<DeploymentIcon
															color="action"
															type="form_placement"
														/>
													</Box>
													<Box>
														<Typography
															variant="body2"
															display="block"
														>
															<Trans
																i18nKey="data_collection-deployment_item-render_into"
																values={{
																	selector: parseSelector(renderingOptions.args?.[2]),
																}}
																components={{
																	code: <Code />,
																}}
															/>
														</Typography>
														<Typography
															variant="caption"
															color="textSecondary"
															display="block"
														>
															{renderingOptions.disableMakeDiv
																? t('data_collection-deployment_item-render_manual')
																: t(
																		'data_collection-deployment_item-render_by_deployment'
																  )}
														</Typography>
														{renderingOptions.useQuerySelectorAll && (
															<Typography
																variant="caption"
																color="textSecondary"
																display="block"
															>
																{t`data_collection-deployment_item-render_into_all_of_selector`}
															</Typography>
														)}
													</Box>
												</Box>
											</Box>
										</Box>
									)}
								</Collapse>
							</>
						)}
					</ContainerQuery>
				</CardContent>
			</Card>
		</div>
	);
});

export default memo(DeploymentEditorItem);

function Emphasis({ children, ...props }) {
	const classes = useStyles();
	return (
		<Typography
			className={classes.emphasis}
			fontWeight="medium"
			display="inline"
			color="textSecondary"
			{...props}
		>
			{children}
		</Typography>
	);
}

function Code(props) {
	const classes = useStyles();
	return (
		<Typography
			variant="inherit"
			display="inline"
			//fontWeight="medium"
			//color="textSecondary"
			className={classes.emph}
			{...props}
		/>
	);
}

/*
				type: null,
*/

function SummaryOr({ className = '' }) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<span
			className={classes.or + ' ' + className}
		>{t`data_collection-deployment_editor_item-condition_summary-or`}</span>
	);
}

function SummaryAnd() {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<span
			className={classes.and}
		>{t`data_collection-deployment_editor_item-condition_summary-and`}</span>
	);
}

export const ConditionSummary = memo(({ name, value, editorType, displayType }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const surveysByKey = useDeploymentStore(store => store.surveysByKey);

	switch (name) {
		case 'events':
			return (
				<div>
					<span className={classes.summarySpaceRight}>
						{t(
							'data_collection-deployment_editor_item-condition_summary-mobile-events-title'
						)}
					</span>
					{value?.map((event, eventIndex) => (
						<span key={eventIndex}>
							{eventIndex > 0 && <SummaryOr className={classes.summarySpaceLeft} />}
							<Trans
								i18nKey="data_collection-deployment_editor_item-condition_summary-mobile-events"
								values={{
									event,
								}}
								components={{
									code: <Code />,
									emphasis: <Emphasis />,
								}}
							/>
						</span>
					))}
				</div>
			);
		case 'target':
			return (
				<div>
					<span className={classes.summarySpaceRight}>
						{t(
							'data_collection-deployment_editor_item-condition_summary-mobile-target-title'
						)}
					</span>
					{value?.map((target, targetIndex) => (
						<span key={targetIndex}>
							{targetIndex > 0 && <SummaryOr className={classes.summarySpaceLeft} />}
							<Trans
								i18nKey={`data_collection-deployment_editor_item-condition_summary-mobile-target-${
									target.os
								}${target.version.length > 0 ? '-with_version' : ''}`}
								values={{
									versions: target.version.join(', '),
								}}
								components={{
									code: <Code />,
									emphasis: <Emphasis />,
								}}
							/>
						</span>
					))}
				</div>
			);
		case 'session':
			return (
				<Trans
					i18nKey="data_collection-deployment_editor_item-condition_summary-session"
					values={{
						value,
					}}
					components={{
						code: <Code />,
					}}
				/>
			);
		case 'trigger':
			return (
				<Trans
					i18nKey={`data_collection-deployment_editor_item-condition_summary-trigger-${value}${
						displayType === 'embed' ? '-embed' : ''
					}${editorType === 'sdk' ? '-sdk' : ''}`}
					components={{
						code: <Code />,
					}}
				/>
			);
		case 'percentage':
			return (
				<Trans
					i18nKey="data_collection-deployment_editor_item-condition_summary-percentage"
					values={{
						percentage: value,
					}}
					components={{
						emphasis: <Emphasis />,
						code: <Code />,
					}}
				/>
			);

		case 'time':
		case 'time_in_session':
		case 'time_after_valid':
			return (
				<Trans
					i18nKey={`data_collection-deployment_editor_item-condition_summary-${name}`}
					components={{
						code: <Code />,
					}}
					values={{
						value,
					}}
				/>
			);

		case 'scrollpos':
			return (
				<Trans
					i18nKey={`data_collection-deployment_editor_item-condition_summary-scrollpos-${value.from}`}
					components={{
						code: <Code />,
					}}
					values={{
						value: String(value.px).includes('%') ? value.px : value.px + 'px',
					}}
				/>
			);

		case 'date':
		case 'clock':
			return (
				<Trans
					i18nKey={`data_collection-deployment_editor_item-condition_summary-${name}-${value.operator}`}
					values={{
						...value,
					}}
					components={{
						code: <Code />,
					}}
				/>
			);

		case 'js':
		case 'cookie':
		case 'css_selector':
		case 'location':
		case 'form_events':
		case 'event_listeners':
		case 'referrer':
			return (
				<>
					{value.map((or, orIndex) => {
						return (
							<div key={name + orIndex}>
								{orIndex === 0 && (
									<span className={classes.summarySpaceRight}>
										{t(
											`data_collection-deployment_editor_item-condition_summary-${name}-title`
										)}
									</span>
								)}
								{orIndex > 0 && <SummaryOr />}
								{or?.map((and, andIndex) => {
									const operator = and.operator.replace(/\s+/g, '');

									return (
										<span key={name + orIndex + andIndex}>
											{andIndex > 0 && <SummaryAnd />}
											<Trans
												i18nKey={`data_collection-deployment_editor_item-condition_summary-${name}-${operator}`}
												values={{
													name: and.name,
													value: Array.isArray(and.value)
														? and.value?.join(', ')
														: String(and.value),
													event: t(
														`data_collection-deployment_editor_item-condition_summary-${name}-${and.event}`
													),
													selector: and.selector,
													form: surveysByKey[and.formKey]?.name,
												}}
												components={{
													code: <Code />,
													emphasis: <Emphasis />,
												}}
											/>
										</span>
									);
								})}
							</div>
						);
					})}
				</>
			);
		case 'devices':
			return (
				<Trans
					i18nKey={`data_collection-deployment_editor_item-condition_summary-devices`}
					values={{
						value: value.reduce((string, device, index) => {
							const addOr = index !== 0 && index === value.length - 1;

							if (addOr) {
								string +=
									' ' +
									t(`data_collection-deployment_editor_item-condition_summary-or`) +
									' ';
							}
							if (!addOr && index !== 0) {
								string += ', ';
							}
							string += t(
								`data_collection-deployment_editor_item-condition_summary-devices-${device}`
							);

							return string;
						}, ''),
					}}
					components={{
						code: <Code />,
					}}
				/>
			);
		case 'number_of_pages':
			return (
				<Trans
					count={value.amount}
					i18nKey={`data_collection-deployment_editor_item-condition_summary-number_of_pages-${value.check}`}
					values={{
						amount: value.amount,
					}}
					components={{
						emphasis: <Emphasis />,
						code: <Code />,
					}}
				/>
			);

		case 'type':
			return (
				<Trans
					i18nKey={`data_collection-deployment_editor_item-condition_summary-type-${value}`}
					components={{
						code: <Code />,
					}}
				/>
			);
		default:
			return null;
	}
});

export const CollapsedSummary = memo(({ conditionSet, editorType, displayType }) => {
	const { t } = useTranslation();
	const activeConditions = useMemo(
		() =>
			Object.entries(conditionSet).filter(
				([key, value]) =>
					!['pause', 'trigger', 'session'].includes(key) && hasDeploymentValue(value)
			).length,
		[conditionSet]
	);

	return (
		<Box
			display="flex"
			alignItems="center"
		>
			<Box
				mr={1}
				color={conditionSet.pause ? 'text.hint' : 'text.secondary'}
			>
				<DeploymentIcon
					type="trigger"
					value={conditionSet.trigger}
					color="inherit"
					fontSize="small"
				/>
			</Box>
			<Typography
				variant="body2"
				color={conditionSet.pause ? 'textHint' : 'textPrimary'}
				//className={classes.conditionSummary}
			>
				<ConditionSummary
					name="trigger"
					value={conditionSet.trigger}
					editorType={editorType}
					displayType={displayType}
				/>
			</Typography>
			{!conditionSet.pause && activeConditions > 0 && (
				<Typography
					ml={1}
					variant="body2"
				>
					{t(
						'data_collection-deployment_editor_item-collapsed_summary-active_conditions',
						{ count: activeConditions }
					)}
				</Typography>
			)}
			{conditionSet.pause && (
				<Box
					display="inline-flex"
					alignItems="center"
					ml={1}
				>
					<PausedIcon
						fontSize="small"
						color="action"
					/>
					<Typography
						ml={0.5}
						variant="caption"
						color="textSecondary"
					>
						{t('data_collection-deployment_editor_item-collapsed_summary-paused')}
					</Typography>
				</Box>
			)}
		</Box>
	);
});

export const ConditionList = memo(
	({ conditions, ruleId, conditionIndex, editorType, displayType }) => {
		const classes = useStyles();
		return (
			<List
				dense
				disablePadding
			>
				{Object.entries(conditions).map(([key, value]) =>
					hasDeploymentValue(value) ? (
						<ListItem
							key={ruleId + conditionIndex + key}
							disableGutters
							className={classes.extraDense}
						>
							<ListItemIcon className={classes.conditionListIcon}>
								<DeploymentIcon
									type={key}
									value={value}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.conditionListText}
								primary={
									<ErrorBoundary returnNullOnError>
										<ConditionSummary
											name={key}
											value={value}
											editorType={editorType}
											displayType={displayType}
										/>
									</ErrorBoundary>
								}
							/>
						</ListItem>
					) : null
				)}
			</List>
		);
	}
);
