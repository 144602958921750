import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { MainMenuListItem, ActionIconButton } from '@/components/Layout';

export default function GiveFeedback({ mainMenuOpen, ...props }) {
	const { app } = useAppContext();
	const { t } = useTranslation();

	if (!mainMenuOpen) {
		return (
			<ActionIconButton
				action="feedback"
				id="feedbackButton"
				tooltip={!app.session.onboarding_trial ? t`app_general-feedback-tooltip` : ''}
				data-track-event="help_feedback_give_feedback"
				{...props}
				onClick={() => {
					try {
						window.srv.openModal();
					} catch (e) {}
				}}
			/>
		);
	}

	return (
		<MainMenuListItem
			action="feedback"
			id="feedbackButton"
			primary={!app.session.onboarding_trial ? t`app_general-feedback-tooltip` : ''}
			data-track-event="help_feedback_give_feedback"
			{...props}
			onClick={() => {
				try {
					window.srv.openModal();
				} catch (e) {}
			}}
		/>
	);
}
